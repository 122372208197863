import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import React, { useState, useRef } from 'react'
import ReactDOM from 'react-dom'
import { useNavigate, useParams } from 'react-router-dom'

import { BreadcrumbsListModal } from './BreadcrumbsListModal'
import BreadrcrumbsSkeleton from './BreadrcrumbsSkeleton'
import { getFileDisplayName } from '../../helpers/folder_utils'
import { useStore } from '../../helpers/useStore'
import useBreadcrumbs from '../../hooks/useBreadcrumbs'
import Tooltip from '../shared/Tooltip'

interface EllipsisButtonProps {
  onClick: () => void
  tooltipContents: string
  buttonRef: React.RefObject<HTMLButtonElement>
}

const EllipsisButton: React.FC<EllipsisButtonProps> = ({
  onClick,
  tooltipContents,
  buttonRef,
}) => (
  <Tooltip tooltipContents={tooltipContents} position="below">
    <button
      ref={buttonRef}
      className="text-txt-default-secondary hover:underline focus:outline-none"
      onClick={onClick}
    >
      ...
    </button>
  </Tooltip>
)

const Breadcrumbs: React.FC = observer(() => {
  const store = useStore()
  const navigate = useNavigate()
  const { id } = useParams()

  const { data, isLoading } = useBreadcrumbs(store)

  const [showModal, setShowModal] = useState(false)
  const [modalStyle, setModalStyle] = useState<{
    top: string | number
    left: string | number
    position: string
  }>({
    top: 0,
    left: 0,
    position: 'absolute',
  })

  const ellipsisButtonRef = useRef<HTMLButtonElement>(null)
  const isViewingFile = Boolean(store.currentDocument)

  if (!id) {
    return null
  }

  if (isLoading || !data) {
    return <BreadrcrumbsSkeleton />
  }

  const visibleBreadcrumbs = data.breadcrumbs.slice(-2)
  const hiddenBreadcrumbs = data.breadcrumbs.slice(0, -2)

  const handleEllipsisClick = () => {
    if (ellipsisButtonRef.current) {
      const { bottom, left } = ellipsisButtonRef.current.getBoundingClientRect()
      setModalStyle({
        top: bottom + 8,
        left,
        position: 'absolute',
      })
    }
    setShowModal(true)
  }

  const handleBreadcrumbClick = (breadcrumbId: string) => {
    navigate(`/chat/${id}/folder/${breadcrumbId}`)
    runInAction(() => (store.currentDocument = null))
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const ModalContent = showModal && (
    <BreadcrumbsListModal
      closeModal={closeModal}
      modalStyle={modalStyle}
      breadcrumbs={hiddenBreadcrumbs}
      handleBreadcrumbsClick={(breadcrumbId) => {
        handleBreadcrumbClick(breadcrumbId)
        closeModal()
      }}
    />
  )

  return (
    <>
      <nav className="flex items-center space-x-2 text-sm p-4">
        {/* Show ellipsis button if there are more than 3 breadcrumbs */}
        {data.breadcrumbs.length > 3 && (
          <>
            <EllipsisButton
              onClick={handleEllipsisClick}
              tooltipContents="More"
              buttonRef={ellipsisButtonRef}
            />
            <span className="text-txt-default-secondary">/</span>
          </>
        )}
        {/* Render visible breadcrumbs (last two breadcrumbs) */}
        {visibleBreadcrumbs.map((breadcrumb, index) => {
          const isLastBreadcrumb =
            !isViewingFile && index === visibleBreadcrumbs.length - 1
          return (
            <React.Fragment key={breadcrumb.id}>
              <button
                onClick={() => handleBreadcrumbClick(breadcrumb.id)}
                className={`hover:underline ${
                  isLastBreadcrumb
                    ? 'text-txt-neutral-secondary' // Current breadcrumb color
                    : 'text-txt-default-secondary font-semibold' // Previous subdirectory color
                }`}
              >
                <Tooltip tooltipContents={breadcrumb.title} position="below">
                  <span className="truncate max-w-[8rem] lg:max-w-[12rem] block">
                    {breadcrumb.title}
                  </span>
                </Tooltip>
              </button>
              {!isLastBreadcrumb && (
                <span className="text-txt-default-secondary">/</span>
              )}
            </React.Fragment>
          )
        })}
        {isViewingFile && store.currentDocument && (
          <React.Fragment key={store.currentDocument.id}>
            <button className="hover:underline text-txt-neutral-secondary">
              <Tooltip
                tooltipContents={getFileDisplayName(store.currentDocument)}
                position="below"
              >
                <span className="truncate max-w-[8rem] lg:max-w-[12rem] block">
                  {getFileDisplayName(store.currentDocument)}
                </span>
              </Tooltip>
            </button>
          </React.Fragment>
        )}
      </nav>

      {ReactDOM.createPortal(ModalContent, document.body)}
    </>
  )
})

export default Breadcrumbs
