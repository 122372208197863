import { useEffect } from 'react'
import { Route, Routes, Outlet } from 'react-router-dom'

import FlashMessages from './FlashMessages'
import AboutLawFirmStep from './onboarding/AboutLawFirmStep'
import AboutUserStep from './onboarding/AboutUserStep'
import AboutWorkStep from './onboarding/AboutWorkStep'
import TrialInfo from './onboarding/TrialInfo'
import assetPath from '../helpers/assetPath'
import { useStore } from '../helpers/useStore'

const useOnboardingTitle = (step?: string) => {
  useEffect(() => {
    const titles: Record<string, string> = {
      firm_info: 'Alexi - Tell us about your law firm',
      user_info: 'Alexi - Tell us about you',
      work_info: 'Alexi - Tell us about your work',
      trial_info: 'Alexi - Trial information',
    }
    document.title = titles[step || ''] || 'Alexi - Onboarding'
  }, [step])
}

const OnboardingFlow: React.FC = () => {
  const store = useStore()
  useOnboardingTitle(store.user?.current_onboarding_step)

  return (
    <>
      <header className="flex justify-start p-6 mb-5">
        <img
          src={assetPath(store, 'alexiTitleLogo.svg')}
          alt="Alexi Logo"
          className="h-8 w-auto"
        />
      </header>
      <div className="flex flex-col h-full items-center overflow-hidden min-w-full max-w-full lg:min-w-[1024px] lg:max-w-[1920px] min-h-[720px] mx-auto">
        <Routes>
          <Route path="firm_info" element={<AboutLawFirmStep />} />
          <Route path="user_info" element={<AboutUserStep />} />
          <Route path="work_info" element={<AboutWorkStep />} />
          <Route path="trial_info" element={<TrialInfo />} />
          <Route path="*" element={<Outlet />} />
        </Routes>
        <FlashMessages />
      </div>
    </>
  )
}

export default OnboardingFlow
