import { observer } from 'mobx-react-lite'
import React from 'react'

import SummaryDocModal from './main_chat/doc_summary/SummaryDocModal'
import MemoViewModal from './main_chat/memoGeneration/MemoViewModal'
import { useStore } from '../helpers/useStore'

const ContentModal = observer(() => {
  const store = useStore()

  if (!store.modal.isOpen) {
    return null
  }

  switch (store.modal.contentType) {
    case 'InstantMemo':
      return <MemoViewModal />
    case 'SummaryDocument':
      return <SummaryDocModal />
    default:
      return null
  }
})

export default ContentModal
