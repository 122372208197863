import React from 'react'

import assetPath from '../../helpers/assetPath'
import { useStore } from '../../helpers/useStore'

const ChatMessageSkeleton = () => {
  const store = useStore()

  return (
    <div className="flex px-6 pt-3 justify-start items-center ">
      <div className={`flex-none w-16 h-16 px-3 pb-3 order-1`}>
        <img
          className="w-16 h-16"
          src={assetPath(store, 'alexi-logo-icon.svg')}
          alt="System"
        />
      </div>
      <div className="flex-grow p-4 bg-slate-50 order-2 rounded-r-lg">
        <div className="h-3 rounded w-3/4 mb-2 bg-pulse-gradient bg-200 animate-pulse-left-to-right"></div>
        <div className="h-3 rounded w-1/2 bg-pulse-gradient bg-200 animate-pulse-left-to-right"></div>
      </div>
    </div>
  )
}

export default ChatMessageSkeleton
