import PropTypes from 'prop-types'
import React from 'react'

import { FailedLoading } from './FailedLoading'
import { LoadingBar } from './LoadingBar'
import { ActivityItemStatus } from '../../helpers/constants'
import { TruncatedText } from '../TruncatedText'

const AttachmentItem = ({ activity, onClick }) => {
  const EXTRA_TIME_FOR_ANALYSIS = 20 // 20 seconds
  const durationEstimate =
    activity.activity_item?.duration_estimate + EXTRA_TIME_FOR_ANALYSIS

  const isAnalyzing =
    activity.activity_item?.status === ActivityItemStatus.STARTED
  const isPending = activity.activity_item?.status === null
  const isFailedActivity =
    activity.activity_item.status === ActivityItemStatus.FAILED ||
    activity.activity_item.status === ActivityItemStatus.INVALID
  const textColour = isFailedActivity
    ? 'text-txt-danger-default'
    : 'text-txt-default-secondary'

  return (
    <div
      className={`flex flex-col justify-center items-start gap-1 self-stretch pr-1 py-2 mt-1 cursor-pointer`}
      onClick={onClick}
    >
      <div className="flex flex-col justify-center items-start gap-1 self-stretch">
        <TruncatedText
          text={activity.activity_item.description}
          textClasses={textColour}
        />
      </div>

      {isPending && (
        <div className="loading-bar self-stretch">
          <div
            className="w-full bg-brd-neutral-secondary flex items-center my-1"
            style={{
              borderRadius: '10px',
              height: '6px',
              background: 'var(--Background-Neutral-Secondary, #F2F2F2)',
            }}
          ></div>
          <div className="flex flex-row justify-between mb-1">
            <span className="text-xs leading-[1.125rem]">Analyzing</span>
            <span className="text-xs leading-[1.125rem]">Pending</span>
          </div>
        </div>
      )}
      {isAnalyzing && (
        <LoadingBar
          activityId={activity.id}
          description={'Analyzing'}
          durationEstimate={durationEstimate}
          isPending={isPending}
        />
      )}
      {isFailedActivity && (
        <FailedLoading updatedAt={activity.activity_item.updated_at} />
      )}
    </div>
  )
}

AttachmentItem.propTypes = {
  activity: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    activity_item: PropTypes.shape({
      description: PropTypes.string,
      duration_estimate: PropTypes.number,
      status: PropTypes.string,
      updated_at: PropTypes.string,
    }),
    ref: PropTypes.object,
    chat_thread_message_id: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func,
}

export default AttachmentItem
