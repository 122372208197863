import axios from 'axios'

import addFlash from '../actions/AddFlash'
import { extendSessionTimeout } from '../actions/sessionActions'
import { rollbarConfig } from '../helpers/rollbarConfig'

// Set withCredentials to true for all requests
axios.defaults.withCredentials = true

export const fetchChatHistory = async (store, params) => {
  extendSessionTimeout(store)

  try {
    const response = await axios.get(`/api/client/v1/chat_messages`, {
      params: params,
    })
    return response.data
  } catch (error) {
    console.error('Error fetching chat history:', error)
    throw error
  }
}

export const createChatThreadMessage = async (store, newMessage) => {
  extendSessionTimeout(store)

  const csrfToken = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute('content')

  try {
    return await axios.post(
      '/api/client/v1/chat_messages',
      {
        chat_thread_message: newMessage,
      },
      {
        // Configuration object
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken,
        },
      }
    )
  } catch (error) {
    if (error.response?.data?.error) {
      addFlash(store, 'error', error.response.data.error)
    }
    console.error('Error sending message:', error)
    throw error
  }
}

export const startBroadcast = async (startBroadcastMessage, store) => {
  const csrfToken = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute('content')

  try {
    await fetch('/api/client/v1/chat_messages/start_broadcast', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken,
      },
      body: JSON.stringify({ chat_thread_message: startBroadcastMessage }),
    })
  } catch (error) {
    rollbarConfig(store)?.error(error)
    throw error
  }
}

export const retryFailedMemo = async (store, instantMemoId) => {
  extendSessionTimeout(store)

  const csrfToken = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute('content')
  try {
    return await axios.post(
      `/api/client/v1/instant_memos/${instantMemoId}/retry_failed_memo`,
      {
        matter_id: instantMemoId,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken,
        },
      }
    )
  } catch (error) {
    rollbarConfig(store)?.error(error)
    throw error
  }
}

export const connectFilesWithMessage = async (store, fileUploadPayload) => {
  extendSessionTimeout(store)

  const csrfToken = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute('content')
  try {
    return await axios.post(
      '/api/client/v1/chat_messages/connect_uploaded_files',
      {
        chat_thread_message: fileUploadPayload,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken,
        },
      }
    )
  } catch (error) {
    rollbarConfig(store)?.error(error)
    throw error
  }
}

export class createMessage {}
