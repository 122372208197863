import PropTypes from 'prop-types'
import React from 'react'

const ChevronRightIcon = ({
  stroke = 'stroke-icon-brand-default',
  fill = 'fill-icon-brand-default',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
  >
    <path
      d="M8 6.5L12 10.5L8 14.5L8 6.5Z"
      className={`${stroke} ${fill}`}
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
  </svg>
)
ChevronRightIcon.propTypes = {
  stroke: PropTypes.string,
  fill: PropTypes.string,
}

export default ChevronRightIcon
