import React from 'react'

const QuestionIcon: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Icon">
      <path
        id="Icon_2"
        d="M10.5389 9.06145C10.7461 8.47219 11.1553 7.9753 11.6938 7.65879C12.2324 7.34229 12.8656 7.22659 13.4812 7.3322C14.0969 7.4378 14.6553 7.75789 15.0576 8.23577C15.4599 8.71365 15.6801 9.31849 15.6791 9.94315C15.6791 11.7065 13.034 12.5882 13.034 12.5882M13.0681 16.1176H13.0799M12.8918 22C18.4146 22 22.8918 17.5228 22.8918 12C22.8918 6.47715 18.4146 2 12.8918 2C7.36895 2 2.89179 6.47715 2.89179 12C2.89179 13.1176 3.07514 14.1925 3.4134 15.196C3.5407 15.5737 3.60434 15.7625 3.61582 15.9076C3.62716 16.0508 3.61859 16.1513 3.58315 16.2905C3.54726 16.4316 3.46802 16.5782 3.30955 16.8715L1.38525 20.4334C1.11076 20.9414 0.973521 21.1954 1.00424 21.3915C1.03099 21.5623 1.13149 21.7126 1.27906 21.8026C1.44847 21.906 1.73567 21.8763 2.31008 21.8169L8.33481 21.1941C8.51726 21.1753 8.60848 21.1658 8.69163 21.169C8.77341 21.1722 8.83114 21.1798 8.91089 21.1982C8.99197 21.2169 9.09393 21.2562 9.29786 21.3347C10.4132 21.7644 11.625 22 12.8918 22Z"
        className="stroke-icon-brand-default"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
)

export default QuestionIcon
