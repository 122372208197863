import { Button, IconButton } from '@material-tailwind/react'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import { getChatResponseLoading } from '../../actions/chatResponseLoadingActions'
import { setSelectedMatter } from '../../actions/matterActions'
import { getMessages, setMessages } from '../../actions/messagesActions'
import {
  setShowDocumentManagers,
  setShowLatestMessagesButton,
  isMdSize,
  setShowSidebar,
} from '../../actions/showLayoutActions'
import { fetchChatHistory } from '../../apis/chatApi'
import { Message } from '../../helpers/Store'
import { useStore } from '../../helpers/useStore'
import { useScreenSize } from '../../hooks/useScreenSize'
import ArrowLeftToLine from '../assets/ArrowLeftToLine'
import ArrowRightToLine from '../assets/ArrowRightToLine'
import Loading from '../assets/Loading'
import MenuIcon from '../assets/MenuIcon'
import UploadIcon from '../assets/UploadIcon'
import ChatInput from '../ChatInput'
import ChatHeader from '../main_chat/ChatHeader'
import ChatMessage from '../main_chat/ChatMessage'
import InitialChatMessage from '../main_chat/InitialChatMessage'
import { InitialChatSuggestions } from '../main_chat/InitialChatSuggestions'
import LatestMessageButton from '../main_chat/LatestMessageButton'
import LoadingMessage from '../main_chat/LoadingMessage'
import ReadOnlyChatInput from '../main_chat/read_only/ReadOnlyChatInput'
import SettingsModal from '../SettingsModal'
import CollapsibleLayout from '../shared/CollapsibleLayout'

export const MainChat = observer(() => {
  const store = useStore()
  const { id: matterId } = useParams()
  const { screenWidth } = useScreenSize()

  const chatContainerRef = useRef<HTMLDivElement>(null)
  const [containerWidth, setContainerWidth] = useState(0)
  const [isSettingsOpen, setIsSettingsOpen] = useState(false)
  const [isLoadingMessages, setIsLoadingMessages] = useState(true)

  const matterMessages = getMessages(store, matterId)
  const lastMessageLength = matterMessages?.at(-1)?.content?.length
  const matterMessageStoreLength = matterMessages?.length
  useEffect(() => {
    const matter = store.matters.find((m) => m.id === matterId)
    if (matter && store.selectedMatter?.id !== matter.id) {
      setSelectedMatter(store, matter)
    }

    if (matterId) {
      loadChatHistory()
    }
  }, [matterId])

  useEffect(() => {
    scrollToBottom()
    setShowLatestMessagesButton(store, false)

    if (chatContainerRef.current) {
      setContainerWidth(chatContainerRef.current.offsetWidth)
    }
  }, [matterMessageStoreLength, lastMessageLength])

  useEffect(() => {
    if (chatContainerRef.current) {
      setContainerWidth(chatContainerRef.current.offsetWidth)
    }
  }, [
    screenWidth,
    store.showLayouts.showDocumentManager,
    store.showLayouts.showSidebar,
  ])

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: 'instant',
      })
    }
  }

  const checkIfNewMessagesBelow = () => {
    const chatContainer = chatContainerRef.current
    if (!chatContainer) {
      return
    }

    const { scrollTop, scrollHeight, clientHeight } = chatContainer
    const distanceFromBottom = scrollHeight - (scrollTop + clientHeight)
    // Show the latest messages button only if the distance from the bottom is greater than 200px
    setShowLatestMessagesButton(store, distanceFromBottom > 100)
  }

  const loadChatHistory = async () => {
    setIsLoadingMessages(true)
    try {
      const messages: Message[] = await fetchChatHistory(store, {
        chat_thread_message: {
          matter_id: matterId,
          thread_id: store.selectedMatter?.thread_id,
        },
      })

      setMessages(store, matterId, messages)
    } catch (error) {
      console.error('Error fetching chat history:', error)
    } finally {
      setIsLoadingMessages(false)
    }
  }

  return (
    <div className="border-l border-r">
      <CollapsibleLayout
        navBarClassName="h-[77px] md:min-w-1/2"
        headerLeftComponent={
          <div className="flex items-center gap-4">
            {!store.showLayouts.showSidebar ? (
              <div>
                <IconButton
                  className="bg-bgCol-default-default w-5 h-5"
                  onClick={() => {
                    setShowSidebar(store, true)
                  }}
                >
                  <MenuIcon />
                </IconButton>
              </div>
            ) : null}
            {store.selectedMatter ? (
              <ChatHeader
                matter={store.selectedMatter}
                handleSettingPopup={(value) => setIsSettingsOpen(value)}
                titleStyle={{
                  maxWidth: containerWidth >= 400 ? containerWidth - 250 : 150,
                }}
              />
            ) : null}
          </div>
        }
        headerRightComponent={
          <>
            <Button
              variant="filled"
              className="bg-bgCol-brand-secondary py-2.5 px-2 flex justify-center items-center rounded h-8"
              onClick={() => {
                setShowDocumentManagers(
                  store,
                  !store.showLayouts.showDocumentManager
                )
              }}
            >
              {store.showLayouts.showDocumentManager ? (
                <ArrowRightToLine strokeClass="stroke-txt-brand-default" />
              ) : (
                <ArrowLeftToLine strokeClass="stroke-txt-brand-default" />
              )}
              {isMdSize(screenWidth) ? (
                <span className="pl-2 text-sm font-medium text-txt-brand-default">
                  {store.showLayouts.showDocumentManager ? 'Hide' : 'Show'}{' '}
                  Documents
                </span>
              ) : null}
            </Button>
            {isMdSize(screenWidth) && !store.showLayouts.showDocumentManager ? (
              <Button
                variant="filled"
                className="bg-bgCol-brand-default py-2.5 px-2 flex justify-center items-center rounded h-8"
                onClick={() => {
                  setShowDocumentManagers(store, true)
                }}
              >
                <UploadIcon strokeClass="stroke-white" />
                <span className="pl-2 text-sm font-normal text-txt-white">
                  Upload
                </span>
              </Button>
            ) : null}
          </>
        }
      >
        {/* Chat Content and Chat Input */}
        <div className="flex flex-col relative w-full h-[calc(100vh-77px)]">
          <div
            className="flex flex-col overflow-auto chat-container h-full"
            ref={chatContainerRef}
            onScroll={checkIfNewMessagesBelow}
          >
            {matterId ? (
              <>
                {isLoadingMessages ? (
                  <Loading />
                ) : matterMessages && matterMessages.length === 0 ? (
                  <div className="flex flex-col h-full">
                    {/* Chat Message Area */}
                    <div className="flex-grow">
                      <InitialChatMessage />
                    </div>
                    {/* Pills Section */}
                    <div className="bg-white px-6">
                      <InitialChatSuggestions />
                    </div>
                  </div>
                ) : (
                  matterMessages?.map((message: Message) => (
                    <ChatMessage
                      key={`chat-message-${message.id}`}
                      message={message}
                      containerWidth={containerWidth}
                    />
                  ))
                )}
                {getChatResponseLoading(store, matterId) && <LoadingMessage />}
              </>
            ) : (
              <InitialChatMessage />
            )}
          </div>
          <div
            className={`bg-white p-6 ${matterMessages?.length === 0 && 'pt-0'} pb-2 sticky bottom-0 items-center`}
          >
            {store.showLatestMessagesButton && (
              <div className="absolute left-1/2 transform -translate-x-1/2 -translate-y-10">
                <LatestMessageButton
                  store={store}
                  scrollToBottom={scrollToBottom}
                />
              </div>
            )}
            {!matterId && <InitialChatSuggestions />}
            {!matterId || store.selectedMatter?.is_alr ? (
              <ChatInput />
            ) : (
              <ReadOnlyChatInput />
            )}
            <div className="mt-2 text-center text-xs text-txt-neutral-secondary">
              Alexi is a conversational tool designed to assist and provide
              information to the best of its abilities. Consider verifying
              important information.
            </div>
          </div>
          {store.selectedMatter && isSettingsOpen && (
            <SettingsModal
              matter={store.selectedMatter}
              onClose={() => {
                setIsSettingsOpen(false)
              }}
            />
          )}
        </div>
      </CollapsibleLayout>
    </div>
  )
})

export default MainChat
