import UploadBatch from './UploadBatch'
import Store from '../../helpers/Store'

export const getUploadBatches = (
  store: Store,
  matterId?: string
): UploadBatch[] => {
  if (!matterId) {
    return []
  }

  return store.uploadBatches[matterId] || []
}

export const addUploadBatch = (
  store: Store,
  uploadBatch: UploadBatch,
  matterId: string
) => {
  if (store.uploadBatches[matterId]) {
    store.uploadBatches[matterId].push(uploadBatch)
  } else {
    store.uploadBatches[matterId] = [uploadBatch]
  }
}
