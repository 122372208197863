import { action } from 'mobx'

import Store from '../helpers/Store'

export const getInputValue = (store: Store) => {
  return store.inputValue
}

export const setInputValue = action((store: Store, value: string) => {
  store.inputValue = value
})
