import React from 'react'

interface EraserIconProps {
  stroke: string // Accepts a stroke as a prop
}

const EraserIcon: React.FC<EraserIconProps> = ({ stroke }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M13.05 21.05L21.35 12.75C22.35 11.85 22.35 10.35 21.35 9.35L15.75 3.75C14.85 2.75 13.35 2.75 12.35 3.75L2.75 13.35C1.75 14.25 1.75 15.75 2.75 16.75L7.05 21.05H22.05M5.05 11.05L14.05 20.05"
      className={`${stroke}`}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default EraserIcon
