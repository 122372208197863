import { action } from 'mobx'

import consumer from './consumer'
import { getUploadBatches } from '../actions/upload_batch/uploadBatchActions'
import {
  IEnterpriseAttachment,
  EnterpriseAttachmentProcessingStatus,
} from '../components/types/interfaces'
import Store from '../helpers/Store'

const handleMatterUpdate = action((store: Store, message: MatterUpdate) => {
  const matter = store.matters.find((m) => m.id === message.matter.id)

  // Two possible places where a matter to update can be found: the list of matters driving the left nav bar, and the selected matter.

  if (matter) {
    Object.assign(matter, message.matter)
  }

  if (store.selectedMatter?.id === message.matter.id) {
    Object.assign(store.selectedMatter, message.matter)
  }
})

const handleEnterpriseAttachmentCompleted = action(
  (store: Store, message: EnterpriseAttachmentProcessed) => {
    // We have two separate chunks of state to update here.

    // Due to the potentially long duration of document processing, and the ephemeral nature of UploadBatches and the current set of Folder data, it's possible that either of these local chunks can be missing.

    // First, find the UploadRequest/UploadBatch
    const batches = getUploadBatches(store, message.matterId)

    for (const batch of batches) {
      const uploadRequest = batch.uploadRequests.find((searchRequest) => {
        return (
          searchRequest.enterpriseAttachmentId ===
          message.enterpriseAttachmentId
        )
      })

      if (uploadRequest) {
        switch (message.processing_status) {
          case 'COMPLETED': {
            uploadRequest.processingFinished()
            break
          }
          case 'INVALID':
          case 'FAILED': {
            uploadRequest.processingFailed()
            break
          }
        }
      }
    }

    // Second, update the file entry in the local folder data

    if (store.currentFolder?.documents) {
      const document = store.currentFolder.documents.find(
        (searchDocument) => searchDocument.id === message.enterpriseAttachmentId
      ) as IEnterpriseAttachment | undefined

      if (document) {
        document.processing_status = message.processing_status
        document.generated_name = message.generated_name
        document.generated_description = message.generated_description
      }
    }
  }
)

// A channel for all updates relevant to the current user, no matter what state the client app is in
const connectToUserChannel = (store: Store) => {
  store.userChannel = consumer.subscriptions.create(
    { channel: 'UserChannel' },
    {
      received(data: string) {
        const parsedChunk: MessageWrapper = JSON.parse(data)

        switch (parsedChunk.message_type) {
          case 'enterprise_attachment_processed':
            handleEnterpriseAttachmentCompleted(
              store,
              parsedChunk.message as EnterpriseAttachmentProcessed
            )
            break
          case 'matter_update':
            handleMatterUpdate(store, parsedChunk.message as MatterUpdate)
            break
        }
      },
    }
  )
}

interface MessageWrapper {
  message_type: 'matter_update' | 'enterprise_attachment_processed'
  message: MatterUpdate | EnterpriseAttachmentProcessed
}

interface MatterUpdate {
  matter: {
    id: string
    title: string
    updated_at: string
  }
}

interface EnterpriseAttachmentProcessed {
  enterpriseAttachmentId: string
  matterId: string
  processing_status: EnterpriseAttachmentProcessingStatus
  generated_name: string
  generated_description: string
}

export { connectToUserChannel }
