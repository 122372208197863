import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'

import ChipSelectGroup from './ChipSelectGroup'
import FormStep from './FormStep'
import InfoModal from './InfoModal'
import { submitOnboardingForm } from '../../apis/onboardingApi'
import { useStore } from '../../helpers/useStore'
import XIcon from '../assets/XIcon'
import Modal from '../shared/Modal'
import ReactSelectMulti from '../shared/ReactSelectMulti'

interface Option {
  value: string
  label: string
}

interface WorkInfoOptions {
  practices: Option[]
  assistance_with: Option[]
  legal_tools: Option[]
}

interface FormData {
  lawPractice: Option[]
  assistance: Option[]
  legalTools: Option[]
}

const AboutWorkStep: React.FC = () => {
  const store = useStore()
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm<FormData>({
    defaultValues: {
      lawPractice: [],
      assistance: [],
      legalTools: [],
    },
    mode: 'onChange',
  })

  const [workInfoOptions, setWorkInfoOptions] = useState<WorkInfoOptions>({
    practices: [],
    assistance_with: [],
    legal_tools: [],
  })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [lawPracticeLabel, setLawPracticeLabel] = useState(
    'What kind of law do you practice?*'
  )

  useEffect(() => {
    const fetchWorkInfo = async () => {
      try {
        const response = await axios.get('/api/client/v1/onboardings/work_info')
        const { user, work_info } = response.data

        setWorkInfoOptions(work_info)
        setLawPracticeLabel(user.law_practice_label)

        reset({
          lawPractice: work_info.practices.filter((opt: Option) =>
            user.law_practices.includes(opt.value)
          ),
          assistance: work_info.assistance_with.filter((opt: Option) =>
            user.assistance_types.includes(opt.value)
          ),
          legalTools: work_info.legal_tools.filter((opt: Option) =>
            user.legal_research_tools.includes(opt.value)
          ),
        })
      } catch (error) {
        console.error('Error fetching work info data:', error)
      }
    }
    fetchWorkInfo()
  }, [reset])

  const onSubmit = async (data: FormData) => {
    const payload = {
      law_practices: data.lawPractice.map((item) => item.value),
      assistance_types: data.assistance.map((item) => item.value),
      legal_research_tools: data.legalTools.map((item) => item.value),
    }
    await submitOnboardingForm(
      '/api/client/v1/onboardings/complete_work_info',
      payload
    )
  }

  const owner = store.user?.role === 'owner'
  const lastStep = owner ? 3 : 2

  return (
    <FormStep
      step={lastStep}
      totalSteps={lastStep}
      title="Tell us about your work"
    >
      <p className="text-sm text-txt-default-default mb-8 mt-4 text-center">
        We’ll use the information you provide to personalize your experience
        with Alexi.
      </p>

      <form onSubmit={handleSubmit(onSubmit)}>
        {/* Law Practice */}
        <div className="mb-6">
          <label
            htmlFor="lawPractice"
            className="block text-sm font-medium mb-2"
          >
            {lawPracticeLabel}
          </label>
          <Controller
            name="lawPractice"
            control={control}
            rules={{ required: 'Please select at least one practice area' }}
            render={({ field }) => (
              <ReactSelectMulti
                value={field.value}
                onChange={field.onChange}
                options={workInfoOptions.practices}
                placeholder="Choose an option"
              />
            )}
          />
          {errors.lawPractice && (
            <p className="text-txt-danger-default text-sm mt-1">
              {errors.lawPractice.message}
            </p>
          )}
        </div>

        {/* Assistance */}
        <div className="mb-6">
          <div className="flex items-center text-sm font-medium mb-2 md:flex-row flex-col">
            <span>What are you looking for assistance with?*</span>
            <button
              type="button"
              onClick={() => setIsModalOpen(true)}
              className="ml-2 text-sm text-txt-brand-default hover:underline"
            >
              Learn about what we can do.
            </button>
          </div>
          <Controller
            name="assistance"
            control={control}
            rules={{ required: 'Please select at least one option' }}
            render={({ field }) => (
              <ChipSelectGroup
                options={workInfoOptions.assistance_with}
                value={field.value.map((item) => item.value)}
                onChange={(values) => {
                  const selectedOptions =
                    workInfoOptions.assistance_with.filter((opt) =>
                      values.includes(opt.value)
                    )
                  field.onChange(selectedOptions)
                }}
              />
            )}
          />
          {errors.assistance && (
            <p className="text-txt-danger-default text-sm mt-1">
              {errors.assistance.message}
            </p>
          )}
        </div>

        <div className="mb-8">
          <label className="block text-sm font-medium mb-2">
            What legal research tools are you currently using?*
          </label>
          <Controller
            name="legalTools"
            control={control}
            rules={{ required: 'Please select at least one tool' }}
            render={({ field }) => (
              <ChipSelectGroup
                options={workInfoOptions.legal_tools}
                value={field.value.map((item) => item.value)}
                onChange={(values) => {
                  const selectedOptions = workInfoOptions.legal_tools.filter(
                    (opt) => values.includes(opt.value)
                  )
                  field.onChange(selectedOptions)
                }}
              />
            )}
          />
          {errors.legalTools && (
            <p className="text-txt-danger-default text-sm mt-1">
              {errors.legalTools.message}
            </p>
          )}
        </div>

        {/* Buttons */}
        <div>
          <button
            type="submit"
            disabled={!isValid}
            className="h-11 mt-8 text-center disabled:bg-bgCol-disabled-default disabled:text-txt-disabled-default disabled:cursor-not-allowed w-full flex items-center justify-center py-2 px-4 text-white rounded-md text-sm bg-bgCol-brand-default focus:ring-2 focus:ring-offset-2"
          >
            Continue
          </button>
        </div>
      </form>

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        {/* Header */}
        <div className="flex justify-between items-center bg-white px-6 pb-6 mt-6 border-b border-brd-default-default">
          <h2 className="text-lg font-semibold text-txt-default-default">
            Learn about what we can do
          </h2>

          <button onClick={() => setIsModalOpen(false)} className="w-6 h-6">
            <XIcon classNames="w-[22px] h-[22px]" />
          </button>
        </div>
        {/* Content */}
        <InfoModal />
      </Modal>
    </FormStep>
  )
}

export default AboutWorkStep
