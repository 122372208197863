import React from 'react'

const ThreeDotsIcon = ({ variants = 'primary' }: { variants?: string }) => {
  let colorClass

  switch (variants) {
    case 'primary':
      colorClass = 'stroke-icon-brand-default'
      break
    case 'secondary':
      colorClass = 'stroke-icon-neutral-default'
      break
    default:
      colorClass = 'stroke-icon-brand-default'
  }
  return (
    <svg
      width="16"
      height="4"
      viewBox="0 0 16 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={colorClass}
    >
      <g id="Group">
        <path
          id="Vector"
          d="M8.8002 2.00029C8.8002 1.55847 8.44202 1.20029 8.0002 1.20029C7.55837 1.20029 7.2002 1.55847 7.2002 2.00029C7.2002 2.44212 7.55837 2.80029 8.0002 2.80029C8.44202 2.80029 8.8002 2.44212 8.8002 2.00029Z"
          className={colorClass}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M3.2001 2.00029C3.2001 1.55847 2.84193 1.20029 2.4001 1.20029C1.95827 1.20029 1.6001 1.55847 1.6001 2.00029C1.6001 2.44212 1.95827 2.80029 2.4001 2.80029C2.84193 2.80029 3.2001 2.44212 3.2001 2.00029Z"
          className={colorClass}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M14.4003 2.00029C14.4003 1.55847 14.0421 1.20029 13.6003 1.20029C13.1585 1.20029 12.8003 1.55847 12.8003 2.00029C12.8003 2.44212 13.1585 2.80029 13.6003 2.80029C14.0421 2.80029 14.4003 2.44212 14.4003 2.00029Z"
          className={colorClass}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}

export default ThreeDotsIcon
