import React, { useEffect, useState } from 'react'

import HourGlassIcon from './assets/HourGlassIcon'
import ConfirmDeleteFilesModal from './ConfirmDeleteFilesModal'
import FilesDeletedModal from './FilesDeletedModal'
import { deleteAllFiles, hasEnterpriseAttachments } from '../apis/filesApi'
import assetPath from '../helpers/assetPath'
import { useStore } from '../helpers/useStore'

const FirmDisabledModal = () => {
  const store = useStore()
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [showFilesDeletedModal, setShowFilesDeletedModal] = useState(false)
  const [userHasEnterpriseAttachments, setUserHasEnterpriseAttachments] =
    useState(false)

  useEffect(() => {
    const checkAttachments = async () => {
      try {
        const hasAttachments = await hasEnterpriseAttachments(store)
        setUserHasEnterpriseAttachments(hasAttachments)
      } catch (error) {
        console.error('Error fetching attachments:', error)
      }
    }

    checkAttachments()
  }, [store])

  const handleTalkToSpecialistClick = () => {
    window.location.href = 'https://calendly.com/trenten-alexi'
  }

  const handleAddCreditCardClick = () => {
    window.location.href = `${store.credentials.customerAppUrl}/firms/admin/billings/payment_method`
  }

  const handleSignOutClick = () => {
    window.location.href = `${store.credentials.customerAppUrl}/users/sign_out`
  }

  const showDeleteConfirmationModal = () => {
    setShowConfirmDelete(true)
  }

  const handleCancel = () => {
    setShowConfirmDelete(false) // Return to FirmDisabledModal
  }

  const handleDeleteFiles = () => {
    deleteAllFiles(store)
    setShowConfirmDelete(false)
    setShowFilesDeletedModal(true)
  }

  if (showConfirmDelete) {
    return (
      <ConfirmDeleteFilesModal
        onCancel={handleCancel}
        onDelete={handleDeleteFiles}
      />
    )
  }

  if (showFilesDeletedModal) {
    return (
      <FilesDeletedModal
        handleTalkToSpecialistClick={handleTalkToSpecialistClick}
        handleAddCreditCardClick={handleAddCreditCardClick}
      />
    )
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50 p-4 sm:p-8 min-w-96">
      <div className="bg-white px-6 sm:px-8 md:px-10 lg:px-10 py-6 sm:py-8 md:py-10 lg:py-10 rounded-lg shadow-lg w-full max-w-md sm:max-w-lg lg:max-w-xl xl:max-w-2xl relative">
        <div className="flex justify-center mb-6 sm:mb-8">
          <HourGlassIcon />
        </div>
        <h2 className="text-xl sm:text-2xl md:text-3xl text-txt-default-default font-semibold text-center mb-4">
          Your 7-day free trial has ended
        </h2>
        <p className="text-center text-txt-default-default font-normal text-sm sm:text-base md:text-lg mb-2">
          Thanks for trying Alexi! Want to keep using our litigation platform?
        </p>
        <p className="text-center text-txt-default-default font-normal text-sm sm:text-base md:text-lg mb-8">
          Continue using Alexi by adding a credit card, or talk to a product
          specialist to get your trial extended.
        </p>
        <div className="flex flex-col sm:flex-row justify-center items-center w-full space-y-4 sm:space-y-0 sm:space-x-4 lg:space-x-6 mb-8">
          <button
            onClick={handleTalkToSpecialistClick}
            className="bg-bgCol-neutral-default text-txt-neutral-default flex items-center justify-center w-full sm:w-auto px-4 sm:px-6 py-3 h-12 rounded-lg font-medium"
          >
            <img
              src={assetPath(store, 'trent.png')}
              alt="Product Specialist"
              className="w-8 h-8 rounded-full mr-2"
            />
            <span className="text-txt-neutral-default font-medium whitespace-nowrap w-full text-center">
              Talk to a product specialist
            </span>
          </button>
          <button
            onClick={handleAddCreditCardClick}
            className="bg-bgCol-brand-default text-txt-utilities-default flex items-center justify-center w-full sm:w-auto px-4 sm:px-6 py-3 h-12 rounded-lg font-medium"
          >
            Add a credit card
          </button>
        </div>
        {userHasEnterpriseAttachments && (
          <p className="text-center mt-4">
            Want to delete all your files?
            <button
              onClick={showDeleteConfirmationModal}
              className="text-txt-brand-default font-medium hover:underline ml-1"
            >
              Yes, delete.
            </button>
          </p>
        )}
        <p className="text-center text-txt-default-default mt-4">
          Want to sign out?
          <button
            onClick={handleSignOutClick}
            className="text-txt-brand-default  font-medium hover:underline ml-1"
          >
            Sign out.
          </button>
        </p>
      </div>
    </div>
  )
}

export default FirmDisabledModal
