import { Drawer } from '@material-tailwind/react'
import { observer } from 'mobx-react-lite'
import React, { Fragment, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { setSelectedMatter } from '../../actions/matterActions'
import { isMdSize, setShowSidebar } from '../../actions/showLayoutActions'
import assetPath from '../../helpers/assetPath'
import { useStore } from '../../helpers/useStore'
import { useScreenSize } from '../../hooks/useScreenSize'
import MattersList from '../matter/MattersList'
import MattersListHeader from '../matter/MattersListHeader'
import CollapsibleLayout from '../shared/CollapsibleLayout'
import UserProfileAndSettingFooter from '../shared/UserProfileAndSettingFooter'

export const Sidebar = observer(() => {
  const store = useStore()
  const navigate = useNavigate()

  const { showSidebar } = store.showLayouts
  const { screenWidth } = useScreenSize()

  const logoUrl = useMemo(() => {
    return (
      store.user?.enterprise.logo_url || assetPath(store, 'alexiTitleLogo.svg')
    )
  }, [store.user?.enterprise.logo_url])

  const displayLogo = () => (
    <a
      className="flex items-center cursor-pointer"
      onClick={() => {
        setSelectedMatter(store, null)
        navigate('/chat')
      }}
    >
      <img src={logoUrl} alt="Enterprise Logo" className="h-8 w-full" />
    </a>
  )

  const content = (
    <CollapsibleLayout
      className={`transition-all ${showSidebar ? 'md:w-60' : 'w-0'}`}
      navBarClassName="h-[77px] border-0 border-b border-brand-tertiary"
      headerLeftComponent={displayLogo()}
      footerComponent={<UserProfileAndSettingFooter />}
      showRightControlButton={true}
      handleRightControlButtonClick={() =>
        setShowSidebar(store, !store.showLayouts.showSidebar)
      }
    >
      <div className="flex flex-col w-full h-full">
        <MattersListHeader />
        <div className="overflow-y-auto flex-grow px-2">
          <MattersList />
        </div>
      </div>
    </CollapsibleLayout>
  )
  return (
    <Fragment>
      {isMdSize(screenWidth) && showSidebar ? (
        content
      ) : (
        <Drawer
          open={showSidebar}
          onClose={() => {
            setShowSidebar(store, false)
          }}
        >
          {content}
        </Drawer>
      )}
    </Fragment>
  )
})

export default Sidebar
