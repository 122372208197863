const ArrowDownIcon = ({ stroke = '#5133EA' }: { stroke?: string }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6667 8.00008L8.00008 12.6667M8.00008 12.6667L3.33341 8.00008M8.00008 12.6667L8.00008 3.33341"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ArrowDownIcon
