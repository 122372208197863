import React from 'react'

interface FormStepProps {
  step: number
  totalSteps: number
  title: string
  children: React.ReactNode
}

const FormStep: React.FC<FormStepProps> = ({
  step,
  totalSteps,
  title,
  children,
}) => {
  return (
    <div className="flex flex-row justify-around w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-full space-y-8 lg:space-y-0 lg:space-x-12 items-center text-txt-default-default">
      <div className="sm:w-[40rem] rounded-lg p-8">
        {/* Step Indicator */}
        <div className="mb-16">
          <p className="text-sm text-txt-default-tertiary mb-3">
            Step {step}/{totalSteps}
          </p>
          <div className="flex items-center">
            {[...Array(totalSteps)].map((_, index) => (
              <div
                key={index}
                className={`flex-1 h-1 ${index < step ? 'bg-bgCol-brand-default' : 'bg-bgCol-neutral-secondary'} rounded-full ${index !== totalSteps - 1 ? 'mr-2' : ''}`}
              ></div>
            ))}
          </div>
        </div>

        {/* Header */}
        <h1 className="text-2xl md:text-[2rem] md:leading-[2.375rem] font-bold text-center">
          {title}
        </h1>

        {/* Dynamic Content */}
        {children}
      </div>
    </div>
  )
}

export default FormStep
