import { marked } from 'marked'

import { Message, Store } from '../../helpers/Store'

/**
 * Verifies if the given Markdown content remains the same after being parsed into HTML and stripped of HTML tags.
 * This is useful for checking if the content contains valid Markdown formatting.
 *
 * @param content - The input Markdown content as a string.
 * @returns A boolean indicating whether the parsed and stripped content differs from the original.
 */
export const verifyMarkdownContent = (content: string) => {
  // Render the Markdown content to HTML
  const renderedOutput = marked(content) as string

  // Remove all HTML tags from the rendered output
  const strippedOutput = renderedOutput.replace(/(<([^>]+)>)/gi, '')

  // Compare the stripped output with the original content after trimming whitespace
  return strippedOutput.trim() !== content.trim()
}

/**
 * Replaces URLs in the given HTML content using the replacement metadata provided in the message.
 * This can be useful for dynamically replacing specific URLs with alternative versions.
 *
 * @param message - The message object containing the replacement metadata.
 * @param htmlContent - The HTML content in which to replace URLs.
 * @returns The updated HTML content with URLs replaced, if applicable.
 */
export const replaceUrls = (message: Message, htmlContent: string) => {
  if (message.replacement_urls_metadata) {
    // Iterate through each URL and its metadata
    Object.entries(message.replacement_urls_metadata).forEach(
      ([originalUrl, metadata]) => {
        // Replace all occurrences of the original URL with the replacement URL
        htmlContent = htmlContent.replaceAll(
          originalUrl,
          metadata.replacement_url
        )
      }
    )
  }
  return htmlContent
}

/**
 * Adjusts the HTML content for a given message by replacing URLs, truncating content, and handling special cases like long text or links.
 * The truncation length depends on the container's width and whether the content is a link or long text without spaces.
 *
 * @param message - The message object containing the original content.
 * @param containerWidth - The width of the container (used to determine truncation length).
 * @returns The adjusted and possibly truncated HTML content or null if there is no message content.
 */
export const truncateAndAdjustHtml = (
  message: Message,
  containerWidth: number
) => {
  // Replace URLs in the content
  const htmlContent = replaceUrls(message, message.content)
  // Determine if the content is a link or a long string without spaces
  const isLinkOrLongTextWithoutSpaces =
    /^(?:https?:\/\/)?(?:www\.)?([a-z0-9]+(?:-[a-z0-9]+)*\.)+[a-z0-9]{2,}$/i.test(
      htmlContent
    ) || !htmlContent.includes(' ')

  // Calculate the maximum number of characters allowed based on container width
  const maxChars =
    isLinkOrLongTextWithoutSpaces && containerWidth > 50
      ? containerWidth / 12 // Scale the truncation length for narrow containers
      : Infinity

  // Truncate the content if it exceeds the maximum character limit
  if (htmlContent.length > maxChars) {
    return `${htmlContent.substring(0, maxChars - 3)}...`
  }

  // Return the adjusted content
  return htmlContent
}

export const extractDocumentReferences = (content: string, store: Store) => {
  const pattern =
    /\[Document ID: ([0-9a-fA-F-]{36})(?:, ((\w+):? [^,\]]+(?:, [^,\]]+)*))?\]/g

  const updatedContent = content.replace(
    pattern,
    (match, documentId, pageOrPages) => {
      const link = `/chat/${store.selectedMatter?.id}/folder/temp/document/${documentId}`
      const pageText = pageOrPages ? ` | ${pageOrPages}` : ''
      return `<a href="${link}">[View File${pageText}]</a>`
    }
  )
  return updatedContent
}
