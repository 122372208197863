import { Tooltip } from '@material-tailwind/react'
import React from 'react'

import InfoIconSmall from '../../assets/InfoIconSmall'
import WarningIcon from '../../assets/WarningIcon'

export const ReadOnlyInfoTooltip = ({ placement }: { placement: string }) => (
  <Tooltip
    className="w-72 max-w-fit z-[9999]"
    placement={placement}
    content="You started working on these matters before your upgrade. Create a new matter to unlock Advanced Legal Reasoning features."
  >
    <button>
      <InfoIconSmall strokeColor="stroke-txt-default-tertiary" />
    </button>
  </Tooltip>
)

const ReadOnlyChatInput = () => {
  return (
    <div className="p-4 text rounded-lg border border-brd-warning-secondary bg-bgCol-warning-secondary flex justify-center items-center space-x-2">
      <WarningIcon />
      <span>This matter is read-only</span>
      <ReadOnlyInfoTooltip placement="top" />
    </div>
  )
}

export default ReadOnlyChatInput
