import { action } from 'mobx'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'

import { Message } from '../../helpers/Store'
import AlexiLogo from '../assets/alexiLogo'

interface AsyncChatMessageProps {
  chat_thread_message: Message
}

const progressEstimateString = (chat_thread_message: Message): string => {
  if (
    !chat_thread_message.async_estimate_at ||
    !chat_thread_message.async_estimate_seconds
  ) {
    return ''
  }

  const startEpoch = chat_thread_message.async_estimate_at.getTime()
  const endEpoch =
    startEpoch + chat_thread_message.async_estimate_seconds * 1000
  const now = Date.now()

  if (now > endEpoch) {
    return 'Ready soon'
  } else if (now > endEpoch - 60_000) {
    return 'Ready in less than a minute'
  } else if (now > endEpoch - 120_000) {
    return 'Ready in about a minute'
  } else {
    const duration = Math.floor((endEpoch - now) / 60_000)
    return `Approximately ${duration} min left.`
  }
}

const AsyncChatMessage = observer(
  ({ chat_thread_message }: AsyncChatMessageProps) => {
    useEffect(() => {
      const updateAsyncProgress = action(() => {
        if (
          !chat_thread_message.async_estimate_at ||
          !chat_thread_message.async_estimate_seconds
        ) {
          return
        }

        const duration = chat_thread_message.async_estimate_seconds * 1000
        const startEpoch = chat_thread_message.async_estimate_at.getTime()
        const endEpoch = startEpoch + duration
        const now = Date.now()

        chat_thread_message.async_progress = 1 - (endEpoch - now) / duration

        if (chat_thread_message.async_progress > 1) {
          // Done
          chat_thread_message.async_progress = 1
        } else {
          window.requestAnimationFrame(updateAsyncProgress)
        }
      })

      window.requestAnimationFrame(updateAsyncProgress)

      // This animation is intended to be specific to this chat_thread_message, and while it wouldn't be damaging to have this animation hook fire more than once, it would be unnecessary. We want this animation setup to fire just once on first render.
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const messageString = chat_thread_message.async_wip_message || 'Working...'

    return (
      <div className={`flex p-6 pb-0 items-start justify-start`}>
        <div className="flex-none w-10 h-10 mr-3">
          <div className="flex justify-center items-center bg-bgCol-brand-default rounded-full w-10 h-10 border border-white shadow">
            <AlexiLogo color="white" width={16} height={16} />
          </div>
        </div>

        <div
          className={`
          text-gray-700 items-center py-3 px-4 rounded-l-lg rounded-br-lg overflow-hidden self-start bg-bgCol-default-tertiary max-w-fit
        `}
        >
          <p className="font-medium">{messageString}</p>
          <p className="text-txt-default-secondary text-sm">
            {progressEstimateString(chat_thread_message)}
          </p>

          <div className="loading-bar self-stretch">
            <div
              className="w-full bg-brd-neutral-secondary flex items-center my-1"
              style={{
                borderRadius: '10px',
                height: '6px',
                background: 'var(--Background-Neutral-Secondary, #F2F2F2)',
              }}
            >
              <div
                className="h-full bg-brd-brand-default"
                style={{
                  width: `${(chat_thread_message.async_progress || 0) * 100}%`,
                  borderRadius: '10px',
                  transition: 'width 1s linear',
                }}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
)

export default AsyncChatMessage
