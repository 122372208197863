// This component represent the initial chat header when the user first opens the chat or starts a new chat.

import React from 'react'

import AlexiLogo from '../assets/alexiLogo'
import Accordion from '../shared/Accordion'

const InitialChatMessage = () => {
  return (
    <div className="flex px-6 pt-3 items-start justify-start">
      <div className="flex-none w-10 h-10 mr-3">
        <div className="flex justify-center items-center bg-bgCol-brand-default rounded-full w-10 h-10 border border-white shadow">
          <AlexiLogo color={'#ffffff'} width={16} height={16} />
        </div>
      </div>

      <div className="max-w-fit p-4 text-gray-700 items-center bg-bgCol-default-tertiary self-start rounded-r-lg rounded-bl-lg">
        <p className="text-sm">
          To begin, ask Alexi a question or describe what you’d like to do.
          Alexi can conduct complex research, draft correspondence, and answer
          legal questions. Alexi can also help you issue spot, brainstorm, and
          strategize.
        </p>
        <div className="py-1.5">
          <Accordion
            title="How can I get a research memo from Alexi?"
            body={
              <div>
                <p>
                  You can request a research memo by asking Alexi to create one.
                  For example,<i> “Can you generate a memo for me?”</i> or{' '}
                  <i>“Please run a memo on this question.”</i>
                </p>
                <p className="mt-1.5">
                  Research memos provide answers to complex legal questions by
                  reference to caselaw and legislation. Each research memo
                  features hyperlinked citations and authorities.
                </p>
              </div>
            }
            open={false}
          />
          <Accordion
            title="What information do I need to provide to obtain a research memo?"
            body={
              <div>
                <p>
                  Once you have requested a memo, you’ll be asked to confirm the
                  applicable jurisdiction and review the phrasing of your
                  question. You can also add facts to contextualize your
                  research.
                </p>
                <p className="mt-1.5">
                  For best results, research questions should be phrased
                  objectively and encompass a single question. For example,{' '}
                  <i>
                    “How has the doctrine of estoppel been applied in paternity
                    proceedings?”
                  </i>
                </p>
              </div>
            }
            open={false}
          />
          <Accordion
            title="How long does it take to generate a research memo?"
            body={
              <div>
                <p>
                  Your research memo will be generated within approximately two
                  minutes. Feel free to keep chatting with Alexi in the
                  meantime.
                </p>
              </div>
            }
            open={false}
          />
        </div>
      </div>
    </div>
  )
}

export default InitialChatMessage
