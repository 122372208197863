import { Dialog, DialogBody, DialogFooter } from '@material-tailwind/react'
import React, { useEffect, useState } from 'react'

import CloseIcon from './assets/CloseIcon'
import assetPath from '../helpers/assetPath'
import { useFeatureFlags } from '../helpers/featureFlags'
import { useStore } from '../helpers/useStore'

const AlrWelcomeModal = () => {
  const store = useStore()
  const { podCia } = useFeatureFlags()

  const [oepn, setOpen] = useState(false)

  useEffect(() => {
    const seenWelcomeModal = localStorage.getItem('hasSeenWelcomeModal')
    if (seenWelcomeModal) {
      return
    }
    if (podCia) {
      setOpen(true)
      localStorage.setItem('hasSeenWelcomeModal', 'true')
    }
  }, [podCia])

  return (
    <Dialog open={oepn} handler={() => {}} className="rounded-lg">
      <DialogBody className="p-0 rounded-t-lg">
        <div className="bg-bgCol-brand-default rounded-t-lg">
          <img
            src={assetPath(store, 'alrWelcome.svg')}
            alt="Image"
            className="rounded-t-lg"
          />
          <button
            onClick={() => {
              setOpen(false)
            }}
            className="absolute top-6 right-6 bg-transparent text-black"
          >
            <CloseIcon className="stroke-icon-utilities-default" />
          </button>
        </div>
      </DialogBody>
      <DialogFooter className="p-0">
        <div className="flex flex-col space-y-3 p-8 pb-6">
          <span className="font-bold text-2xl text-txt-default-default">
            Welcome to Alexi&apos;s Advanced Legal Reasoning
          </span>
          <span className="font-normal text-base text-txt-neutral-default">
            Upload documents. Ask questions. Perform complex analyses. Test
            arguments. Search for cases. Request memos. Draft correspondence.{' '}
          </span>
          <div className="flex justify-end ">
            <button
              onClick={() => {
                setOpen(false)
              }}
              className="w-fit py-3 px-6 rounded-lg bg-bgCol-brand-default h-12 text-txt-utilities-default font-medium"
            >
              Try it out
            </button>
          </div>
        </div>
      </DialogFooter>
    </Dialog>
  )
}

export default AlrWelcomeModal
