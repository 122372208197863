import {
  Button,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from '@material-tailwind/react'
import React, { useState } from 'react'

import FolderIcon from '../assets/FolderIcon'
import ThreeDotsIcon from '../assets/ThreeDotsIcon'

interface FolderTableRowProps {
  folder: any
  handleRowClick: () => void
}

const FolderTableRow = ({ folder, handleRowClick }: FolderTableRowProps) => {
  const [isFolderMenuOpen, setIsFolderMenuOpen] = useState(false)
  return (
    <tr
      key={folder.id}
      className="hover:bg-gray-50 cursor-pointer text-sm h-12"
      onClick={handleRowClick}
    >
      <td className="px-4 py-2 text-txt-default-default max-w-[100px]">
        <div className="flex items-center space-x-2">
          <FolderIcon
            className="stroke-icon-neutral-default flex-shrink-0"
            stroke=""
            backgroundColor=""
            width={18}
            height={18}
          />
          <span className="overflow-hidden truncate flex-grow">
            {folder.title}
          </span>
        </div>
      </td>
      <td className="px-4 py-2">Folder</td>
      <td className="px-4 py-2 items-center justify-center">
        <Menu
          open={isFolderMenuOpen}
          handler={setIsFolderMenuOpen}
          placement="bottom-end"
        >
          <MenuHandler>
            <Button className="bg-transparent p-0 h-6">
              <ThreeDotsIcon variants="secondary" />
            </Button>
          </MenuHandler>
          <MenuList className="p-0 min-w-max text-black text-sm font-normal border-0 z-[9999]">
            <MenuItem onClick={() => {}}>View</MenuItem>
            <MenuItem onClick={() => {}}>Edit</MenuItem>
            <MenuItem onClick={() => {}}>Download</MenuItem>
            <MenuItem onClick={() => {}}>Move to another folder</MenuItem>
            <MenuItem
              className="text-txt-danger-default hover:text-txt-danger-default focus:text-txt-danger-default"
              onClick={() => {}}
            >
              Delete
            </MenuItem>
          </MenuList>
        </Menu>
      </td>
    </tr>
  )
}

export default FolderTableRow
