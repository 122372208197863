import PropTypes from 'prop-types'
import React, { useState } from 'react'

const SelectInput = ({
  value,
  onChange,
  options,
  disabled = false,
  showPlaceholder = true,
}) => {
  const [isFocused, setIsFocused] = useState(false)

  return (
    <div
      className={`border rounded-md ${disabled ? 'bg-bgCol-neutral-default border-brd-disabled-default' : ''} py-1 ${
        isFocused
          ? 'ring-2 outline-none ring-brd-brand-default border-brd-brand-default'
          : 'border-brd-default-default'
      }`}
    >
      <select
        value={value}
        onChange={onChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        disabled={disabled}
        className={`block w-full py-1 border-r-[0.75rem] border-transparent px-3 text-sm focus:outline-none ${
          value ? 'text-txt-default-default' : 'text-txt-default-tertiary'
        } placeholder:text-sm placeholder:text-txt-default-tertiary disabled:bg-bgCol-neutral-default`}
      >
        {showPlaceholder && (
          <option className="text-txt-default-tertiary" value="" disabled>
            Choose an option
          </option>
        )}
        {Object.entries(options).map(([key, optionLabel]) => (
          <option key={key} value={key}>
            {optionLabel}
          </option>
        ))}
      </select>
    </div>
  )
}

SelectInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  showPlaceholder: PropTypes.bool,
}

export default SelectInput
