import {
  IEnterpriseAttachment,
  IInstantMemo,
  ISummaryDocument,
} from '../components/types/interfaces'

export const getFileDisplayName = (
  document: IInstantMemo | IEnterpriseAttachment | ISummaryDocument
): string => {
  if ('filename' in document) {
    // For IEnterpriseAttachment, use filename
    return document.filename
  } else if ('title' in document) {
    // For IInstantMemo and ISummaryDocument, use title
    return document.title
  }
  return ''
}
