import { observer } from 'mobx-react-lite'
import React from 'react'

import FlashMessage from './FlashMessage'
import { useStore } from '../helpers/useStore'

const FlashMessages = observer(() => {
  const store = useStore()

  return (
    <div className="fixed top-4 left-1/2 transform -translate-x-1/2 z-50 w-3/4 max-w-4xl space-y-16">
      {store.flashes.map((flash) => (
        <FlashMessage flash={flash} key={flash.id} />
      ))}
    </div>
  )
})

export default FlashMessages
