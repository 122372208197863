import { Alert } from '@material-tailwind/react'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { handleFileUpload } from '../../actions/fileUploadActions'
import {
  isMdSize,
  setShowDocumentManagers,
} from '../../actions/showLayoutActions'
import { getUploadBatches } from '../../actions/upload_batch/uploadBatchActions'
import { useStore } from '../../helpers/useStore'
import { useScreenSize } from '../../hooks/useScreenSize'
import UploadIcon from '../assets/UploadIcon'
import Breadcrumbs from '../breadcrumbs/Breadcrumbs'
import DocumentViewer from '../documents/document_viewer/DocumentViewer'
import FilesAndFolders from '../documents/FilesAndFolders'
import PaginationComponent from '../documents/PaginationComponent'
import UploadProgressBar from '../documents/UploadProgressBar'
import CollapsibleLayout from '../shared/CollapsibleLayout'

const DocumentManager = observer(() => {
  const store = useStore()
  const { screenWidth } = useScreenSize()
  const navigate = useNavigate()

  // TODO: lock uploads during in progress upload

  const [dragActive, setDragActive] = useState(false)

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setDragActive(true)
  }

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    const relatedTarget = e.relatedTarget as HTMLElement | null

    if (!relatedTarget || !e.currentTarget.contains(relatedTarget)) {
      setDragActive(false)
    }
  }

  const showDocumentViewer = Boolean(store.currentDocument)

  const uploadBatches = getUploadBatches(store, store.selectedMatter?.id)

  return (
    <div
      className={`relative h-[99.65%] w-full ${
        dragActive
          ? 'border-[2.5px] border-brd-brand-default bg-[rgba(245,242,255,0.20)] rounded-[8px]'
          : ''
      }`}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={(e) => {
        setDragActive(false)
        handleFileUpload(e, store, navigate, true)
      }}
    >
      {dragActive && (
        <Alert
          variant="outlined"
          className="absolute bottom-8 left-1/2 transform -translate-x-1/2
            flex flex-row justify-center items-center w-[19.5rem] [&>*]:!mr-0 px-3 py-2 rounded border
            border-brd-neutral-default
            bg-bgCol-default-default
            shadow-[0px_2px_8px_0px_rgba(156,156,156,0.08)]"
          icon={<UploadIcon />}
        >
          <span className="text-black text-sm">
            Drop into &quot;Uploaded Documents&quot;.
          </span>
        </Alert>
      )}
      <CollapsibleLayout
        className="h-full"
        documentPanelStyling={true}
        showLeftControlButton={!isMdSize(screenWidth)}
        handleLeftControlButtonClick={() => {
          setShowDocumentManagers(store, false)
        }}
        headerLeftComponent={
          <span className="flex items-center">{<Breadcrumbs />}</span>
        }
        footerComponent={
          !showDocumentViewer && (
            <div>
              {uploadBatches.map((batch, idx) => {
                return <UploadProgressBar key={idx} uploadBatch={batch} />
              })}
              <PaginationComponent />
            </div>
          )
        }
      >
        {showDocumentViewer ? <DocumentViewer /> : <FilesAndFolders />}
      </CollapsibleLayout>
    </div>
  )
})

export default DocumentManager
