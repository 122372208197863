import React from 'react'

import LegalResearchIcon from '../assets/legalResearchIcon'
import LightbulbIcon from '../assets/lightbulbIcon'
import PencilLineIcon from '../assets/PencilLineIcon'
import TextSearchIcon from '../assets/textSearchIcon'

const InfoModal: React.FC = () => (
  <div className="p-6">
    <p className="text-txt-default-default mb-4">
      Alexi is an AI assistant designed to help you with various legal tasks.
    </p>

    <div className="grid  md:grid-cols-2 grid-cols-1 gap-4">
      <div className="p-4 rounded-lg border border-brd-warning-secondary bg-bgCol-warning-secondary">
        <h3 className="font-medium flex items-start mb-1">
          <span className="mr-2">
            <LightbulbIcon />
          </span>
          <span>Brainstorming</span>
        </h3>
        <p className="text-sm pl-8 font-normal text-txt-default-secondary mt-0">
          Alexi helps you with issue spotting and crafting arguments, and can
          suggest examination questions and document production requests.
        </p>
      </div>

      <div className="p-4 rounded-lg border border-brd-brand-tertiary bg-[#F6F5FE]">
        <h3 className="font-medium flex items-start mb-1">
          <span className="mr-2">
            <LegalResearchIcon />
          </span>
          <span>Legal research</span>
        </h3>
        <p className="text-sm pl-8 font-normal text-txt-default-secondary mt-0">
          Alexi streamlines legal research with everything from quick, in-chat
          answers to detailed research memos.
        </p>
      </div>

      <div className="p-4 rounded-lg border border-brd-info-secondary bg-bgCol-default-tertiaryHover">
        <h3 className="font-medium flex items-start mb-1">
          <span className="mr-2">
            <TextSearchIcon />
          </span>
          <span>Evidentiary analysis</span>
        </h3>
        <p className="text-sm pl-8 font-normal text-txt-default-secondary mt-0">
          In just minutes, Alexi summarizes documents and helps you find key
          details. Simply ask questions, and Alexi will provide answers.
        </p>
      </div>

      <div className="p-4 rounded-lg border border-brd-success-secondary bg-bgCol-success-secondary">
        <h3 className="font-medium flex items-start mb-1">
          <span className="mr-2">
            <PencilLineIcon />
          </span>
          <span>Drafting</span>
        </h3>
        <p className="text-sm pl-8 font-normal text-txt-default-secondary mt-0">
          Alexi can draft emails or letters for you, whether it&apos;s routine
          and time-consuming, or tricky and you need help overcoming
          writer&apos;s block.
        </p>
      </div>
    </div>
  </div>
)

export default InfoModal
