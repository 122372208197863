import { observer } from 'mobx-react-lite'
import React from 'react'

import { removeFile } from '../../actions/fileActions'
import { useStore } from '../../helpers/useStore'
import CloseIcon from '../assets/CloseIcon'
import FaildUploadIcon from '../assets/FaildUploadIcon'
import Tooltip from '../shared/Tooltip'

interface FailedFileCardProps {
  fileId: string
  filename: string
  error: string
}

const FailedInputFileCard: React.FC<FailedFileCardProps> = ({
  fileId,
  filename,
  error,
}) => {
  const store = useStore()

  const handleRemoveFileClick = (localAttachmentId: string) => {
    removeFile(store, localAttachmentId)
  }

  return (
    <div
      key={fileId}
      className="relative p-3 border border-brd-danger-secondary bg-bgCol-default-default rounded-lg group max-w-full"
    >
      <div className="flex">
        <div className="flex-shrink-0 p-2 bg-bgCol-danger-tertiary rounded">
          <FaildUploadIcon />
        </div>
        <div className="w-full overflow-hidden ml-2 flex-1 text-txt-danger-secondary">
          <Tooltip tooltipContents={filename} position="above">
            <p className="text-sm font-medium truncate">{filename}</p>
          </Tooltip>
          <p className="text-xs">{error}</p>
        </div>
      </div>

      <div className="absolute top-0 right-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
        <button
          onClick={() => handleRemoveFileClick(fileId)}
          type="button"
          className="absolute bottom-[-0.5rem] right-[-0.5rem] text-black-500 hover:black focus:outline-none"
        >
          <div className="bg-white p-[2px] shadow-sm rounded-md hover:bg-bgCol-default-defaultHover">
            <CloseIcon />
          </div>
        </button>
      </div>
    </div>
  )
}

export default observer(FailedInputFileCard)
