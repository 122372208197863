import { observer } from 'mobx-react-lite'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import GearIcon from './assets/GearIcon'
import { ProfileDropdown } from './header/ProfileDropdown'
import { setSelectedMatter } from '../actions/matterActions'
import assetPath from '../helpers/assetPath'
import { useStore } from '../helpers/useStore'

const LegacyHeader = observer(() => {
  const [showDropdown, setShowDropdown] = React.useState(false)

  const store = useStore()
  const navigate = useNavigate()

  const handleProfileClick = () => {
    setShowDropdown(!showDropdown)
  }

  return (
    <div className="flex items-center justify-between p-4 border-b bg-white h-[4.5rem]">
      <a
        className="flex items-center"
        onClick={() => {
          setSelectedMatter(store, null)
          navigate('/chat')
        }}
      >
        <img src={assetPath(store, 'alexiTitleLogo.svg')} alt="Image" />
      </a>
      <div className="flex items-center space-x-4">
        {store.user?.admin && (
          <a href={`${store.credentials.customerAppUrl}/firms/admin/account`}>
            <GearIcon />
          </a>
        )}
        <div className="flex items-center">
          {store.user && (
            <div onClick={handleProfileClick} className="cursor-pointer">
              <div className="flex items-center space-x-2 bg-gray-50 p-2 pr-4 rounded-full">
                <div className="flex justify-center items-center uppercase bg-bgCol-brand-default rounded-full w-10 h-10 ">
                  <span className="text-sm font-semibold text-white">
                    {store.user.initials}
                  </span>
                </div>
                <span className="text-sm font-medium text-black">
                  {store.user.full_name}
                </span>
              </div>
              <ProfileDropdown showDropdown={showDropdown} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
})

export default LegacyHeader
