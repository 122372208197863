import React from 'react'

import { ResponseAdditionalContext } from '../../../helpers/Store'
import { useStore } from '../../../helpers/useStore'

interface MemoFormSubmittedProps {
  responseAdditionalContext: ResponseAdditionalContext
}

const MemoFormSubmitted = ({
  responseAdditionalContext,
}: MemoFormSubmittedProps) => {
  const store = useStore()
  const normalizeValue = (value?: string) => {
    return value === 'none' || value === undefined || value === 'null'
      ? ''
      : value
  }

  const renderField = (label: string, content?: string) => {
    const normalizedContent = normalizeValue(content)
    return normalizedContent ? (
      <div className="mb-6">
        <p className="text-txt-default-default font-medium text-lg mb-2">
          {label}
        </p>
        <p
          className="text-txt-default-default font-normal text-base mt-1"
          dangerouslySetInnerHTML={{ __html: normalizedContent }}
        />
      </div>
    ) : null
  }

  const renderJurisdictionRow = () => {
    const jurisdiction = normalizeValue(responseAdditionalContext.jurisdiction)
    const state = normalizeValue(responseAdditionalContext.state)

    if (!store.user) {
      return null
    }

    // Determine what to render based on conditions
    if (store.user.enterprise.country_code === 'CA') {
      // For Canada, only render the state
      return (
        <div className="mb-6 flex flex-wrap items-start gap-4">
          {state && (
            <div className="flex-1">
              <p className="text-txt-default-default font-medium text-lg mb-2">
                State
              </p>
              <p
                className="text-txt-default-default font-normal text-base mt-1"
                dangerouslySetInnerHTML={{ __html: state }}
              />
            </div>
          )}
        </div>
      )
    } else {
      // For US, always render jurisdiction
      return (
        <div className="mb-6 flex flex-wrap items-start gap-4">
          {jurisdiction && (
            <div className="flex-1">
              <p className="text-txt-default-default font-medium text-lg mb-2">
                Jurisdiction
              </p>
              <p
                className="text-txt-default-default font-normal text-base mt-1"
                dangerouslySetInnerHTML={{ __html: jurisdiction }}
              />
            </div>
          )}
          {state && (
            <div className="flex-1">
              <p className="text-txt-default-default font-medium text-lg mb-2">
                {jurisdiction === 'Federal' ? 'Forum' : 'State'}
              </p>
              <p
                className="text-txt-default-default font-normal text-base mt-1"
                dangerouslySetInnerHTML={{ __html: state }}
              />
            </div>
          )}
        </div>
      )
    }
  }

  return (
    <div className="rounded-lg">
      {/* Research Question Section */}
      {renderField(
        'Research question',
        responseAdditionalContext.research_question
      )}

      {/* Research Facts Section */}
      {renderField(
        'Background facts',
        responseAdditionalContext.research_facts
      )}

      {/* Jurisdiction, State, and Forum Section */}
      {renderJurisdictionRow()}
    </div>
  )
}
export default MemoFormSubmitted
