const ArrowUpIcon = ({ stroke = '#5133EA' }: { stroke?: string }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33337 8.00004L8.00004 3.33337M8.00004 3.33337L12.6667 8.00004M8.00004 3.33337V12.6667"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ArrowUpIcon
