import React from 'react'
import { UseFormRegisterReturn } from 'react-hook-form'

interface FormRadioGroupProps {
  label: string
  options: string[]
  error?: string
  register: UseFormRegisterReturn
}

export const FormRadioGroup: React.FC<FormRadioGroupProps> = ({
  label,
  options,
  error,
  register,
}) => (
  <div>
    <p className="block text-sm font-medium mb-2">{label}</p>
    <div className="flex items-center space-x-8">
      {options.map((option) => (
        <label key={option} className="inline-flex items-center space-x-2">
          <input
            type="radio"
            value={option}
            {...register}
            className="form-radio text-txt-brand-default"
          />
          <span className="text-sm">{option}</span>
        </label>
      ))}
    </div>
    {error && <p className="text-txt-danger-default text-sm mt-1">{error}</p>}
  </div>
)
