import { action } from 'mobx'
import { observer } from 'mobx-react-lite'
import React from 'react'

import ActivitiesColumn from './ActivitiesColumn'
import DocumentsColumn from './DocumentsColumn'
import { WorkflowBase } from './WorkflowLayout'
import { useStore } from '../../helpers/useStore'

const MatterWorkflow = observer(() => {
  const store = useStore()

  const tabs = [
    {
      key: 'activities',
      label: 'Activity',
      component: <ActivitiesColumn />,
    },
    {
      key: 'documents',
      label: 'Documents',
      component: <DocumentsColumn />,
    },
  ]

  return (
    <WorkflowBase
      title="Workflow"
      tabs={tabs}
      activeTab={store.workflowTab}
      onTabChange={action((tab) => (store.workflowTab = tab))}
    />
  )
})

export default MatterWorkflow
