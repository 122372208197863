import React from 'react'

const ShieldIcon: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Icon">
      <g id="Group">
        <path
          id="Vector"
          d="M20.5971 13.0985C20.5971 18.5975 16.7477 21.3471 12.1725 22.9418C11.9329 23.023 11.6726 23.0191 11.4356 22.9308C6.84936 21.3471 3 18.5975 3 13.0985V5.39976C3 5.10807 3.11587 4.82833 3.32213 4.62207C3.52838 4.41582 3.80813 4.29994 4.09982 4.29994C6.29945 4.29994 9.04899 2.98016 10.9627 1.30844C11.1957 1.10937 11.4921 1 11.7985 1C12.105 1 12.4014 1.10937 12.6344 1.30844C14.5591 2.99116 17.2976 4.29994 19.4972 4.29994C19.7889 4.29994 20.0687 4.41582 20.2749 4.62207C20.4812 4.82833 20.5971 5.10807 20.5971 5.39976V13.0985Z"
          className="stroke-icon-brand-default"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path
        id="Vector_2"
        d="M9 12L11 14L15 10"
        className="stroke-icon-brand-default"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
)

export default ShieldIcon
