import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import PaperclipIcon from './assets/PaperclipIcon'
import PlaneIcon from './assets/PlaneIcon'
import SpinnerIcon from './assets/SpinnerIcon'
import {
  ALLOWED_FILE_TYPES,
  handleFilesUploadLegacy,
} from './file_uploads/HandleFilesUpload'
import SlidingFilePopup from './file_uploads/SlidingFilePopup'
import IconButton from './IconButton'
import addFlash from '../actions/AddFlash'
import { submitMessage, submitLegacyFiles } from '../actions/chatInputActions'
import { setChatResponseLoading } from '../actions/chatResponseLoadingActions'
import { getInputValue, setInputValue } from '../actions/InputValueActions'
import { setNewMatter } from '../actions/matterActions'
import { getMessages } from '../actions/messagesActions'
import { createMatter } from '../apis/mattersApi'
import {
  storeMessageObject,
  getMessageObject,
  removeStoredMessage,
} from '../helpers/LocalStorageUtil'
import { useStore } from '../helpers/useStore'

const LegacyChatInput = observer(() => {
  const store = useStore()
  const navigate = useNavigate()
  const inputRef = useRef(null)
  const DEFAULT_TEXTAREA_HEIGHT = 4

  const [sendMessageInProgress, setSendMessageInProgress] = useState(false)

  const matterId = store.selectedMatter?.id
  const filteredFiles = store.uploadedFiles.filter(
    (file) => file.matterId === matterId
  )
  const filesAttached = filteredFiles.length > 0
  const invalidFilesExist =
    filesAttached && filteredFiles.some((file) => file.status !== 'validated')

  const sendMessageDisabled =
    (getInputValue(store).length === 0 && store.uploadedFiles.length === 0) ||
    store.chatResponseLoading[matterId] ||
    invalidFilesExist ||
    sendMessageInProgress

  useEffect(() => {
    runInAction(() => {
      store.inputRef = inputRef.current
      store.inputRef?.focus()
    })

    const savedInput = getInputWithExpiration(matterId || 'newMatter')

    if (savedInput) {
      setInputValue(store, savedInput)
    }
  }, [store, matterId])

  useEffect(() => {
    const textarea = inputRef.current
    adjustHeight()

    textarea.addEventListener('input', adjustHeight)

    return () => {
      textarea.removeEventListener('input', adjustHeight)
    }
  }, [])

  const adjustHeight = () => {
    const textarea = inputRef.current
    textarea.style.height = 'auto'
    textarea.style.height = `${Math.min(textarea.scrollHeight, 200)}px`
  }

  const resetHeight = () => {
    const textarea = inputRef.current
    textarea.style.height = `${DEFAULT_TEXTAREA_HEIGHT * 16}px`
  }

  // eslint-disable-next-line consistent-return
  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()

      if (!sendMessageDisabled && !store.isStreaming[matterId]) {
        handleSendMessage()
      } else {
        return false
      }
    }
  }

  const handleInputChange = (e) => {
    setInputValue(store, e.target.value)
    saveInputWithExpiration(store, 6)
  }

  const handleSendMessage = async () => {
    try {
      setSendMessageInProgress(true)

      if (!matterId) {
        setNewMatter(store, await createMatter(store))
        navigate(`/chat/${store.selectedMatter.id}`)
      }

      if (filesAttached) {
        submitLegacyFiles(store)
      } else {
        submitMessage(store)
      }

      resetHeight()
      setSendMessageInProgress(false)
    } catch (error) {
      if (error.response.status === 401) {
        addFlash(
          store,
          'error',
          'You are not authorized to perform this action.'
        )
      }

      setSendMessageInProgress(false)
    }
  }

  const messages = getMessages(store, matterId) ?? []
  const mostRecentMessageType = messages[messages?.length - 1]?.message_type

  const formFinishedStates = ['memoFormSubmitted', 'memoFormCancelled']
  const formInProgressStates = ['memoFormRendered']

  const formFinishedStatus = formFinishedStates.includes(mostRecentMessageType)
  const formInProgressStatus = formInProgressStates.includes(
    mostRecentMessageType
  )

  if (formInProgressStatus || formFinishedStatus) {
    if (formFinishedStatus) {
      setChatResponseLoading(store, matterId, true)
    }

    return (
      <div className="p-4 bg-white border rounded-lg shadow-md">
        <div className="flex items-center justify-between">
          <DisabledFileButton />
          <DisabledSendButton />
        </div>
      </div>
    )
  }

  const handleFileInputChange = (event) => {
    runInAction(() => {
      if (store.inputRef) {
        store.inputRef.focus()
      }
    })

    handleFilesUploadLegacy(
      store,
      filteredFiles.length,
      Array.from(event.target.files),
      navigate
    )

    event.target.value = ''
  }

  return (
    <div
      className={`bg-white border rounded-lg shadow-md ${
        filesAttached
          ? 'border-brd-brand-default'
          : ' focus-within:border-brd-brand-default'
      } transition-all duration-400 ease-in-out`}
    >
      <textarea
        placeholder="Enter your request"
        className={`data-hj-allow p-4 w-full rounded-t-lg h-[${DEFAULT_TEXTAREA_HEIGHT}rem] resize-none overflow-y-auto text-sm font-normal ${
          filesAttached
            ? 'h-10 text-txt-disabled-default bg-white'
            : `h-[${DEFAULT_TEXTAREA_HEIGHT}rem]`
        }`}
        value={getInputValue(store)}
        onKeyPress={handleKeyPress}
        onChange={handleInputChange}
        style={{
          border: 'none',
          outline: 'none',
          transition: 'height 0.2s ease',
        }}
        ref={inputRef}
        onFocus={() => (store.isInputFocused = true)}
        onBlur={() => (store.isInputFocused = false)}
        disabled={filesAttached}
      />

      <div className="bg-bgCol-default-tertiary rounded-b-lg">
        <SlidingFilePopup files={filteredFiles} />
        <div className="px-4 py-2 flex items-center justify-between rounded-b-lg">
          <div className="flex space-x-2">
            <EnabledFileButton />
            <input
              id="fileInput"
              type="file"
              multiple
              accept={ALLOWED_FILE_TYPES}
              onChange={handleFileInputChange}
              className="hidden"
            />
          </div>
          {sendButton({
            store,
            matterId,
            sendMessageDisabled,
            handleSendMessage,
          })}
        </div>
        {filesAttached && (
          <div className="px-4 py-2 bg-bgCol-brand-secondary text-xs font-normal text-txt-brand-default rounded-b-lg">
            You can continue typing your request after submitting the documents
            to the chat.
          </div>
        )}
      </div>
    </div>
  )
})

const saveInputWithExpiration = (store, expirationInHours) => {
  if (getInputValue(store).length === 0) {
    removeStoredMessage(store.selectedMatter?.id || 'newMatter')
    return
  }

  const expirationTime =
    new Date().getTime() + expirationInHours * 60 * 60 * 1000
  const data = {
    value: getInputValue(store),
    expirationTime: expirationTime,
  }
  storeMessageObject(store.selectedMatter?.id || 'newMatter', data)
}

const getInputWithExpiration = (key) => {
  const data = getMessageObject(key)

  if (!data) {
    return null
  }

  const currentTime = new Date().getTime()
  if (currentTime > data.expirationTime) {
    removeStoredMessage(key)
    return null
  }
  return data.value
}

const sendButton = ({
  store,
  matterId,
  sendMessageDisabled,
  handleSendMessage,
}) => {
  if (matterId && store.isStreaming[matterId]) {
    return <SpinnerSendButton />
  }

  if (sendMessageDisabled) {
    return <DisabledSendButton />
  }

  return <EnabledSendButton handleSendMessage={handleSendMessage} />
}

const DisabledFileButton = () => (
  <IconButton
    icon={PaperclipIcon}
    className="cursor-not-allowed"
    id="attachFilesButton"
    stroke="#CECECE"
  />
)

const EnabledFileButton = () => (
  <IconButton
    icon={PaperclipIcon}
    onClick={() => document.getElementById('fileInput').click()}
    id="attachFilesButton"
  />
)

const DisabledSendButton = () => (
  <IconButton
    icon={PlaneIcon}
    className="cursor-not-allowed"
    id="sendButton"
    stroke="#CECECE"
  />
)
const SpinnerSendButton = () => (
  <IconButton
    icon={SpinnerIcon}
    className="bg-icon-brand-default"
    id="sendButton"
    stroke="#ffffff"
  />
)

const EnabledSendButton = ({ handleSendMessage }) => (
  <IconButton
    icon={PlaneIcon}
    onClick={handleSendMessage}
    className="bg-icon-brand-default"
    id="sendButton"
  />
)

EnabledSendButton.propTypes = {
  handleSendMessage: PropTypes.func.isRequired,
}

export default LegacyChatInput
