import { IDocument } from '@cyntler/react-doc-viewer'
import { Button } from '@material-tailwind/react'
import React from 'react'

import { downloadFile } from '../../../apis/filesApi'
import { useStore } from '../../../helpers/useStore'
import DocumentDefaultOutline from '../../assets/DocumenetDefaultOutline'
import DownloadIcon from '../../assets/DownloadIcon'

interface DocumentViewerNoRenderProps {
  document: IDocument & { id: string }
  fileName: string
}
const DocumentViewerNoRender = ({ document }: DocumentViewerNoRenderProps) => {
  const store = useStore()

  const { id } = document
  const handleDownload = async () => {
    if (id) {
      await downloadFile(store, id)
    }
  }
  return (
    <div className="w-full h-[calc(100vh-158px)] flex flex-col items-center justify-center">
      <DocumentDefaultOutline />
      <span className="font-semibold text-lg">
        Sorry, we&apos;re unable to open a preview of this document
      </span>
      <span className="font-light text-sm pb-6">
        Please download the document to view it
      </span>
      {id && (
        <Button
          className="px-4 py-2 flex bg-bgCol-brand-default rounded space-x-2"
          onClick={handleDownload}
        >
          <DownloadIcon classNames="stroke-icon-utilities-default" />
          <span className="text-txt-utilities-default text-sm font-normal">
            Download
          </span>
        </Button>
      )}
    </div>
  )
}

export default DocumentViewerNoRender
