import { action, runInAction } from 'mobx'
import React from 'react'

import { fetchActivities } from '../apis/workflowActivitiesApi'
import { rollbarConfig } from '../helpers/rollbarConfig'

const setActivities = action(async (store) => {
  try {
    const activities = await fetchActivities(store, store.selectedMatter?.id)

    if (activities.length === 0) {
      runInAction(() => {
        store.activities = []
      })
      return
    }

    runInAction(() => {
      store.activities = activities.map((activity) => ({
        ...activity,
        ref: React.createRef(),
      }))
    })
  } catch (error) {
    rollbarConfig(store)?.error(error)
  }
})

export { setActivities }
