import { action } from 'mobx'

import Store from '../helpers/Store'

const isMdSize = (screenWidth: number) => screenWidth >= 768

const isLgSize = (screenWidth: number) => screenWidth >= 1024

const is2xLSize = (screenWidth: number) => screenWidth >= 1536

const setShowSidebar = action((store: Store, value: boolean) => {
  store.showLayouts.showSidebar = value
})

const setShowDocumentManagers = action((store: Store, value: boolean) => {
  store.showLayouts.showDocumentManager = value
})

const setShowLatestMessagesButton = action((store: Store, value: boolean) => {
  store.showLatestMessagesButton = value
})

export {
  isMdSize,
  isLgSize,
  is2xLSize,
  setShowSidebar,
  setShowDocumentManagers,
  setShowLatestMessagesButton,
}
