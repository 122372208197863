import { marked } from 'marked'
import PropTypes from 'prop-types'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import RemarkEmoji from 'remark-emoji'
import RemarkGfm from 'remark-gfm'

import DocSummaryMessage from './doc_summary/DocSummaryMessage'
import { EmptyMemoMessage } from './memoGeneration/EmptyMemoMessage'
import MemoErrorMessage from './memoGeneration/MemoErrorMessage'
import MemoForm from './memoGeneration/MemoForm'
import MemoFormSubmitted from './memoGeneration/MemoFormSubmitted'
import MemoMessage from './memoGeneration/MemoMessage'
import { replaceAlexiTextWithLogo } from '../../actions/messagesActions'
import { useStore } from '../../helpers/useStore'
import AlexiLogo from '../assets/alexiLogo'
import FailedFileCard from '../file_uploads/FailedFileCard'
import FileCard from '../file_uploads/FileCard'

const LegacyChatMessage = ({ message, mainChatContainerWidth }) => {
  const store = useStore()

  if (
    message.role === 'system' &&
    message.message_type === 'systemMessage' &&
    !message.content &&
    !['memoFormRendered', 'memoFormSubmitted', 'memoResult'].includes(
      message.message_type
    )
  ) {
    return null
  }

  const verifyMarkdownContent = () => {
    const renderedOutput = marked(message.content)
    const strippedOutput = renderedOutput.replace(/(<([^>]+)>)/gi, '')
    return strippedOutput.trim() !== message.content.trim()
  }

  const replaceUrls = (htmlContent) => {
    if (message.replacement_urls_metadata) {
      Object.entries(message.replacement_urls_metadata).forEach(
        ([originalUrl, metadata]) => {
          htmlContent = htmlContent.replaceAll(
            originalUrl,
            metadata.replacement_url
          )
        }
      )
    }
    return htmlContent
  }

  const truncateAndAdjustHtml = (htmlContent) => {
    if (!htmlContent) {
      return null
    }

    htmlContent = replaceUrls(htmlContent)

    const isLinkOrLongTextWithoutSpaces =
      /^(?:https?:\/\/)?(?:www\.)?([a-z0-9]+(?:-[a-z0-9]+)*\.)+[a-z0-9]{2,}$/i.test(
        htmlContent
      ) || !htmlContent.includes(' ')
    const maxChars =
      isLinkOrLongTextWithoutSpaces && mainChatContainerWidth > 50
        ? mainChatContainerWidth / 12
        : Infinity
    // Truncate the content if it exceeds maxLength
    if (htmlContent.length > maxChars) {
      return `${htmlContent.substring(0, maxChars - 3)}...`
    }
    return htmlContent
  }

  const renderChatContent = () => {
    const adjustedContent = truncateAndAdjustHtml(message?.content)
    // Preprocess the content by replacing "Alexi" with the logo
    const preprocessedContent = replaceAlexiTextWithLogo(adjustedContent, store)

    if (
      message.role === 'system' &&
      message.current_type === 'markdown' &&
      verifyMarkdownContent()
    ) {
      return (
        <ReactMarkdown
          remarkPlugins={[[RemarkGfm], [RemarkEmoji]]}
          rehypePlugins={[rehypeRaw]} // Allows rendering of raw HTML
          className="no-tailwind markdown-content"
          components={{
            // eslint-disable-next-line no-unused-vars,unused-imports/no-unused-vars
            a: ({ node, ...props }) => (
              <a {...props} target="_blank" rel="noopener noreferrer" />
            ),
            p: ({ children }) => <p>{children}</p>,
          }}
        >
          {preprocessedContent}
        </ReactMarkdown>
      )
    }

    // For non-system messages or non-markdown content
    return <span dangerouslySetInnerHTML={{ __html: preprocessedContent }} />
  }

  const renderMessage = () => {
    switch (message.message_type) {
      case 'memoFormRendered':
        return (
          <MemoForm
            responseAdditionalContext={message.response_additional_context}
          />
        )
      case 'memoFormSubmitted':
        return (
          <MemoFormSubmitted
            responseAdditionalContext={message.response_additional_context}
          />
        )
      case 'instantMemoRetryForbidden':
      case 'instantMemoRetrySuggested':
      case 'instantMemoRejected':
        return <MemoErrorMessage message={message} store={store} />
      case 'memoResult':
        return (
          <MemoMessage
            message={message}
            containerWidth={mainChatContainerWidth}
          />
        )
      case 'docSummaryResult':
        return <DocSummaryMessage message={message} />
      case 'emptyMemoForm':
        return <EmptyMemoMessage />
      default:
        return renderChatContent()
    }
  }

  const isMemoStepFormStyling = (msg) =>
    ['memoFormRendered'].includes(msg?.message_type)

  const isFailedMemoStep = (msg) =>
    [
      'instantMemoRetryForbidden',
      'instantMemoRetrySuggested',
      'instantMemoRejected',
    ].includes(msg?.message_type)

  const renderFileAttachments = (msg) =>
    msg?.enterprise_attachments
      ? msg.enterprise_attachments.map((attachment) => {
          if (
            attachment.status === 'FAILED' ||
            attachment.status === 'INVALID'
          ) {
            return (
              <FailedFileCard
                key={attachment.file.id}
                id={attachment.file.id}
                filename={attachment.filename}
                error={attachment?.error_msg}
              />
            )
          } else {
            return <FileCard key={attachment.file.id} attachment={attachment} />
          }
        })
      : null

  const messageStyles = () => {
    switch (true) {
      case isFailedMemoStep(message):
        return 'self-start rounded-r-lg rounded-bl-lg border-brd-warning-secondary'
      case message.role === 'user':
        return 'py-3 px-4 bg-bgCol-default-secondary self-end rounded-l-lg rounded-br-lg'
      default:
        return 'py-3 px-4 bg-bgCol-default-tertiary self-start rounded-r-lg rounded-bl-lg'
    }
  }

  return (
    <div
      className={`flex p-6 pb-0 items-start ${
        message.role === 'user' ? 'justify-end' : 'justify-start'
      }`}
      ref={message.ref}
    >
      {message.role !== 'user' && (
        <div className="flex-none w-10 h-10 mr-3">
          <div className="flex justify-center items-center bg-bgCol-brand-default rounded-full w-10 h-10 border border-white shadow">
            <AlexiLogo color={'#ffffff'} width={16} height={16} />
          </div>
        </div>
      )}

      <div
        className={`
          text-gray-700 items-center
          ${isMemoStepFormStyling(message) ? 'w-full' : 'max-w-fit'}
          ${messageStyles()}
        `}
      >
        {renderMessage()}
        <div className="flex flex-wrap justify-end gap-4 max-w-[670px]">
          {renderFileAttachments(message)}
        </div>
      </div>

      {message.role === 'user' && (
        <div className="flex justify-center items-center uppercase bg-gray-50 rounded-full w-10 h-10 p-4 ml-3 border border-white shadow">
          <span className="text-lg font-semibold text-black">
            {store.user?.initials}
          </span>
        </div>
      )}
    </div>
  )
}

LegacyChatMessage.propTypes = {
  message: PropTypes.shape({
    content: PropTypes.string,
    current_type: PropTypes.string.isRequired,
    enterprise_attachments: PropTypes.array,
    id: PropTypes.string,
    message_type: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.oneOf([null]),
    ]),
    ref: PropTypes.object,
    role: PropTypes.string.isRequired,
    response_additional_context: PropTypes.shape({
      research_facts: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.oneOf([null]),
      ]),
      research_question: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.oneOf([null]),
      ]),
      jurisdiction: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.oneOf([null]),
      ]),
      state: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
    }),
    replacement_urls_metadata: PropTypes.object,
  }).isRequired,
  mainChatContainerWidth: PropTypes.number.isRequired,
}

export default LegacyChatMessage
