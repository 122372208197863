import { Navbar, IconButton } from '@material-tailwind/react'
import React, { ReactNode } from 'react'

import ArrowLeftToLine from '../assets/ArrowLeftToLine'
import ArrowRightToLine from '../assets/ArrowRightToLine'
import CloseIcon from '../assets/CloseIcon'

export interface CollapsibleLayoutProps {
  children?: ReactNode | ReactNode[]
  className?: string
  footerComponent?: ReactNode
  handleCloseButtonClick?: () => void
  handleLeftControlButtonClick?: () => void
  handleRightControlButtonClick?: () => void
  headerLeftComponent?: ReactNode
  headerRightComponent?: ReactNode
  documentPanelStyling?: boolean
  navBarClassName?: string
  showCloseButton?: boolean
  showLeftControlButton?: boolean
  showRightControlButton?: boolean
}
const CollapsibleLayout = (props: CollapsibleLayoutProps) => {
  const {
    children,
    className = '',
    footerComponent,
    handleCloseButtonClick,
    handleLeftControlButtonClick,
    handleRightControlButtonClick,
    headerLeftComponent,
    headerRightComponent,
    documentPanelStyling = false,
    navBarClassName = '',
    showCloseButton = false,
    showLeftControlButton = false,
    showRightControlButton = false,
  } = props

  return (
    <div className={`h-screen flex flex-col ${className}`}>
      <Navbar
        className={`sticky top-0 z-10 ${documentPanelStyling ? 'p-0 border-0 rounded-lg' : 'p-4 rounded-none'} max-w-full ${navBarClassName}`}
      >
        <div className="container max-w-full h-full flex items-center justify-between">
          <div className="flex items-center gap-4">
            {showLeftControlButton && (
              <div className={documentPanelStyling ? 'p-4' : 'p-0'}>
                <IconButton
                  className="bg-bgCol-default-default w-5 h-5"
                  onClick={handleLeftControlButtonClick}
                >
                  <ArrowRightToLine />
                </IconButton>
              </div>
            )}
            {headerLeftComponent}
          </div>
          <div className="flex items-center gap-4">
            {headerRightComponent}
            {showRightControlButton && (
              <div className={documentPanelStyling ? 'p-4' : 'p-0'}>
                <IconButton
                  className="bg-bgCol-default-default w-5 h-5"
                  onClick={handleRightControlButtonClick}
                >
                  <ArrowLeftToLine />
                </IconButton>
              </div>
            )}
            {showCloseButton && (
              <div className={documentPanelStyling ? 'p-4' : 'p-0'}>
                <IconButton
                  className="bg-bgCol-default-default w-5 h-5"
                  onClick={handleCloseButtonClick}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            )}
          </div>
        </div>
      </Navbar>

      <div className="flex-grow container max-w-full flex overflow-auto">
        {children}
      </div>

      <div className="w-full sticky bottom-0">{footerComponent}</div>
    </div>
  )
}

export default CollapsibleLayout
