import React from 'react'
import { Control, Controller, RegisterOptions } from 'react-hook-form'

import SelectInput from './SelectInput'

interface FormControlledSelectProps {
  control: Control<any>
  name: string
  label: string
  error?: string
  options: Record<string, string>
  rules?: RegisterOptions
  disabled?: boolean
}

export const FormControlledSelect: React.FC<FormControlledSelectProps> = ({
  control,
  name,
  label,
  error,
  options,
  rules,
  disabled = false,
}) => (
  <div>
    <label className="block text-sm font-medium mb-2">{label}</label>
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <SelectInput {...field} options={options} disabled={disabled} />
      )}
    />
    {error && <p className="text-txt-danger-default text-sm mt-1">{error}</p>}
  </div>
)
