import axios, { AxiosError } from 'axios'

interface Enterprise {
  account_active: boolean
  country_code: string
  created_at: string
  credit_card_on_file: string
  id: number
  internal: boolean
  is_firm_disabled: boolean
  is_on_max_chat_app: boolean
  name: string
  state: string
  logo_url: string | null
  customTheme: CustomTheme | null
}

export interface CustomTheme {
  brandDefault: string
  brandDefaultHover: string
  brandDefaultTertiary: string
  brandDefaultSecondary: string
}

interface Country {
  common_name: string
  abbreviation: string
}

export interface UserInfo {
  id: number
  email: string
  first_name: string
  last_name: string
  full_name: string
  initials: string
  created_at: string
  admin: boolean
  enterprise: Enterprise
  country: Country
  iana_timezone: string
  current_onboarding_step: string
  onboarding_completed: boolean
  role: string
  auth_setup_needed: boolean
}

axios.defaults.withCredentials = true

export const fetchUserInfo = async (
  customerAppUrl: string
): Promise<UserInfo | null> => {
  try {
    const response = await axios.get(
      `${customerAppUrl}/max_chat_api/v1/current_user`,
      {
        headers: {
          Accept: 'application/json',
        },
      }
    )

    return response.data
  } catch (error: unknown) {
    const err = error as AxiosError

    if (err.response?.status === 401) {
      window.location.href = `${customerAppUrl}/users/sign_in`
      return null
    } else {
      console.error('Error fetching user info:', err)
      throw err
    }
  }
}
