import React from 'react'

interface FormInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string
  error?: string
}

// Use React.forwardRef to forward the ref to the underlying input element.
export const FormInput = React.forwardRef<HTMLInputElement, FormInputProps>(
  ({ label, error, ...props }, ref) => {
    return (
      <div>
        <label className="block text-sm font-medium mb-2">{label}</label>
        <input
          {...props}
          ref={ref} // Attach the ref here
          className={`h-10 mt-1 text-sm block w-full border rounded-md px-4 py-2 focus:ring-1 focus:outline-none focus:ring-brd-brand-default focus:border-brd-brand-default ${
            error ? 'border-brd-danger-default' : 'border-brd-default-default'
          } placeholder:text-sm placeholder:text-txt-default-tertiary`}
        />
        {error && (
          <p className="text-txt-danger-default text-sm mt-1">{error}</p>
        )}
      </div>
    )
  }
)
FormInput.displayName = 'FormInput'
