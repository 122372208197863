import React from 'react'

import WarningIcon from '../assets/WarningIcon'

const ReadOnlyDocumentHeader = () => {
  return (
    <div className="p-4 text rounded-lg border border-brd-warning-secondary bg-bgCol-warning-secondary">
      <div className="flex flex-col-2 justify-center items-center space-x-4">
        <div>
          <WarningIcon className="w-5 h-5" />
        </div>
        <span>
          Documents are read-only, please start a new matter to upload
          documents.
        </span>
      </div>
    </div>
  )
}

export default ReadOnlyDocumentHeader
