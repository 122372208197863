import React from 'react'

const FileIcon: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Icon">
      <g id="Group 1000003815">
        <g id="Group">
          <path
            id="Vector"
            d="M17.6666 22.9997H5.44452C4.79622 22.9997 3.89841 23.0182 3.44 22.5598C2.98158 22.1013 3.0001 21.2035 3.0001 20.5552V3.44428C3.0001 2.79598 2.98158 1.89817 3.44 1.43975C3.89842 0.981335 4.79622 0.999859 5.44452 0.999859H14L20.1111 7.11091V20.5552C20.1111 21.2035 20.1296 22.1013 19.6712 22.5598C19.2127 23.0182 18.3149 22.9997 17.6666 22.9997Z"
            className="stroke-icon-brand-default"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_2"
            d="M14.002 0.999756V5.8886C14.002 6.21275 14.1307 6.52362 14.3599 6.75283C14.5891 6.98204 14.9 7.11081 15.2242 7.11081H20.113"
            className="stroke-icon-brand-default"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g id="Vector_3">
          <path
            d="M12.1996 15.9315C12.1715 15.8214 12.1147 15.7209 12.0351 15.6405C11.9555 15.5601 11.856 15.5027 11.747 15.4743L9.81449 14.971C9.78152 14.9615 9.7525 14.9415 9.73184 14.9138C9.71118 14.8862 9.7 14.8526 9.7 14.8179C9.7 14.7833 9.71118 14.7496 9.73184 14.722C9.7525 14.6944 9.78152 14.6743 9.81449 14.6649L11.747 14.1612C11.8559 14.1328 11.9554 14.0755 12.035 13.9952C12.1146 13.9148 12.1714 13.8144 12.1996 13.7043L12.6979 11.7523C12.7072 11.7189 12.727 11.6894 12.7544 11.6685C12.7818 11.6475 12.8152 11.6361 12.8496 11.6361C12.884 11.6361 12.9174 11.6475 12.9448 11.6685C12.9722 11.6894 12.992 11.7189 13.0013 11.7523L13.4993 13.7043C13.5274 13.8144 13.5842 13.9149 13.6638 13.9953C13.7434 14.0757 13.8429 14.1331 13.9519 14.1615L15.8844 14.6646C15.9176 14.6738 15.9469 14.6939 15.9678 14.7216C15.9887 14.7493 16 14.7831 16 14.8179C16 14.8528 15.9887 14.8866 15.9678 14.9143C15.9469 14.942 15.9176 14.962 15.8844 14.9713L13.9519 15.4743C13.8429 15.5027 13.7434 15.5601 13.6638 15.6405C13.5842 15.7209 13.5274 15.8214 13.4993 15.9315L13.0009 17.8835C12.9917 17.917 12.9718 17.9464 12.9445 17.9674C12.9171 17.9884 12.8837 17.9998 12.8493 17.9998C12.8149 17.9998 12.7815 17.9884 12.7541 17.9674C12.7267 17.9464 12.7069 17.917 12.6976 17.8835L12.1996 15.9315Z"
            className="fill-icon-brand-default"
          />
          <path
            d="M8.42835 10.4543C8.41228 10.3914 8.37981 10.3339 8.33432 10.288C8.28883 10.242 8.23199 10.2093 8.16969 10.193L7.06542 9.90539C7.04658 9.89999 7.03 9.88853 7.0182 9.87274C7.00639 9.85696 7 9.83772 7 9.81794C7 9.79816 7.00639 9.77891 7.0182 9.76313C7.03 9.74735 7.04658 9.73589 7.06542 9.73049L8.16969 9.44267C8.23196 9.42646 8.2888 9.39369 8.33428 9.34777C8.37977 9.30186 8.41225 9.24448 8.42835 9.18159L8.7131 8.06616C8.71839 8.04706 8.72973 8.03023 8.74538 8.01824C8.76103 8.00625 8.78013 7.99976 8.79977 7.99976C8.81941 7.99976 8.83851 8.00625 8.85416 8.01824C8.86981 8.03023 8.88114 8.04706 8.88644 8.06616L9.17101 9.18159C9.18708 9.24451 9.21955 9.30193 9.26503 9.34788C9.31052 9.39383 9.36737 9.42662 9.42966 9.44286L10.5339 9.7303C10.5529 9.73559 10.5697 9.74703 10.5816 9.76286C10.5935 9.77869 10.6 9.79804 10.6 9.81794C10.6 9.83783 10.5935 9.85718 10.5816 9.87301C10.5697 9.88884 10.5529 9.90028 10.5339 9.90557L9.42966 10.193C9.36737 10.2093 9.31052 10.242 9.26503 10.288C9.21955 10.3339 9.18708 10.3914 9.17101 10.4543L8.88626 11.5697C8.88096 11.5888 8.86963 11.6056 8.85398 11.6176C8.83833 11.6296 8.81923 11.6361 8.79959 11.6361C8.77995 11.6361 8.76085 11.6296 8.7452 11.6176C8.72955 11.6056 8.71821 11.5888 8.71292 11.5697L8.42835 10.4543Z"
            className="fill-icon-brand-default"
          />
          <path
            d="M12.1996 15.9315C12.1715 15.8214 12.1147 15.7209 12.0351 15.6405C11.9555 15.5601 11.856 15.5027 11.747 15.4743L9.81449 14.971C9.78152 14.9615 9.7525 14.9415 9.73184 14.9138C9.71118 14.8862 9.7 14.8526 9.7 14.8179C9.7 14.7833 9.71118 14.7496 9.73184 14.722C9.7525 14.6944 9.78152 14.6743 9.81449 14.6649L11.747 14.1612C11.8559 14.1328 11.9554 14.0755 12.035 13.9952C12.1146 13.9148 12.1714 13.8144 12.1996 13.7043L12.6979 11.7523C12.7072 11.7189 12.727 11.6894 12.7544 11.6685C12.7818 11.6475 12.8152 11.6361 12.8496 11.6361C12.884 11.6361 12.9174 11.6475 12.9448 11.6685C12.9722 11.6894 12.992 11.7189 13.0013 11.7523L13.4993 13.7043C13.5274 13.8144 13.5842 13.9149 13.6638 13.9953C13.7434 14.0757 13.8429 14.1331 13.9519 14.1615L15.8844 14.6646C15.9176 14.6738 15.9469 14.6939 15.9678 14.7216C15.9887 14.7493 16 14.7831 16 14.8179C16 14.8528 15.9887 14.8866 15.9678 14.9143C15.9469 14.942 15.9176 14.962 15.8844 14.9713L13.9519 15.4743C13.8429 15.5027 13.7434 15.5601 13.6638 15.6405C13.5842 15.7209 13.5274 15.8214 13.4993 15.9315L13.0009 17.8835C12.9917 17.917 12.9718 17.9464 12.9445 17.9674C12.9171 17.9884 12.8837 17.9998 12.8493 17.9998C12.8149 17.9998 12.7815 17.9884 12.7541 17.9674C12.7267 17.9464 12.7069 17.917 12.6976 17.8835L12.1996 15.9315Z"
            className="stroke-icon-brand-default"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.42835 10.4543C8.41228 10.3914 8.37981 10.3339 8.33432 10.288C8.28883 10.242 8.23199 10.2093 8.16969 10.193L7.06542 9.90539C7.04658 9.89999 7.03 9.88853 7.0182 9.87274C7.00639 9.85696 7 9.83772 7 9.81794C7 9.79816 7.00639 9.77891 7.0182 9.76313C7.03 9.74735 7.04658 9.73589 7.06542 9.73049L8.16969 9.44267C8.23196 9.42646 8.2888 9.39369 8.33428 9.34777C8.37977 9.30186 8.41225 9.24448 8.42835 9.18159L8.7131 8.06616C8.71839 8.04706 8.72973 8.03023 8.74538 8.01824C8.76103 8.00625 8.78013 7.99976 8.79977 7.99976C8.81941 7.99976 8.83851 8.00625 8.85416 8.01824C8.86981 8.03023 8.88114 8.04706 8.88644 8.06616L9.17101 9.18159C9.18708 9.24451 9.21955 9.30193 9.26503 9.34788C9.31052 9.39383 9.36737 9.42662 9.42966 9.44286L10.5339 9.7303C10.5529 9.73559 10.5697 9.74703 10.5816 9.76286C10.5935 9.77869 10.6 9.79804 10.6 9.81794C10.6 9.83783 10.5935 9.85718 10.5816 9.87301C10.5697 9.88884 10.5529 9.90028 10.5339 9.90557L9.42966 10.193C9.36737 10.2093 9.31052 10.242 9.26503 10.288C9.21955 10.3339 9.18708 10.3914 9.17101 10.4543L8.88626 11.5697C8.88096 11.5888 8.86963 11.6056 8.85398 11.6176C8.83833 11.6296 8.81923 11.6361 8.79959 11.6361C8.77995 11.6361 8.76085 11.6296 8.7452 11.6176C8.72955 11.6056 8.71821 11.5888 8.71292 11.5697L8.42835 10.4543Z"
            className="stroke-icon-brand-default"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default FileIcon
