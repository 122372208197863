import {
  Button,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from '@material-tailwind/react'
import React, { useState } from 'react'

import DocumentDeleteForm from './DocumentDeleteForm'
import { FILE_TYPE_MAP } from '../../helpers/constants'
import ThreeDotsIcon from '../assets/ThreeDotsIcon'
import { IEnterpriseDoc } from '../types/interfaces'

interface DocumentProps {
  doc: IEnterpriseDoc
  handleRowClick: () => void
}

const EnterpriseDocument: React.FC<DocumentProps> = ({
  doc,
  handleRowClick,
}) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isDocumentMenuOpen, setIsDocumentMenuOpen] = useState(false)

  return (
    <tr
      key={doc.id}
      className="hover:bg-gray-50 cursor-pointer h-12"
      onClick={handleRowClick}
    >
      <td className="p-2 text-sm font-normal max-w-[100px] overflow-hidden truncate">
        {doc.filename}
      </td>

      <td className="p-2 text-sm font-normal">
        {FILE_TYPE_MAP[doc.content_type]}
      </td>

      <td className="px-4 py-2 items-center justify-center">
        <Menu
          open={isDocumentMenuOpen}
          handler={setIsDocumentMenuOpen}
          placement="bottom-end"
        >
          <MenuHandler>
            <Button className="bg-transparent p-0 h-6">
              <ThreeDotsIcon variants="secondary" />
            </Button>
          </MenuHandler>
          <MenuList className="p-0 min-w-max text-black text-sm font-normal border-0 z-[9999]">
            <MenuItem onClick={handleRowClick}>View</MenuItem>
            <MenuItem onClick={() => {}}>Download</MenuItem>
            <MenuItem onClick={() => {}}>Move to another folder</MenuItem>
            <MenuItem
              className="text-txt-danger-default hover:text-txt-danger-default focus:text-txt-danger-default"
              onClick={() => {
                setIsDeleteModalOpen(true)
              }}
            >
              Delete
            </MenuItem>
          </MenuList>
        </Menu>
      </td>
      {/* Delete Modal */}
      {isDeleteModalOpen && (
        <DocumentDeleteForm
          doc={doc}
          onClose={() => setIsDeleteModalOpen(false)}
        />
      )}
    </tr>
  )
}

export default EnterpriseDocument
