import Hotjar from '@hotjar/browser'
import { ThemeProvider } from '@material-tailwind/react'
import { Provider } from '@rollbar/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { runInAction, toJS } from 'mobx'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { ErrorBoundary } from 'react-error-boundary'
import TagManager from 'react-gtm-module'
import Rollbar from 'rollbar'

import getManifest from './actions/getManifest'
import { setupPeriodicSessionCookieCheck } from './actions/sessionActions'
import { fetchUserInfo, UserInfo } from './apis/userApi'
import App from './App'
import { connectToUserChannel } from './channels/user_channel'
import AlrWelcomeModal from './components/AlrWelcomeModal'
import ErrorPage from './components/ErrorPage'
import FlashMessages from './components/FlashMessages'
import { initializeLDProvider } from './helpers/featureFlags'
import HubSpotTracking from './helpers/hubSpotTracking'
import { rollbarConfig } from './helpers/rollbarConfig'
import Store, { Credentials } from './helpers/Store'
import StoreContext from './helpers/StoreContext'

const store = new Store()

const queryClient = new QueryClient()

let rollbar: Rollbar | null = null
let error: Error | null = null
let LDProvider: React.ElementType

declare global {
  interface Window {
    __INITIAL_DATA__?: Credentials
    _store?: Store
    toJS?: typeof toJS
  }
}

const getCredentials = () => {
  runInAction(() => {
    if (window.__INITIAL_DATA__) {
      store.credentials = window.__INITIAL_DATA__
    } else {
      throw new Error('Failed to load initial data')
    }
  })
}

const initRollbar = () => {
  rollbar = rollbarConfig(store)

  if (rollbar) {
    rollbar.configure({ payload: { environment: process.env.NODE_ENV } })
  }
}

const devSetup = () => {
  window._store = store
  window.toJS = toJS
}

const prodSetup = (credentials: Credentials, user: UserInfo) => {
  if (
    typeof credentials.hotjarId === 'number' &&
    typeof credentials.hotjarVersion === 'number'
  ) {
    HubSpotTracking(store)

    Hotjar.init(credentials.hotjarId, credentials.hotjarVersion)

    Hotjar.identify(user.id.toString(), {
      email: user.email,
      name: user.full_name,
      enterprise_id: user.enterprise.id,
      enterprise_name: user.enterprise.name,
      country: user.country.common_name,
      created_at: user.created_at,
    })
  }

  if (typeof credentials.googleAnalyticsId === 'string') {
    TagManager.initialize({
      gtmId: credentials.googleAnalyticsId,
    })
  }
}

const initializeApp = async () => {
  try {
    getCredentials()

    initRollbar()

    store.user = await fetchUserInfo(store.credentials.customerAppUrl)

    if (!store.user) {
      window.location.href = store.credentials.customerAppUrl
      return
    }

    if (store.user.onboarding_completed) {
      if (window.location.href.includes('onboarding')) {
        window.location.href = '/chat'
        return
      }
    } else if (
      store.user.current_onboarding_step !==
      window.location.href.split('/').pop()
    ) {
      window.location.href = `/onboarding/${store.user.current_onboarding_step}`
      return
    }

    if (!window.location.href.includes('/onboarding')) {
      if (!store.user.enterprise.is_on_max_chat_app) {
        window.location.href = store.credentials.customerAppUrl
        return
      }

      if (store.user.auth_setup_needed) {
        window.location.href = `${store.credentials.customerAppUrl}/users/auth/edit`
        return
      }
    }

    LDProvider = await initializeLDProvider(store)

    connectToUserChannel(store)

    await getManifest(store)
    process.env.NODE_ENV === 'development'
      ? devSetup()
      : prodSetup(store.credentials, store.user)

    setupPeriodicSessionCookieCheck(store)
  } catch (thrownError: unknown) {
    if (thrownError instanceof Error) {
      error = thrownError
    }
  }

  const root = createRoot(
    document.body.appendChild(document.createElement('div'))
  )

  const FallbackComponent = ({
    error: thrownError,
    resetErrorBoundary,
  }: {
    error: Error
    resetErrorBoundary: () => void
  }) => <ErrorPage error={thrownError} onRetry={resetErrorBoundary} />

  root.render(
    <LDProvider>
      <StoreContext.Provider value={store}>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider>
            {error ? (
              <ErrorPage
                error={error}
                onRetry={() => window.location.reload()}
              />
            ) : (
              <ErrorBoundary FallbackComponent={FallbackComponent}>
                <FlashMessages />
                <AlrWelcomeModal />
                {rollbar ? (
                  <Provider instance={rollbar}>
                    <App />
                  </Provider>
                ) : (
                  <App />
                )}
              </ErrorBoundary>
            )}
          </ThemeProvider>
        </QueryClientProvider>
      </StoreContext.Provider>
    </LDProvider>
  )
}

document.addEventListener('DOMContentLoaded', initializeApp)
