import React from 'react'

const HourGlassIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
  >
    <path
      d="M13.3335 58.6667H50.6668M13.3335 5.33333H50.6668M45.3335 58.6667V47.5413C45.3332 46.127 44.7711 44.7706 43.7708 43.7707L32.0002 32M32.0002 32L20.2295 43.7707C19.2292 44.7706 18.6671 46.127 18.6668 47.5413V58.6667M32.0002 32L20.2295 20.2293C19.2292 19.2294 18.6671 17.873 18.6668 16.4587V5.33333M32.0002 32L43.7708 20.2293C44.7711 19.2294 45.3332 17.873 45.3335 16.4587V5.33333"
      className="stroke-icon-brand-default"
      strokeWidth="4.56"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default HourGlassIcon
