import _ from 'lodash'
import { action, runInAction } from 'mobx'

import { setMatterUploadInProgress, setNewMatter } from './matterActions'
import UploadBatch from './upload_batch/UploadBatch'
import { addUploadBatch } from './upload_batch/uploadBatchActions'
import { createFolders } from '../apis/foldersApi'
import { createMatter } from '../apis/mattersApi'
import { DEFAULT_UPLOAD_PATH } from '../helpers/constants'

/** Action to update file upload progress */
export const updateFileUploadProgress = action(
  (store, blobId, progress, error_msg = null) => {
    const uploadedFile = store.uploadedFiles.find(
      (attachment) => attachment.file.id === blobId
    )

    if (!uploadedFile) {
      return
    }

    uploadedFile.progress = progress
    switch (progress) {
      case 100:
      case 'uploaded':
        uploadedFile.status = 'uploaded'
        break
      case 'validated':
        uploadedFile.status = 'validated'
        break
      case 'error':
        uploadedFile.status = 'error'
        uploadedFile.error_msg = error_msg
        break
      default:
        break
    }
  }
)

/** Group files by their folders */
export const groupFilesByFolder = (files) => {
  const folders = {}

  files.forEach((file) => {
    const filePath = file.relativePath || `${DEFAULT_UPLOAD_PATH}/${file.name}`
    const pathSegments = filePath.split('/')
    const isFile = file.name === pathSegments[pathSegments.length - 1]

    if (!isFile) {
      const folderPath = pathSegments.join('/')
      if (!folders[folderPath]) {
        folders[folderPath] = []
      }
      folders[folderPath].push(file)
    }
  })

  return folders
}

/** Traverse file tree recursively */
export const traverseFileTree = async (entry, basePath = '') => {
  if (entry.isFile) {
    return new Promise((resolve) => {
      entry.file((file) => {
        if (file.name !== '.DS_Store') {
          file.relativePath = basePath
          resolve([file])
        } else {
          resolve([])
        }
      })
    })
  } else if (entry.isDirectory) {
    const dirReader = entry.createReader()
    const entries = []

    const readEntriesRecursively = async () => {
      return new Promise((resolve) => {
        dirReader.readEntries(async (results) => {
          if (results.length === 0) {
            resolve() // Stop recursion
            return
          }

          for (const item of results) {
            const files = await traverseFileTree(
              item,
              `${basePath}/${entry.name}`
            )
            entries.push(...files)
          }

          resolve(await readEntriesRecursively())
        })
      })
    }

    await readEntriesRecursively()

    return entries.length === 0
      ? [{ relativePath: `${basePath}/${entry.name}/`, isEmptyFolder: true }]
      : entries
  }
}

/** Process uploaded folders */
export const processUploadedFolders = async (
  input,
  currentFolderPath,
  allFiles
) => {
  const processingPromises = Array.from(input).map((item) => {
    return new Promise((resolve) => {
      const entry = item.webkitGetAsEntry ? item.webkitGetAsEntry() : null
      if (entry) {
        if (entry.isFile) {
          entry.file((file) => {
            file.relativePath = currentFolderPath
            allFiles.push(file)
            resolve()
          })
        } else if (entry.isDirectory) {
          traverseFileTree(entry, currentFolderPath)
            .then((files) => {
              allFiles.push(...files)
              resolve()
            })
            .catch((error) => {
              console.error('Error traversing directory:', error)
              resolve()
            })
        }
      } else {
        resolve()
      }
    })
  })

  await Promise.all(processingPromises)
}

/** Process manually uploaded files */
export const processUploadedFiles = (input, currentFolderPath, allFiles) => {
  for (const file of input) {
    file.relativePath = currentFolderPath
    allFiles.push(file)
  }
}

/** Handle manual file change (extract files and folders) */
const handleFileChange = (event, currentFolderPath) => {
  const files = Array.from(event.target.files || []) // Array of selected files

  if (!files.length) {
    return []
  } // Return empty array if no files

  const allFiles = []
  const folderSet = new Set()

  // Iterate over files to extract folder paths and files
  files.forEach((file) => {
    // Set relativePath directly on the file object
    file.relativePath = `${currentFolderPath}/${file.webkitRelativePath.substring(0, file.webkitRelativePath.lastIndexOf('/'))}`

    if (file.relativePath) {
      folderSet.add(file.relativePath) // Collect unique folder paths
    }

    // Push the file (with relativePath added directly)
    allFiles.push(file)
  })

  // Convert folder paths into folder objects
  const folders = Array.from(folderSet).map((folderPath) => ({
    isFolder: true,
    relativePath: folderPath,
  }))

  // Return both files and folders in a single array
  return [...allFiles, ...folders]
}

/** Extract files and folders */
export const extractFilesAndFolders = async (input, currentFolderPath) => {
  const allFiles = []

  if (Array.isArray(input)) {
    const hasRelativePaths = input.some((file) => file.webkitRelativePath)

    if (hasRelativePaths) {
      const extracted = handleFileChange(
        { target: { files: input } },
        currentFolderPath
      )
      allFiles.push(...extracted)
    } else {
      processUploadedFiles(input, currentFolderPath, allFiles)
    }
  } else if (input instanceof DataTransferItemList) {
    await processUploadedFolders(input, currentFolderPath, allFiles)
  } else {
    console.error('Unsupported input type:', input)
    return []
  }

  return allFiles
}

/** Process uploads */
export const processUploads = async (
  files,
  store,
  uploadToCurrentFolder = false
) => {
  // ITERATE OVER FILES AND IF RELATIVEPATH IS '' ASSIGN IT TO 'hOME/My Document'
  const filesToUpload = files.filter(
    (file) =>
      file?.relativePath &&
      file.name &&
      !file?.isEmptyFolder &&
      file.name !== '.DS_Store'
  )

  const foldersToUpload = groupFilesByFolder(files)
  const noFoldersToUpload = _.isEmpty(foldersToUpload)

  try {
    if (!uploadToCurrentFolder && !noFoldersToUpload) {
      await createFolders(foldersToUpload, store)
    }
    setMatterUploadInProgress(store, true)

    runInAction(async () => {
      const uploadBatch = new UploadBatch(store, filesToUpload)
      addUploadBatch(store, uploadBatch, store.selectedMatter.id)
      await uploadBatch.upload()
      setMatterUploadInProgress(store, false)
    })
  } catch (error) {
    console.error('Error during uploads:', error)
  }
}

/** Handle file upload */
export const handleFileUpload = async (
  e,
  store,
  navigate,
  isDragAndDrop = false
) => {
  let filesAndFolders = e

  if (isDragAndDrop) {
    e.preventDefault()

    filesAndFolders = e.dataTransfer?.items
  }

  const currentFolderPath =
    store.currentFolder?.full_path || DEFAULT_UPLOAD_PATH

  const files = await extractFilesAndFolders(filesAndFolders, currentFolderPath)

  try {
    if (!store.selectedMatter) {
      const newMatter = await createMatter(store)
      await setNewMatter(store, newMatter)
      navigate(`/chat/${newMatter.id}`)
    }

    await processUploads(files, store)
  } catch (error) {
    console.error('Error handling existing matter upload:', error)
  }
}
