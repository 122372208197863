import { IDocument, IHeaderOverride } from '@cyntler/react-doc-viewer'
import { runInAction } from 'mobx'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { downloadFile } from '../../../apis/filesApi'
import { useStore } from '../../../helpers/useStore'
import CloseIcon from '../../assets/CloseIcon'
import DownloadIcon from '../../assets/DownloadIcon'
import ExternalLinkIcon from '../../assets/ExternalLinkIcon'
import Loading from '../../assets/Loading'
import { ALLOWED_FILE_TYPES } from '../../file_uploads/HandleFilesUpload'
import Tooltip from '../../shared/Tooltip'

const NO_PREVIEW_FILE = [
  'application/msword', // doc
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
  'application/vnd.ms-powerpoint', // ppt
  'application/vnd.openxmlformats-officedocument.presentationml.presentation', // pptx
]

export const LoadingComponent = (
  <div className="w-full h-full flex items-center justify-center">
    <Loading />
  </div>
)

const DocumentViewerHeader: IHeaderOverride = (state) => {
  const navigate = useNavigate()
  const store = useStore()
  if (!state.currentDocument) {
    return null
  }

  const {
    id,
    uri,
    fileType = '',
  } = state.currentDocument as IDocument & { id: string }

  const openInNewTab = () => {
    window.open(uri, '_blank')
  }
  const handleDownload = async () => {
    if (id) {
      await downloadFile(store, id)
    }
  }

  const handleClose = () => {
    runInAction(() => (store.currentDocument = null))
    // TODO: move the document ID into breadcrumb controller
    const pathSegments = window.location.pathname.split('/')
    const newPath = pathSegments
      .slice(0, pathSegments.indexOf('document')) // Exclude everything after "document"
      .join('/')
    navigate(newPath)
  }

  const supportedFileType = ALLOWED_FILE_TYPES.includes(fileType)
  const displayPreviewButton = !NO_PREVIEW_FILE.includes(fileType)

  return (
    <div className="w-full flex items-center justify-end p-4 gap-6">
      {displayPreviewButton && supportedFileType && (
        <button onClick={openInNewTab} className="relative group">
          <Tooltip tooltipContents="Open in a new tab">
            <ExternalLinkIcon className="w-6 h-6 stroke-icon-default-secondary group-hover:stroke-icon-brand-default" />
          </Tooltip>
        </button>
      )}
      {supportedFileType && (
        <button className="relative group" onClick={handleDownload}>
          <Tooltip tooltipContents="Download">
            <DownloadIcon classNames="w-6 h-6 stroke-icon-default-secondary group-hover:stroke-icon-brand-default" />
          </Tooltip>
        </button>
      )}
      <button className="relative group" onClick={handleClose}>
        <Tooltip tooltipContents="Close">
          <CloseIcon className="w-6 h-6 stroke-icon-default-secondary group-hover:stroke-icon-brand-default" />
        </Tooltip>
      </button>
    </div>
  )
}

export default DocumentViewerHeader
