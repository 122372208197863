import { observer } from 'mobx-react-lite'
import React from 'react'

import AsyncChatMessage from './AsyncChatMessage'
import ChatMessageSkeleton from './ChatMessageSkeleton'
import { useStore } from '../../helpers/useStore'

const LoadingMessage = observer(() => {
  const store = useStore()

  if (!store.mostRecentMessage || !store.mostRecentMessage.async_estimate_at) {
    return <ChatMessageSkeleton />
  } else {
    return <AsyncChatMessage chat_thread_message={store.mostRecentMessage} />
  }
})

export default LoadingMessage
