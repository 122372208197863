import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

import { fetchBreadcrumbs } from '../apis/filesApi'
import Store from '../helpers/Store'

export interface Breadcrumb {
  id: string
  title: string
}

export interface BreadcrumbsResponse {
  breadcrumbs: Breadcrumb[]
}

const useBreadcrumbs = (store: Store) => {
  const { id, folderId = '' } = useParams()

  return useQuery<BreadcrumbsResponse>({
    queryKey: ['matterBreadcrumbs', id, folderId],
    queryFn: () => fetchBreadcrumbs(store, id, folderId),
    enabled: Boolean(id),
    staleTime: Infinity,
  })
}

export default useBreadcrumbs
