import React from 'react'
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom'

import ChatApp from './components/ChatApp'
import HomeScreen from './components/HomeScreen'
import ChatLayout from './components/layouts/ChatLayout'
import ChatTemplate from './components/layouts/ChatTemplate'
import LegacyMainChat from './components/LegacyMainChat'
import OnboardingFlow from './components/OnboardingFlow'
import MatterWorkflow from './components/workflow/MatterWorkflow'
import { useFeatureFlags } from './helpers/featureFlags'

function App() {
  const { podCia } = useFeatureFlags()

  return (
    <Router>
      <Routes>
        <Route path="/onboarding/*" element={<OnboardingFlow />} />
        <Route path="/onboarding" element={<OnboardingFlow />} />
        {podCia ? (
          <Route path="/chat" element={<ChatLayout />}>
            <Route index element={<ChatTemplate />} />
            <Route path=":id" element={<ChatTemplate />} />
            <Route path=":id/folder/:folderId" element={<ChatTemplate />} />
            <Route
              path=":id/folder/:folderId/document/:documentId"
              element={<ChatTemplate />}
            />
          </Route>
        ) : (
          <Route path="/chat" element={<ChatApp />}>
            <Route index element={<HomeScreen />} />
            <Route
              path=":id"
              element={
                <>
                  <LegacyMainChat />
                  <MatterWorkflow />
                </>
              }
            />
          </Route>
        )}
        <Route path="*" element={<Navigate to="/chat" />} />
      </Routes>
    </Router>
  )
}

export default App
