import React from 'react'

const LegalResearchIcon: React.FC = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Frame 1597882165">
      <g id="Group 1000003819">
        <g id="Group">
          <path
            id="Vector"
            d="M17.8405 22.0999C17.7764 22.2767 17.6867 22.4347 17.5615 22.56C17.1031 23.0184 16.2052 22.9999 15.5569 22.9999H3.33484C2.68653 22.9999 1.78873 23.0184 1.33031 22.56C0.871891 22.1016 0.890415 21.2038 0.890415 20.5555V7.11116L7.00147 1.0001H15.5569C16.2052 1.0001 17.103 0.98158 17.5615 1.44C18.0199 1.89842 18.0014 2.79622 18.0014 3.44452C18.0014 5.07915 18.0014 7.82133 18.0014 9"
            stroke="#5133EA"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_2"
            d="M7.00146 1V5.88884C7.00146 6.21299 6.8727 6.52387 6.64349 6.75308C6.41428 6.98228 6.1034 7.11105 5.77925 7.11105H0.890412"
            stroke="#5133EA"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <path
          id="Vector_3"
          d="M11.6802 16.5591L14.6702 19.5491M19.9302 14.2991L16.9402 11.3091M16.2302 17.3791L21.2202 22.5791C21.7002 23.0591 22.4802 23.0591 22.9602 22.5791C23.4402 22.0991 23.4402 21.3191 22.9602 20.8391L17.7602 15.8491L16.2402 17.3691L16.2302 17.3791ZM17.2502 11.6091L11.9902 16.8691L14.3702 19.2491L19.6302 13.9891L17.2502 11.6091Z"
          stroke="#5133EA"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
  </svg>
)

export default LegalResearchIcon
