import axios, { AxiosError } from 'axios'

import { extendSessionTimeout } from '../actions/sessionActions'
import { IEnterpriseDoc } from '../components/types/interfaces'
import { rollbarConfig } from '../helpers/rollbarConfig'
import Store from '../helpers/Store'
import { BreadcrumbsResponse } from '../hooks/useBreadcrumbs'
import { Folder } from '../hooks/useFilesAndFolders'

interface presignedUrlResponse {
  direct_upload_url: string
  filename: string
  key: string
  file: {
    id: string
    byte_size: number
    content_type: string
    file_name: string
    url: string
  }
  content_type: string
  id: string
}

interface presignedUrlArgs {
  filename: string
  byte_size: number
  relative_path: string
  checksum: string
  content_type: string
  store: Store
  matter_id: string
}

function getCsrfToken(): string {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const csrfElement = document!.querySelector(
    'meta[name="csrf-token"]'
  ) as Element
  return csrfElement.getAttribute('content') as string
}

async function getPresignedUrlAndMetadata({
  filename,
  byte_size,
  relative_path,
  checksum,
  content_type,
  store,
  matter_id,
}: presignedUrlArgs): Promise<presignedUrlResponse> {
  extendSessionTimeout(store)

  try {
    const headers = {
      'Content-Type': 'application/json',
      'X-CSRF-Token': getCsrfToken(),
    }

    const response = await axios.post(
      '/api/client/v1/file_uploads',
      {
        blob: {
          filename,
          byte_size,
          relative_path,
          checksum,
          content_type,
        },
        matter_id: matter_id,
      },
      {
        headers: headers,
      }
    )

    return response.data // Assuming your Rails backend returns { direct_upload: { ... }, blob: { ... } }
  } catch (error) {
    rollbarConfig(store)?.error(error as Error)
    throw error
  }
}

const fetchFiles = async (store: Store) => {
  if (!store.selectedMatter) {
    return []
  }

  extendSessionTimeout(store)

  try {
    const thread_id = store.selectedMatter.thread_id
    const response = await axios.get(
      `/api/client/v1/documents?thread_id=${thread_id}`
    )

    if (response.status === 200) {
      return response.data
    } else {
      return []
    }
  } catch (error) {
    rollbarConfig(store)?.error(error as Error)
    throw error
  }
}

const fetchFilesAndFolders = async (
  store: Store,
  matterId: string | undefined,
  folderId: string | undefined
): Promise<Folder> => {
  extendSessionTimeout(store)

  if (!matterId) {
    return {
      documents: [],
      folder_type: '',
      full_path: '',
      id: '',
      matter_id: '',
      meta: {
        total_count: 0,
        per_page: 0,
        current_page: 0,
        total_pages: 0,
      },
      parent_id: '',
      sub_folders: [],
      title: '',
    }
  }

  const sortParams = store.documentSortParams
  const params = new URLSearchParams()

  sortParams.forEach((value, key) => {
    params.append(key, value)
  })

  params.set('folder_id', folderId || '')
  params.set('page', store.documentPagination.page.toString())
  params.set('per_page', store.documentPagination.perPage.toString())

  try {
    const response = await axios.get(
      `/api/client/v1/folders?matter_id=${matterId}`,
      {
        params,
      }
    )

    if (response.status === 200) {
      return response.data as Folder
    } else {
      throw new Error('Failed to fetch data')
    }
  } catch (error) {
    rollbarConfig(store)?.error(error as Error)
    throw error
  }
}

const fetchBreadcrumbs = async (
  store: Store,
  matterId: string | undefined,
  folderId: string
): Promise<BreadcrumbsResponse> => {
  extendSessionTimeout(store)

  if (!matterId) {
    return {
      breadcrumbs: [],
    }
  }

  try {
    const response = await axios.get(`/api/client/v1/file_breadcrumbs`, {
      params: {
        matter_id: matterId,
        folder_id: folderId,
      },
    })

    if (response.status === 200) {
      return response.data as BreadcrumbsResponse
    } else {
      throw new Error('Failed to fetch data')
    }
  } catch (error) {
    rollbarConfig(store)?.error(error as Error)
    throw error
  }
}

const bytesToMB = (bytes: number) => {
  const MB = 1024 * 1024
  return bytes / MB
}

const validateFile = async (
  store: Store,
  e_attachment_id: string,
  blob_id: string,
  file_size_bytes: number
) => {
  extendSessionTimeout(store)

  if (bytesToMB(file_size_bytes) > 512) {
    return { error_msg: 'File size exceeds 512MB' }
  }

  const url = `/api/client/v1/file_uploads/validate_file?e_attachment_id=${e_attachment_id}&blob_id=${blob_id}`

  try {
    const { data, status } = await axios.get(url)

    if (status === 200) {
      return data
    }

    return { error_msg: data?.error_msg || 'Upload failed' }
  } catch (error) {
    const errorData = (error as AxiosError).response?.data as {
      error_msg: string
    }
    const errorMsg = errorData.error_msg || 'Upload failed'

    rollbarConfig(store)?.error(error as AxiosError)

    return { error_msg: errorMsg }
  }
}

const fetchFile = async (store: Store, docId: string) => {
  if (!docId) {
    return
  }
  extendSessionTimeout(store)
  try {
    const response = await axios.get(
      `/api/client/v1/enterprise_attachments/${docId}`
    )
    if (response.status === 200) {
      return response.data
    }
    return { error_msg: response.data?.error_msg || 'get file data failed' }
  } catch (error) {
    rollbarConfig(store)?.error(error as AxiosError)
    throw error
  }
}
const previewFile = async (store: Store, docId: string) => {
  if (!docId) {
    return
  }
  extendSessionTimeout(store)
  try {
    const response = await axios.get(
      `/api/client/v1/enterprise_attachments/${docId}/preview`,
      {
        headers: {
          Accept: '*/*',
        },
        responseType: 'json',
      }
    )
    if (response.status === 200) {
      return response.data
    }
    return { error_msg: response.data?.error_msg || 'get file failed' }
  } catch (error) {
    rollbarConfig(store)?.error(error as AxiosError)
    throw error
  }
}
const downloadFile = async (store: Store, docId: string) => {
  if (!store.selectedMatter || !docId) {
    return
  }

  extendSessionTimeout(store)
  try {
    const thread_id = store.selectedMatter.thread_id
    const response = await axios.get(
      `/api/client/v1/enterprise_attachments/${docId}/download`,
      {
        params: {
          thread_id: thread_id,
          matter_id: store.selectedMatter.id,
        },
        headers: {
          Accept: 'application/json',
        },
        responseType: 'json',
      }
    )
    if (response.status === 200) {
      // Api call to download relies on the disposition attachment header, returns a
      // downloadable S3 Url. Adding it to window.location.href will download the file
      window.location.href = response.data
    }
  } catch (error) {
    rollbarConfig(store)?.error(error as AxiosError)
    throw error
  }
}

const downloadFileLegacy = async (store: Store, doc: IEnterpriseDoc) => {
  if (!store.selectedMatter) {
    return
  }

  extendSessionTimeout(store)

  try {
    const thread_id = store.selectedMatter.thread_id

    const response = await axios.get(
      `/api/client/v1/enterprise_attachments/${doc.documentable.id}/download`,
      {
        params: {
          thread_id: thread_id,
          matter_id: store.selectedMatter.id,
        },
        headers: {
          Accept: 'application/json',
        },
        responseType: 'json',
      }
    )

    if (response.status === 200) {
      // Api call to download relies on the disposition attachment header, returns a
      // downloadable S3 Url. Adding it to window.location.href will download the file
      window.location.href = response.data
    }
  } catch (error) {
    rollbarConfig(store)?.error(error as Error)
    throw error
  }
}

const deleteFile = async (store: Store, doc: IEnterpriseDoc) => {
  if (!store.selectedMatter) {
    return
  }

  extendSessionTimeout(store)

  try {
    const thread_id = store.selectedMatter.thread_id
    const headers = {
      'X-CSRF-Token': getCsrfToken(),
    }

    await axios.delete(
      `/api/client/v1/enterprise_attachments/${doc.documentable.id}/delete_file`,
      {
        params: {
          thread_id: thread_id,
          matter_id: store.selectedMatter.id,
        },
        headers: headers,
      }
    )
  } catch (error) {
    rollbarConfig(store)?.error(error as Error)
    throw error
  }
}

export const deleteAllFiles = async (store: Store) => {
  extendSessionTimeout(store)

  try {
    const headers = {
      'X-CSRF-Token': getCsrfToken(),
    }

    return await axios.delete(
      `/api/client/v1/enterprise_attachments/delete_all_files`,
      {
        headers: headers,
      }
    )
  } catch (error) {
    rollbarConfig(store)?.error(error as Error)
    throw error
  }
}

export const hasEnterpriseAttachments = async (store: Store) => {
  extendSessionTimeout(store)

  try {
    const headers = {
      'X-CSRF-Token': getCsrfToken(),
    }

    const response = await axios.get(
      `/api/client/v1/documents/has_enterprise_attachments`,
      {
        headers: headers,
      }
    )

    return response.data.hasAttachments
  } catch (error) {
    rollbarConfig(store)?.error(error as Error)
    throw error
  }
}

const completeUpload = async (
  store: Store,
  enterpriseAttachmentId: string,
  matterId: string
) => {
  extendSessionTimeout(store)

  const payload = { matter_id: matterId }

  try {
    return await axios.patch(
      `/api/client/v1/enterprise_attachments/${enterpriseAttachmentId}/complete_upload`,
      payload,
      {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': getCsrfToken(),
        },
      }
    )
  } catch (error) {
    rollbarConfig(store)?.error(error as Error)
    throw error
  }
}

export {
  getPresignedUrlAndMetadata,
  fetchFiles,
  fetchFile,
  deleteFile,
  previewFile,
  downloadFile,
  downloadFileLegacy,
  validateFile,
  fetchFilesAndFolders,
  completeUpload,
  fetchBreadcrumbs,
}
