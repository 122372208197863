import React from 'react'

const CloseIcon = ({
  className = 'stroke-icon-default-secondary',
}: {
  className?: string
}) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g>
      <path
        id="Vector"
        d="M14 2L2 14M2 2L14 14"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={className}
      />
    </g>
  </svg>
)

export default CloseIcon
