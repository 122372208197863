const FilesAndFoldersSkeleton = () => {
  return (
    <div className="p-4 h-full w-full animate-pulse">
      <div className="pb-4 justify-items-end">
        <div>
          <div className="h-9 w-32 rounded-md bg-gray-300" />
        </div>
      </div>

      <div className="w-full">
        <table className="w-full table-auto border-collapse divide-y divide-gray-200">
          <thead>
            <tr>
              <th className="px-4 py-2 text-left text-sm font-normal text-gray-700">
                <div className="h-5 w-24 rounded-md bg-gray-300" />
              </th>
              <th className="px-4 py-2 text-left text-sm font-normal text-gray-700">
                <div className="h-5 w-24 rounded-md bg-gray-300" />
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {Array.from({ length: 15 }).map((_, index) => (
              <tr key={index} className="h-12">
                <td className="px-4 py-2">
                  <div className="h-5 w-32 rounded-md bg-gray-300" />
                </td>
                <td className="px-4 py-2">
                  <div className="h-5 w-20 rounded-md bg-gray-300" />
                </td>
                <td className="px-4 py-2 w-12">
                  <div className="h-5 w-8 rounded-md bg-gray-300" />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default FilesAndFoldersSkeleton
