import axios from 'axios'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import FormStep from './FormStep'
import { submitOnboardingForm } from '../../apis/onboardingApi'
import { FormControlledSelect } from '../shared/FormControlledSelect'
import { FormInput } from '../shared/FormInput'

const isValidUrl = (url: string) => {
  const urlPattern = /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)(\/[\w-]*)*\/?$/
  return urlPattern.test(url)
}

enum LawFirmType {
  PrivatePractice = 'Private practice',
  Corporation = 'Corporation',
  Government = 'Government',
  NonProfit = 'Non-profit',
}

interface FormData {
  firm_name: string
  firm_website: string
  law_firm_type: string
  firm_size: string
}

const AboutLawFirmStep: React.FC = () => {
  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors, isValid },
  } = useForm<FormData>({
    defaultValues: {
      firm_name: '',
      firm_website: '',
      law_firm_type: '',
      firm_size: '',
    },
    mode: 'onChange',
  })

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/api/client/v1/onboardings/firm_data')
        reset(response.data)
      } catch (error) {
        console.error('Error fetching firm data:', error)
      }
    }

    fetchData()
  }, [reset])

  const onSubmit = async (data: FormData) => {
    await submitOnboardingForm(
      '/api/client/v1/onboardings/complete_firm_info',
      data
    )
  }

  const lawFirmType = watch('law_firm_type')
  const getFirmSizeLabel = () => {
    if (
      [
        LawFirmType.Corporation,
        LawFirmType.Government,
        LawFirmType.NonProfit,
      ].includes(lawFirmType as LawFirmType)
    ) {
      return 'What is the size of your legal department?*'
    }
    return 'What is the size of your firm?*'
  }

  const handleNotPartOfFirm = async () => {
    await submitOnboardingForm(
      '/api/client/v1/onboardings/not_part_of_firm',
      {}
    )
  }

  return (
    <FormStep step={1} totalSteps={3} title="Tell us about your law firm">
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6 mt-8">
        <FormInput
          label="Firm name*"
          placeholder="Firm name"
          error={errors.firm_name?.message}
          {...register('firm_name', {
            required: 'Firm name is required.',
            minLength: { value: 2, message: 'Firm name is required.' },
            pattern: {
              value: /^[a-zA-Z0-9\s.]+$/,
              message:
                'Firm name can only contain letters, numbers, and spaces.',
            },
          })}
        />

        <FormInput
          label="Firm website*"
          placeholder="Firm website"
          error={errors.firm_website?.message}
          {...register('firm_website', {
            required: 'Please enter a valid URL for your website.',
            minLength: {
              value: 4,
              message: 'Please enter a valid URL for your website.',
            },
            validate: (value) =>
              isValidUrl(value) || 'Please enter a valid URL for your website.',
          })}
        />

        <FormControlledSelect
          control={control}
          name="law_firm_type"
          label="What type of law firm do you work for?*"
          error={errors.law_firm_type?.message}
          rules={{ required: 'Please select a type of law firm.' }}
          options={{
            'Private practice': LawFirmType.PrivatePractice,
            Corporation: LawFirmType.Corporation,
            Government: LawFirmType.Government,
            'Non-profit': LawFirmType.NonProfit,
          }}
        />

        <FormControlledSelect
          control={control}
          name="firm_size"
          label={getFirmSizeLabel()}
          error={errors.firm_size?.message}
          rules={{ required: 'Please select the size of your firm.' }}
          options={{
            '1-2': '1-2',
            '3-10': '3-10',
            '11-50': '11-50',
            '51-100': '51-100',
            '100+': '100+',
          }}
        />

        <div>
          <button
            type="submit"
            disabled={!isValid}
            className="h-11 mt-8 text-center disabled:bg-bgCol-disabled-default disabled:text-txt-disabled-default disabled:cursor-not-allowed w-full flex items-center justify-center py-2 px-4 text-white rounded-md text-sm font-medium bg-bgCol-brand-default focus:ring-2 focus:ring-offset-2"
          >
            Continue
          </button>
        </div>
      </form>

      <div className="mt-16 text-center">
        <a
          href="#"
          className="text-txt-brand-default text-sm font-medium"
          onClick={handleNotPartOfFirm}
        >
          I am not part of a law firm
        </a>
      </div>
    </FormStep>
  )
}

export default AboutLawFirmStep
