import { Select, Option, IconButton } from '@material-tailwind/react'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import React from 'react'

import {
  getDocumentPagination,
  setDocumentPage,
  setDocumentPerPage,
} from '../../actions/documentPagination'
import Store from '../../helpers/Store'
import { useStore } from '../../helpers/useStore'
import PaginationLeftIcon from '../assets/PaginationLeftIcon'
import PaginationRightIcon from '../assets/PaginationRightIcon'

const PaginationComponent = observer(() => {
  const store = useStore()

  if (_.isEmpty(store.folders)) {
    return null
  }

  if (
    store.documentPagination.totalCount < 25 ||
    store.selectedMatter === null
  ) {
    return null
  }

  return (
    <div
      className="flex flex-row justify-between p-4"
      style={{
        background:
          'linear-gradient(180deg, rgba(255, 255, 255, 0.50) -791.46%, #FAF9FF 100%)',
      }}
    >
      <CountComponent store={store} />
      <PageComponent store={store} />
    </div>
  )
})

interface CountComponentProps {
  store: Store
}

const CountComponent = observer(({ store }: CountComponentProps) => {
  const { perPage, totalCount } = getDocumentPagination(store)

  return (
    <div className="flex flex-row items-center gap-2">
      <span className="text-md">Show</span>
      <Select
        variant="outlined"
        size="md"
        value={perPage.toString()}
        onChange={(val) => setDocumentPerPage(store, parseInt(val || '25'))}
        containerProps={{
          className: '!min-w-[4.5rem]',
        }}
        labelProps={{
          className: 'before:mr-0 after:ml-0',
        }}
      >
        <Option value="25">25</Option>
        <Option value="50">50</Option>
        <Option value="100">100</Option>
        <Option value="250">250</Option>
      </Select>
      <span className="text-md"> of </span>
      <span className="text-md"> {totalCount}</span>
    </div>
  )
})

interface PageComponentProps {
  store: Store
}

const PageComponent = observer(({ store }: PageComponentProps) => {
  const { page, totalPages } = getDocumentPagination(store)

  return (
    <div className="flex items-center gap-2">
      <IconButton
        size="sm"
        variant="text"
        disabled={page === 1}
        onClick={() => setDocumentPage(store, page - 1)}
      >
        <PaginationLeftIcon />
      </IconButton>

      <span className="text-sm">
        Page {page} of {totalPages}
      </span>

      <IconButton
        size="sm"
        variant="text"
        disabled={page === totalPages}
        onClick={() => setDocumentPage(store, page + 1)}
      >
        <PaginationRightIcon />
      </IconButton>
    </div>
  )
})

export default PaginationComponent
