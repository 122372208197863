import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

import { getMatterUploadInProgress } from '../actions/matterActions'
import { fetchFilesAndFolders } from '../apis/filesApi'
import Store from '../helpers/Store'

interface FileMetadata {
  user_id: number
  analyzed: boolean
}

interface File {
  id: string
  key: string
  filename: string
  content_type: string
  metadata: FileMetadata
  service_name: string
  byte_size: number
  checksum: string
  created_at: string
  attachable_sgid: string
}

interface Document {
  id: string
  content_type: string
  created_at: string
  updated_at: string
  filename: string
  file: File
  key: string
  generated_name?: string | null
  chat_thread_message_id?: string | null
  folder_id: string
}

interface SubFolder {
  documents: Document[]
  sub_folders: SubFolder[]
  id: string
  title: string
  parent_id: string
  matter_id: string
  full_path: string
  folder_type: string
}

interface Meta {
  total_count: number
  per_page: number
  current_page: number
  total_pages: number
}

export interface Folder {
  id: string
  title: string
  parent_id: string
  matter_id: string
  full_path: string
  folder_type: string
  documents: Document[]
  sub_folders: SubFolder[]
  meta: Meta
}

const useFilesAndFolders = (store: Store): UseQueryResult<Folder> => {
  const { id, folderId } = useParams()

  const sortParamsString = JSON.stringify([
    store.documentSortParams.get('sortBy'),
    store.documentSortParams.get('sortOrder'),
  ])

  const paginationParamsString = JSON.stringify([
    store.documentPagination.page,
    store.documentPagination.perPage,
  ])

  const getRefetchInterval = () => {
    if (getMatterUploadInProgress(store)) {
      return 1000
    } else {
      return false
    }
  }

  return useQuery<Folder>({
    queryKey: [
      'filesAndFolders',
      id,
      folderId,
      sortParamsString,
      paginationParamsString,
    ],
    queryFn: () => fetchFilesAndFolders(store, id, folderId),
    enabled: Boolean(id),
    staleTime: Infinity,
    refetchInterval: getRefetchInterval(),
  })
}

export default useFilesAndFolders
