import { makeAutoObservable, action } from 'mobx'

import UploadRequest, { MaxchatFile } from './UploadRequest'
import Store from '../../helpers/Store'

const MAX_CONCURRENT_UPLOADS = 5

class UploadBatch {
  batchStatus: 'initialized' | 'uploading' | 'completed' = 'initialized'
  files: MaxchatFile[]
  filesToProcess: MaxchatFile[]
  uploadRequests: UploadRequest[] = []

  batchPromise: Promise<null>
  resolve!: (a: null) => void
  store: Store
  matterId: string

  progressBarShown: boolean = false
  progressBarRemoved: boolean = false

  constructor(_store: Store, _files: MaxchatFile[]) {
    // TODO: this file validation should probably happen before we kick off the upload? Requires additional handling in any case.
    this.files = _files.filter((file) => file.name && file.relativePath)
    this.filesToProcess = [...this.files]
    this.batchPromise = new Promise((resolve) => {
      this.resolve = resolve
    })
    this.store = _store

    if (!_store.selectedMatter) {
      throw new Error('No selected matter, unable to upload')
    }
    this.matterId = _store.selectedMatter.id

    makeAutoObservable(this)
  }

  async upload() {
    this.batchStatus = 'uploading'

    while (
      this.filesToProcess.length > 0 &&
      this.uploadingDocuments().length < MAX_CONCURRENT_UPLOADS
    ) {
      const file = this.filesToProcess.shift() as MaxchatFile
      this.startRequest(file)
    }

    return this.batchPromise
  }

  startRequest(file: MaxchatFile) {
    const uploadRequest = new UploadRequest(file, this)
    this.uploadRequests.push(uploadRequest)
    uploadRequest.upload()
  }

  // Notifiers for UploadRequests to call

  uploadRequestUploaded() {
    if (this.filesToProcess.length > 0) {
      const file = this.filesToProcess.shift() as MaxchatFile
      this.startRequest(file)
    }
  }

  uploadRequestProcessed() {
    if (this.files.length === this.finishedDocumentsCount()) {
      this.batchStatus = 'completed'
      setTimeout(
        action(() => {
          // First, we wait 5s and then hide the progress bar with an animation
          this.progressBarShown = false
          setTimeout(
            action(() => {
              // Then, after the bar's opacity has been animated to zero, we stop rendering it
              this.progressBarRemoved = true
            }),
            500
          )
        }),
        5000
      )
      this.resolve(null)
    }
  }

  // Accessors

  // TODO: might need to memoize these, for the 10k document case

  uploadingDocuments() {
    return this.uploadRequests.filter(
      (uploadRequest) => uploadRequest.requestStatus === 'uploading'
    )
  }

  processingDocuments() {
    return this.uploadRequests.filter(
      (uploadRequest) => uploadRequest.requestStatus === 'processing'
    )
  }

  completedDocuments() {
    return this.uploadRequests.filter(
      (uploadRequest) => uploadRequest.requestStatus === 'completed'
    )
  }

  failedDocuments() {
    return this.uploadRequests.filter(
      (uploadRequest) => uploadRequest.requestStatus === 'failed'
    )
  }

  finishedDocumentsCount() {
    return this.completedDocuments().length + this.failedDocuments().length
  }
}

export default UploadBatch
