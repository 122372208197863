import React, { useEffect, useState } from 'react'

import { updateMatter } from '../../apis/mattersApi'
import { Matter } from '../../helpers/Store'
import { useStore } from '../../helpers/useStore'
import ChevronDownUnfilledIcon from '../assets/ChevronDownUnfilledIcon'
import Tooltip from '../shared/Tooltip'

export interface ChatHeaderProps {
  matter: Matter
  handleSettingPopup: (value: boolean) => void
  titleStyle: any
}

const ChatHeader = ({
  matter,
  handleSettingPopup,
  titleStyle,
}: ChatHeaderProps) => {
  const store = useStore()

  const [isEditing, setIsEditing] = useState(false)
  const [matterName, setMatterName] = useState(matter.title || '')

  useEffect(() => {
    setMatterName(matter.title || '')
  }, [matter.title])

  const handleTitleClick = () => {
    setIsEditing(true)
  }

  const saveMatter = async () => {
    if (matter.id) {
      const updatedMatter = { ...matter, assigned_name: matterName }
      await updateMatter(store, updatedMatter)
    }
  }

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMatterName(e.target.value)
  }

  const handleBlur = () => {
    setIsEditing(false)
    saveMatter()
  }

  if (!matter) {
    return null
  }

  return (
    <div className="flex flex-col">
      <div className="flex items-center space-x-1">
        {isEditing ? (
          <div className="text-txt-brand-default">
            <input
              type="text"
              value={matterName}
              onChange={handleTitleChange}
              onBlur={handleBlur}
              autoFocus
              className="text-lg text-txt-brand-default font-semibold focus:outline-none focus:border-none bg-bgCol-brand-secondary rounded-lg px-1"
              style={{
                width: `${matterName.length <= 1 ? '25px' : `${matterName.length}ch`}`,
                ...titleStyle,
              }}
            />
          </div>
        ) : (
          <div className="hover:bg-bgCol-brand-secondaryHover rounded-lg transition-colors duration-200">
            <p
              className="text-txt-default-default overflow-hidden truncate"
              onClick={handleTitleClick}
              style={{ ...titleStyle }}
            >
              {matterName}
            </p>
          </div>
        )}
        <Tooltip tooltipContents="View matter details">
          <button className="ml-0.5" onClick={() => handleSettingPopup(true)}>
            <ChevronDownUnfilledIcon />
          </button>
        </Tooltip>
      </div>
      <div>
        <button
          onClick={() => handleSettingPopup(true)}
          className="text-xs text-txt-neutral-secondary"
        >
          {matter.display_formatted_jurisdiction}
        </button>
      </div>
    </div>
  )
}

export default ChatHeader
