import { action } from 'mobx'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'

import UploadBatch from '../../actions/upload_batch/UploadBatch'

interface UploadProgressBarProps {
  uploadBatch: UploadBatch
}

function getBorderColor(uploadBatch: UploadBatch) {
  if (uploadBatch.failedDocuments().length === 0) {
    return 'border-brd-brand-secondary'
  } else {
    return 'border-brd-warning-secondary'
  }
}

function getBackgroundStyle(uploadBatch: UploadBatch) {
  const bgColor =
    uploadBatch.failedDocuments().length === 0
      ? '#EEEBFD' // bg-bgCol-brand-tertiary
      : '#FFF7EB' // bg-bgCol-warning-tertiary

  const progression =
    (uploadBatch.finishedDocumentsCount() / uploadBatch.files.length) * 100

  return {
    background: `linear-gradient(0.25turn, ${bgColor} 0 ${progression}%, white ${progression}% 100%)`,
  }
}

const UploadProgressBar = observer(
  ({ uploadBatch }: UploadProgressBarProps) => {
    useEffect(() => {
      setTimeout(
        action(() => {
          uploadBatch.progressBarShown = true
        }),
        0
      )
    }, [uploadBatch])

    if (uploadBatch.progressBarRemoved === true) {
      return null
    }

    return (
      <div
        className={`flex justify-center items-center my-2 mx-4 p-2 border rounded ${getBorderColor(uploadBatch)} transition-opacity ${uploadBatch.progressBarShown ? 'opacity-100' : 'opacity-0'}`}
        style={getBackgroundStyle(uploadBatch)}
      >
        <span className="text-center">{`Uploading ${uploadBatch.finishedDocumentsCount()}/${uploadBatch.files.length} documents`}</span>
      </div>
    )
  }
)

export default UploadProgressBar
