import React from 'react'

const InfoIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20.15"
    height="20.15"
    viewBox="0 0 20.15 20.15"
    fill="none"
  >
    <path
      d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z"
      className="stroke-icon-brand-default"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 13.5999V9.9999M10 6.3999H10.009"
      className="stroke-icon-brand-default"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default InfoIcon
