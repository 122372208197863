import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import FormStep from './FormStep'
import { fetchStates } from '../../apis/fetchStatesApi'
import { submitOnboardingForm } from '../../apis/onboardingApi'
import { useStore } from '../../helpers/useStore'
import InfoIconSmall from '../assets/InfoIconSmall'
import { FormControlledSelect } from '../shared/FormControlledSelect'
import { FormInput } from '../shared/FormInput'
import { FormRadioGroup } from '../shared/FormRadioGroup'
import Tooltip from '../shared/Tooltip'

interface FormData {
  first_name: string
  last_name: string
  country: string
  country_state: string
  jurisdiction?: string
  role_in_firm: string
  ai_tools_before: string
}

const AboutUserStep: React.FC = () => {
  const store = useStore()
  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors, isValid },
  } = useForm<FormData>({
    defaultValues: {
      first_name: '',
      last_name: '',
      country: '',
      country_state: '',
      jurisdiction: '',
      role_in_firm: '',
      ai_tools_before: '',
    },
    mode: 'onChange',
  })

  const country = watch('country')
  const [states, setStates] = useState<Record<string, string>>({})
  const [roles, setRoles] = useState<Record<string, string[]>>({})
  const [roleOptions, setRoleOptions] = useState<Record<string, string>>({})

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/api/client/v1/onboardings/user_info')
        reset(response.data.user)
        setRoles(response.data.role_options)
      } catch (error) {
        if (axios.isAxiosError(error) && error.response?.status === 500) {
          window.location.href = '/'
        }
        console.error('Error fetching user data:', error)
      }
    }
    fetchData()
  }, [reset])

  useEffect(() => {
    const loadStates = async () => {
      try {
        const data = await fetchStates(store, country, false)
        const transformedData = data.reduce(
          (acc: Record<string, string>, state: { name: string }) => {
            acc[state.name] = state.name
            return acc
          },
          {}
        )
        setStates(transformedData)
      } catch (error) {
        console.error('Error fetching states:', error)
      }
    }

    loadStates()
    const newRoles = country ? roles[country] : []
    setRoleOptions(
      newRoles.reduce((acc: Record<string, string>, role: string) => {
        acc[role] = role
        return acc
      }, {})
    )
    reset((formValues) => ({
      ...formValues,
      country_state: '',
    }))
  }, [country, store, roles, reset])

  const onSubmit = async (data: FormData) => {
    await submitOnboardingForm(
      '/api/client/v1/onboardings/complete_user_info',
      data
    )
  }

  const owner = store.user?.role === 'owner'
  const currentStep = owner ? 2 : 1
  const totalSteps = owner ? 3 : 2

  return (
    <FormStep
      step={currentStep}
      totalSteps={totalSteps}
      title="Tell us about you"
    >
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6 mt-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <FormInput
            label="First name*"
            placeholder="First name"
            error={errors.first_name?.message}
            {...register('first_name', {
              required: 'First name is required.',
              validate: (value) =>
                value.trim() !== '' || 'First name cannot be empty.',
            })}
          />

          <FormInput
            label="Last name*"
            placeholder="Last name"
            error={errors.last_name?.message}
            {...register('last_name', {
              required: 'Last name is required',
              validate: (value) =>
                value.trim() !== '' || 'Last name cannot be empty.',
            })}
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <FormControlledSelect
            control={control}
            name="country"
            label="Country*"
            error={errors.country?.message}
            rules={{ required: 'Country is required.' }}
            options={{
              US: 'United States',
              CA: 'Canada',
            }}
          />

          <FormControlledSelect
            control={control}
            name="country_state"
            label={country === 'CA' ? 'Province*' : 'State*'}
            error={errors.country_state?.message}
            rules={{ required: 'State/Province is required.' }}
            options={states}
            disabled={!country}
          />
        </div>

        {country === 'US' && (
          <div>
            <label className="text-sm font-medium mb-2 flex items-center">
              What is your primary jurisdiction?*
              <Tooltip
                position="above"
                additionalClasses="w-[20rem]"
                tooltipContents="We use this information to set your default jurisdiction to ensure that we provide relevant caselaw and legal information."
              >
                <div className="h-[14px] w-[14px] flex items-center ml-1">
                  <InfoIconSmall strokeColor="#1B212D" />
                </div>
              </Tooltip>
            </label>
            <FormControlledSelect
              control={control}
              name="jurisdiction"
              label=""
              error={errors.jurisdiction?.message}
              rules={{ required: 'Jurisdiction is required.' }}
              options={{
                state: 'State courts',
                federal: 'Federal courts',
              }}
            />
          </div>
        )}

        <FormControlledSelect
          control={control}
          name="role_in_firm"
          label="How would you describe your role?*"
          error={errors.role_in_firm?.message}
          rules={{ required: 'Role is required' }}
          options={roleOptions}
          disabled={!country}
        />

        <FormRadioGroup
          label="Have you used any AI tools before?*"
          options={['No, never', 'Yes, a couple of times', 'Yes, quite often']}
          error={errors.ai_tools_before?.message}
          register={register('ai_tools_before', {
            required: 'Please select an option',
          })}
        />

        <div>
          <button
            type="submit"
            disabled={!isValid}
            className="h-11 mt-8 text-center disabled:bg-bgCol-disabled-default disabled:text-txt-disabled-default disabled:cursor-not-allowed w-full flex items-center justify-center py-2 px-4 text-white rounded-md text-sm font-medium bg-bgCol-brand-default focus:ring-2 focus:ring-offset-2"
          >
            Continue
          </button>
        </div>
      </form>
    </FormStep>
  )
}

export default AboutUserStep
