import React from 'react'
import Select, { StylesConfig, GroupBase } from 'react-select'

interface Option {
  value: string
  label: string
}

interface ReactSelectMultiProps {
  value: Option[]
  onChange: (newValue: Option[]) => void
  options: Option[]
  error?: string
  placeholder?: string
  closeMenuOnSelect?: boolean
}

// You can customize these styles further or rely on classNamePrefix and global CSS.
const customStyles: StylesConfig<Option, true, GroupBase<Option>> = {
  control: (provided, state) => ({
    ...provided,
    borderColor: state.isFocused ? '#E7E7E7' : '#D1D5DB',
    boxShadow: state.isFocused ? '0 0 0 1px rgba(99, 102, 241)' : 'none',
    '&:hover': {
      borderColor: state.isFocused ? 'rgba(99, 102, 241)' : '#9CA3AF',
    },
    minHeight: '40px',
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: '#FAFAFA',
    borderRadius: '2rem',
    padding: '0 0.5rem',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: '#454548',
    backgroundColor: '#FAFAFA',
    fontSize: '14px',
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: '#454548',
    ':hover': {
      backgroundColor: '#FAFAFA',
      color: '#454548',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#EEEBFD' : provided.backgroundColor,
    color: state.isFocused ? '#5133EA' : provided.color,
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '400px',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#f2f2f2',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#ccc',
      borderRadius: '3px',
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '14px',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#1B212D',
  }),
}

const ReactSelectMulti: React.FC<ReactSelectMultiProps> = ({
  value,
  onChange,
  options,
  placeholder = 'Choose an option',
  closeMenuOnSelect = false,
}) => {
  return (
    <Select
      isMulti
      value={value}
      onChange={(selected) => onChange(selected as Option[])}
      options={options}
      styles={customStyles}
      classNamePrefix="react-select"
      placeholder={placeholder}
      closeMenuOnSelect={closeMenuOnSelect}
    />
  )
}

export default ReactSelectMulti
