import React from 'react'

export const UploadSectionDocumentIcon: React.FC<
  React.SVGProps<SVGSVGElement>
> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_6421_4203)">
      <path
        d="M39.4707 2.66691V15.4668C39.4707 16.3155 39.8078 17.1294 40.408 17.7295C41.0081 18.3296 41.822 18.6668 42.6707 18.6668H55.4706"
        className="stroke-icon-brand-default"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.9998 60.2666H17.0667C15.3693 60.2666 13.0187 60.3151 11.8185 59.1149C10.6183 57.9147 10.6668 55.564 10.6668 53.8667V9.06709C10.6668 7.36972 10.6183 5.01909 11.8185 3.81887C13.0187 2.61865 15.3693 2.66715 17.0667 2.66715H39.4665L55.4663 18.667V35.9999"
        className="stroke-icon-brand-default"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44 52H60M52 44V60"
        className="stroke-icon-brand-default"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_6421_4203">
        <rect width="64" height="64" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default UploadSectionDocumentIcon
