import PropTypes from 'prop-types'
import React from 'react'

import { useStore } from '../../helpers/useStore'
import BellIcon from '../assets/BellIcon'
import FingerprintIcon from '../assets/FingerprintIcon'
import HomeIcon from '../assets/HomeIcon'
import SignOutIcon from '../assets/SignOutIcon'

export const ProfileDropdown = ({
  showDropdown,
  position = 'right-0 top-2',
}) => {
  const store = useStore()

  if (!showDropdown) {
    return null
  }

  return (
    <div className="relative">
      <div
        className={`absolute ${position} gap-1 py-2 bg-bgCol-default-default border border-bgCol-neutral-secondary rounded-lg z-10`}
      >
        <ul className="flex flex-col gap-1">
          <ListItem
            icon={<HomeIcon />}
            text={'Account Details'}
            url={`${store.credentials.customerAppUrl}/users/my_profile`}
          />
          <ListItem
            icon={<FingerprintIcon />}
            text={'Login & Security'}
            url={`${store.credentials.customerAppUrl}/users/security/edit`}
          />
          <ListItem
            icon={<BellIcon />}
            text={'Notifications'}
            url={`${store.credentials.customerAppUrl}/users/profiles/edit_notification_prefs`}
          />
          <hr className="border border-bgCol-neutral-secondary" />
          <ListItem
            icon={<SignOutIcon />}
            text={'Sign Out'}
            url={`${store.credentials.customerAppUrl}/users/sign_out`}
          />
        </ul>
      </div>
    </div>
  )
}

const ListItem = ({ icon, text, url }) => (
  <a href={url}>
    <li className="flex items-center px-3 py-2 rounded-md whitespace-nowrap">
      {icon && <span className="mr-2">{icon}</span>}
      {text}
    </li>
  </a>
)

ProfileDropdown.propTypes = {
  showDropdown: PropTypes.bool.isRequired,
  position: PropTypes.string,
}

ListItem.propTypes = {
  icon: PropTypes.element,
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
}
