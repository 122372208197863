/* eslint-disable consistent-return */
import axios from 'axios'
import CryptoJS from 'crypto-js'
import { runInAction } from 'mobx'

import addFile from '../../actions/AddFile'
import addFlash from '../../actions/AddFlash'
import { updateFileUploadProgress } from '../../actions/fileUploadActions'
import { getPresignedUrlAndMetadata, validateFile } from '../../apis/filesApi'
import { FileUploadStatus } from '../../helpers/constants'
import { rollbarConfig } from '../../helpers/rollbarConfig'
import GetIconForContentType from '../main_chat/GetIconForContentType'

const MAX_FILE_COUNT = 10
const ALLOWED_FILE_TYPES = [
  'application/msword', // doc
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
  'application/vnd.ms-powerpoint', // ppt
  'application/vnd.openxmlformats-officedocument.presentationml.presentation', // pptx
  'application/pdf', // pdf
  'image/jpeg', // jpg, jpeg
  'image/png', // png
  'image/gif', // gif
  'image/webp', // webp
]

const findFolderByPath = (folders, path) => {
  const folderArray = Array.isArray(folders) ? folders : folders?.toJS?.() || []

  for (const folder of folderArray) {
    if (folder.full_path === path) {
      return folder
    }
    const foundInSubfolders = findFolderByPath(folder.sub_folders, path)
    if (foundInSubfolders) {
      return foundInSubfolders
    }
  }
  return null
}

export const insertIntoTree = (store, item, isFolder = false) => {
  let targetFolder = findFolderByPath(
    store.folders.sub_folders || [],
    item.relativePath
  )
  targetFolder ||= store.currentFolder

  if (targetFolder) {
    runInAction(() => {
      if (isFolder) {
        // Check if the folder already exists
        const exists = targetFolder.sub_folders.some(
          (folder) => folder.title === item.title
        )
        if (!exists) {
          targetFolder.sub_folders.push(item)

          // Update currentFolder if applicable
          if (store.currentFolder?.id === targetFolder.id) {
            store.currentFolder = {
              ...store.currentFolder,
              sub_folders: [...store.currentFolder.sub_folders, item],
            }
          }
        }
      } else {
        // Check if the file/document already exists
        const exists = targetFolder.documents.some(
          (doc) => doc.filename === item.filename
        )
        if (!exists) {
          targetFolder.documents.push(item)
        }
      }
    })
  } else {
    console.error(
      'Folder not found in tree for path:',
      item.relativePath,
      '\nItem:',
      item
    )
  }
}

async function handleFilesUploadLegacy(
  store,
  alreadyAcceptedAmount,
  acceptedFiles
) {
  const validFiles = []

  for (const file of acceptedFiles) {
    if (validFiles.length + alreadyAcceptedAmount >= MAX_FILE_COUNT) {
      addFlash(
        store,
        'notice',
        `Maximum number of documents per upload reached. You can submit the remaining files in a new request.`
      )
      break
    }

    if (!ALLOWED_FILE_TYPES.includes(file.type)) {
      addFlash(
        store,
        'error',
        'This document is no supported. Check our list of supported documents.'
      )
      continue
    }

    validFiles.push(file)
  }

  try {
    const uploadPromises = validFiles.map(async (acceptedFile) => {
      const reader = new FileReader()
      const matterId = store.selectedMatter?.id

      return new Promise((resolve, reject) => {
        reader.onload = async (e) => {
          const wordArray = CryptoJS.lib.WordArray.create(e.target.result)
          const hash = CryptoJS.MD5(wordArray)
          const checksum = hash.toString(CryptoJS.enc.Base64)

          try {
            const { direct_upload_url, filename, key, file, content_type, id } =
              await getPresignedUrlAndMetadata({
                filename: acceptedFile.name,
                byte_size: acceptedFile.size,
                checksum,
                content_type: acceptedFile.type,
                store,
                matter_id: matterId,
              })
            addFile(store, {
              file: file,
              key: key,
              file_name: filename,
              icon_for_content_type: GetIconForContentType(content_type),
              content_type: content_type,
              e_attachment_id: id,
            })

            const response = await axios.put(direct_upload_url, acceptedFile, {
              headers: {
                'Content-MD5': checksum,
                'Content-Type': content_type,
              },
              onUploadProgress: (progressEvent) => {
                const progress = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                )
                updateFileUploadProgress(store, file.id, progress)
              },
            })

            if (response.status === 200) {
              updateFileUploadProgress(
                store,
                file.id,
                FileUploadStatus.Uploaded
              )

              const validationResult = await validateFile(
                store,
                id,
                file.id,
                file.byte_size
              )

              if (!validationResult.error_msg) {
                updateFileUploadProgress(
                  store,
                  file.id,
                  FileUploadStatus.Validated
                )
              } else {
                updateFileUploadProgress(
                  store,
                  file.id,
                  FileUploadStatus.Error,
                  validationResult.error_msg
                )
              }
            } else {
              updateFileUploadProgress(
                store,
                file.id,
                FileUploadStatus.Error,
                'Upload failed'
              )
            }

            resolve(response)
          } catch (error) {
            rollbarConfig(store)?.error(error)
            reject(error)
          }
        }
        reader.readAsArrayBuffer(acceptedFile)
      })
    })

    await Promise.all(uploadPromises)
  } catch (error) {
    rollbarConfig(store)?.error(error)
  }
}

export { ALLOWED_FILE_TYPES, handleFilesUploadLegacy }
