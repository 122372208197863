import PropTypes from 'prop-types'
import React from 'react'

import ActivityItemHeader from './ActivityItemHeader'
import { LoadingBar } from './LoadingBar'
import { scrollAndHighlightMessage } from '../../actions/activityItemActions'
import { useStore } from '../../helpers/useStore'
import MagicIcon from '../assets/MagicIcon'
import { TruncatedText } from '../TruncatedText'

const MemoItem = ({ activity }) => {
  const store = useStore()
  const durationEstimate = activity.activity_item?.duration_estimate
  const status = activity.activity_item?.status

  const memoIsLoading =
    status !== 'COMPLETED' && status !== 'FAILED' && status !== 'REJECTED'

  const isMemoFailed = status === 'FAILED' || status === 'REJECTED'

  const memoHeaderDescription = () => {
    if (status === 'FAILED') {
      return 'Unable to generate the memo.'
    }
    if (status === 'REJECTED') {
      return 'No relevant authorities found'
    }

    return memoIsLoading
      ? 'You requested a memo'
      : 'Alexi generated a legal memo'
  }

  return (
    <div
      key={activity.id}
      onClick={() => scrollAndHighlightMessage(store, activity)}
      ref={activity.ref}
      className="mb-4 mt-4 p-2 hover:bg-bgCol-neutral-default rounded-lg"
    >
      <div className="flex items-start">
        {/* Activity icon */}
        <span className="w-5">
          <MagicIcon />
        </span>

        {/* Activity content */}
        <div className="ml-2">
          <ActivityItemHeader
            activity={activity}
            description={memoHeaderDescription()}
          />
          <div
            className={`flex flex-col justify-center items-start gap-1 self-stretch pt-2
          ${isMemoFailed ? 'text-txt-danger-default' : 'text-txt-default-secondary'}`}
          >
            <TruncatedText text={activity.activity_item.description} />
          </div>
          {memoIsLoading && (
            <LoadingBar
              activityId={activity.id}
              description={'Generating a memo'}
              durationEstimate={durationEstimate}
            />
          )}
        </div>
      </div>
    </div>
  )
}

MemoItem.propTypes = {
  activity: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    activity_item: PropTypes.shape({
      description: PropTypes.string,
      duration_estimate: PropTypes.number,
      status: PropTypes.string,
    }),
    ref: PropTypes.object,
    chat_thread_message_id: PropTypes.string,
  }).isRequired,
}

export default MemoItem
