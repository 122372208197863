import PropTypes from 'prop-types'
import React from 'react'

const FileUploadIcon = ({ stroke = '#FC9D05' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M17 10L10 3M10 3L3 10M10 3V17"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

FileUploadIcon.propTypes = {
  stroke: PropTypes.string,
}

export default FileUploadIcon
