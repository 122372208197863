import React from 'react'

import CanadianDropdown from './CanadianDropdown'
import USDropdown from './USDropdown'
import { useStore } from '../../../helpers/useStore'

interface StateJurisdictionSelectorProps {
  jurisdiction: string
  setJurisdiction: (value: string) => void
  state: string
  setState: (value: string) => void
  additionalContext: Record<string, any>
}

const StateJurisdictionSelector: React.FC<StateJurisdictionSelectorProps> = ({
  jurisdiction,
  setJurisdiction,
  state,
  setState,
  additionalContext,
}) => {
  const store = useStore()
  const countryCode = store.user && store.user.enterprise.country_code
  const userIsCanadian = countryCode === 'CA'

  return (
    <div
      className={`my-4 rounded-lg ${userIsCanadian ? 'w-1/2' : 'w-full'}`}
      style={{ borderRadius: '0 8px 8px 8px' }}
    >
      <div>
        {userIsCanadian ? (
          <CanadianDropdown
            state={state}
            setJurisdiction={setJurisdiction}
            additionalContext={additionalContext}
            setState={setState}
          />
        ) : (
          <USDropdown
            state={state}
            setState={setState}
            jurisdiction={jurisdiction}
            setJurisdiction={setJurisdiction}
            additionalContext={additionalContext}
          />
        )}
      </div>
    </div>
  )
}

export default StateJurisdictionSelector
