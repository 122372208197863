import React from 'react'

const ArrowLeftToLine = ({
  strokeClass = 'stroke-icon-neutral-default',
}: {
  strokeClass?: string
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
  >
    <path
      d="M5.83333 10.5002L17.5 10.5002M5.83333 10.5002L10.8333 5.50016M5.83333 10.5002L10.8333 15.5002M2.5 16.3335L2.5 4.66683"
      className={`${strokeClass}`}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default ArrowLeftToLine
