import { FILE_TYPES } from '../../helpers/FileTypes'

const GetIconForContentType = (contentType) => {
  for (const value of Object.values(FILE_TYPES)) {
    // Use Object.values
    if (value.mimeType === contentType) {
      return value.icon
    }
  }
  return 'default.jpg'
}

export default GetIconForContentType
