import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import DocumentManager from './DocumentManager'
import MainChat from './MainChat'
import { setFetchedDocument } from '../../actions/fileActions'
import { isMdSize } from '../../actions/showLayoutActions'
import { useStore } from '../../helpers/useStore'
import { useScreenSize } from '../../hooks/useScreenSize'

const ChatTemplate = observer(() => {
  const store = useStore()
  const navigate = useNavigate()
  const { screenWidth } = useScreenSize()
  const { showDocumentManager } = store.showLayouts

  // TODO: move the document ID into breadcrumb controller
  const { id, documentId } = useParams()
  useEffect(() => {
    if (documentId && store.currentDocument?.id !== documentId) {
      setFetchedDocument(store, documentId).then((data) => {
        // in case the file was updated (move to new folder) after the static message render
        navigate(`/chat/${id}/folder/${data.folder_id}/document/${documentId}`)
      })
    }
  }, [documentId])

  if (!isMdSize(screenWidth)) {
    return (
      <div className="grid grid-cols-1">
        {!showDocumentManager ? <MainChat /> : <DocumentManager />}
      </div>
    )
  }

  // only show one column
  let gridColumns = 'grid-cols-1'
  let mainChatSpan = ''

  if (showDocumentManager) {
    if (store.currentDocument) {
      // change chat:document to 1:1 if preview document
      gridColumns = 'grid-cols-2'
      mainChatSpan = 'grid-cols-1'
    } else {
      // change chat:document to 2:1 if folder view
      gridColumns = 'grid-cols-3'
      mainChatSpan = 'col-span-2'
    }
  }

  return (
    <div className={`grid ${gridColumns}`}>
      <div className={mainChatSpan}>
        <MainChat />
      </div>
      {showDocumentManager ? <DocumentManager /> : null}
    </div>
  )
})

export default ChatTemplate
