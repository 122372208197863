import { observer } from 'mobx-react-lite'
import React, { useRef } from 'react'
import ReactMarkdown from 'react-markdown'
import RemarkGfm from 'remark-gfm'

import SummaryDocPopoverAiTooltip from './SummaryDocPopoverAiTooltip'
import SummaryModalAiDisclaimer from './SummaryModalAiDisclaimer'
import { hideModal } from '../../../actions/modalActions'
import { useStore } from '../../../helpers/useStore'
import AlexiLogo from '../../assets/alexiLogo'
import DownloadIcon from '../../assets/DownloadIcon'
import InfoIconSmall from '../../assets/InfoIconSmall'
import MemoDocument from '../../assets/MemoDocument'
import XIcon from '../../assets/XIcon'
import Tooltip from '../../shared/Tooltip'

const SummaryDocModal = observer(() => {
  const store = useStore()

  const aiInfoBoxRef = useRef(null)
  const backdropRef = useRef(null)

  function scrollToInfoBox() {
    aiInfoBoxRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  function backdropClick(event) {
    // Only dismiss the modal if the click is actually on the dark backdrop
    if (event.target === backdropRef.current) {
      hideModal(store)
    }
  }

  const tooltipContents = (
    <SummaryDocPopoverAiTooltip scrollToInfoBox={scrollToInfoBox} />
  )

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
      onClick={backdropClick}
      ref={backdropRef}
    >
      <div className="flex flex-col bg-white rounded-lg shadow-lg max-w-4xl h-5/6 w-full overflow-hidden">
        <div className="shrink flex items-center bg-white p-6 border-b border-gray-300">
          <div className="p-2 bg-bgCol-neutral-secondary rounded-md flex items-center justify-center">
            <MemoDocument />
          </div>

          <div className="flex-grow ml-4 mr-4 min-w-0">
            <h2 className="text-lg font-semibold text-txt-default-default whitespace-nowrap overflow-hidden text-ellipsis leading-6">
              {store.modal.data.title}
            </h2>
            <div className="flex items-center text-xs">
              <p className="mr-1 text-txt-default-secondary">Created by</p>

              <AlexiLogo width={12} height={12} />
              <p className="text-txt-brand-default px-0.5">alexi</p>
              <Tooltip tooltipContents={tooltipContents}>
                <div className="h-3 w-3 flex items-center">
                  <InfoIconSmall />
                </div>
              </Tooltip>
            </div>
          </div>
          <div className="flex-shrink-0 flex items-center space-x-2">
            <Tooltip tooltipContents="Download">
              <a
                href={`/api/client/v1/summary_documents/${store.modal.data.id}`}
                target="_blank"
                rel="noopener noreferrer"
                className="m-2 w-6 h-6 inline-block"
              >
                <DownloadIcon classNames="w-6 h-6" />
              </a>
            </Tooltip>
            <button onClick={() => hideModal(store)} className="m-2">
              <XIcon classNames="w-[22px] h-[22px]" />
            </button>
          </div>
        </div>

        <div className="summary-document-viewer grow px-6 py-4 overflow-y-auto scrollbar-visible">
          <div className="no-tailwind pb-2">
            <ReactMarkdown
              remarkPlugins={[[RemarkGfm]]}
              className={'no-tailwind viewer-content'}
              components={{
                // eslint-disable-next-line no-unused-vars,unused-imports/no-unused-vars
                a: ({ node, ...props }) => (
                  <a {...props} target="_blank" rel="noopener noreferrer" />
                ),
              }}
            >
              {store.modal.data.summary}
            </ReactMarkdown>
          </div>
          <div ref={aiInfoBoxRef}>
            <SummaryModalAiDisclaimer />
          </div>
        </div>
      </div>
    </div>
  )
})

export default SummaryDocModal
