import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer'
import React, { useEffect, useState } from 'react'

import DocumentViewerHeader, { LoadingComponent } from './DocumentViewerHeader'
import DocumentViewerNoRender from './DocumentViewerNoRender'
import { previewFile } from '../../../apis/filesApi'
import { useStore } from '../../../helpers/useStore'
import { useScreenSize } from '../../../hooks/useScreenSize'

const DocumentViewer = () => {
  const store = useStore()
  const { screenWidth } = useScreenSize()
  const { currentDocument } = store

  const [url, setUrl] = useState('')
  const [documentLoading, setDocumentLoading] = React.useState(true)

  useEffect(() => {
    setDocumentLoading(true)
    previewFile(store, currentDocument?.id || '').then((data) => {
      setUrl(data)
      setDocumentLoading(false)
    })
  }, [currentDocument])

  const fileType =
    currentDocument && 'content_type' in currentDocument
      ? currentDocument.content_type
      : 'html'
  const docs = [{ id: currentDocument?.id, uri: url, fileType }]

  if (documentLoading) {
    return LoadingComponent
  }

  return (
    <div className="w-full bg-bgCol-neutral-secondary">
      <div className="p-4 overflow-y-auto">
        <DocViewer
          key={screenWidth} // re-render when screen size was changed
          className="custom-document-viewer w-full rounded-lg"
          pluginRenderers={DocViewerRenderers}
          documents={docs}
          config={{
            header: {
              disableFileName: true,
              retainURLParams: false,
              overrideComponent: DocumentViewerHeader,
            },
            loadingRenderer: {
              overrideComponent: () => LoadingComponent,
            },
            noRenderer: {
              overrideComponent: DocumentViewerNoRender as any,
            },
            pdfVerticalScrollByDefault: true,
          }}
        />
      </div>
    </div>
  )
}

export default DocumentViewer
