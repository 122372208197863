import React from 'react'
import { useNavigate } from 'react-router-dom'

import UploadButton from './UploadButton'
import { handleFileUpload } from '../../actions/fileUploadActions'
import { useStore } from '../../helpers/useStore'
import ShieldCheckmarkIcon from '../assets/ShieldCheckmarkIcon'
import UploadSectionDocumentIcon from '../assets/UploadSectionDocumentIcon'

const UploadSection = () => {
  const store = useStore()
  const navigate = useNavigate()

  return (
    <div
      className={`h-full w-full flex flex-col justify-center items-center p-1.5 transition-all`}
    >
      <div className="flex flex-col justify-center items-center flex-grow">
        <UploadSectionDocumentIcon className="mb-6" />
        <div className="text-center gap-2 flex flex-col">
          <span className="font-medium leading-6">
            Drag & Drop or upload folders and files.
          </span>
          <span className="text-sm">
            Upload folders, .csv, .doc, .docx, .jpeg, .jpg, .pdf, .png, .ppt,
            .pptx, .xls, .xlsx files.
          </span>
        </div>
        <UploadButton
          className="mt-6"
          onFilesSelected={(e) => handleFileUpload(e, store, navigate)}
        />
      </div>

      <div className="w-full text-center flex flex-row items-center justify-center gap-2">
        <ShieldCheckmarkIcon />
        <span className="text-xs">
          Your confidential information is protected.
        </span>
        <a
          href="https://www.alexi.com/security"
          target="_blank"
          rel="noreferrer"
          className="inline-flex items-center"
        >
          <span className="text-brd-brand-default text-xs">Read</span>
        </a>
      </div>
    </div>
  )
}

export default UploadSection
