// Sort activities by date (most recent first)

import timezonedDatetime from '../helpers/DateTimeUtils'

const sortActivitiesByDate = (activities) => {
  return activities
    .slice()
    .sort(
      (a, b) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    )
}

// Group activities by date
const groupByDate = (activities, store) => {
  return activities.reduce((acc, activity) => {
    const date = timezonedDatetime(
      activity.created_at,
      store,
      'toDateIsoFormat'
    )

    // Initialize date group if not already present
    if (!acc[date]) {
      acc[date] = []
    }

    // Push the activity into the correct date group
    acc[date].push(activity)

    return acc
  }, {})
}

// Group consecutive AttachmentActivityItems and process other activities
const processDayActivities = (dayActivities) => {
  const processedActivities = []
  let currentGroup = null

  for (let i = 0; i < dayActivities.length; i++) {
    const currentActivity = dayActivities[i]

    // Check if the current activity is an AttachmentActivityItem
    if (currentActivity.activity_item_type === 'AttachmentActivityItem') {
      if (!currentGroup) {
        // If no active group, start a new group
        currentGroup = {
          activity_item_type: 'AttachmentActivityGroup',
          items: [currentActivity],
        }
      } else {
        // If already a group, add to the current group
        currentGroup.items.push(currentActivity)
      }
    } else {
      // If it's not an AttachmentActivityItem, finalize the current group if it exists
      if (currentGroup) {
        // Push the group to the output
        processedActivities.push(currentGroup)
        currentGroup = null
      }
      // Add the non-attachment activity directly to the output list
      processedActivities.push(currentActivity)
    }
  }

  // If there is a remaining group after the loop, push it
  if (currentGroup) {
    processedActivities.push(currentGroup)
  }

  return processedActivities
}

// Replace any groups with only one activity item with just the activity
const replaceSingleItemGroups = (processedActivities) => {
  return processedActivities.map((item) => {
    if (
      item.activity_item_type === 'AttachmentActivityGroup' &&
      item.items.length === 1
    ) {
      // Replace group with single item if there's only one activity in the group
      return item.items[0]
    }
    return item
  })
}

// Main function to group activities by date and process them
export const groupByDateAndThread = (store) => {
  const activities = store.activities
  const selectedMatter = store.selectedMatter

  const filteredActivities = activities.filter(
    (activity) => activity.matter_id === selectedMatter?.id
  )

  // Step 1: Sort activities by date
  const sortedActivities = sortActivitiesByDate(filteredActivities)

  // Step 2: Group activities by date
  const groupedByDate = groupByDate(sortedActivities, store)

  // Step 3: Process each day's activities
  Object.keys(groupedByDate).forEach((date) => {
    const dayActivities = groupedByDate[date]

    // Process the day's activities and group consecutive AttachmentActivityItems
    const processedActivities = processDayActivities(dayActivities)

    // Step 4: Replace any groups with only one activity item
    groupedByDate[date] = replaceSingleItemGroups(processedActivities)
  })

  return groupedByDate
}
