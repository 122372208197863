import { action } from 'mobx'

import Store from '../helpers/Store'

export const getDocumentPagination = (store: Store) => {
  return store.documentPagination
}

export const setDocumentPage = action((store: Store, page: number) => {
  store.documentPagination.page = page
})

export const setDocumentPerPage = action((store: Store, perPage: number) => {
  store.documentPagination.perPage = perPage
})

export const setDocumentTotal = action((store: Store, totalCount: number) => {
  store.documentPagination.totalCount = totalCount
})
