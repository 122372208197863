import React from 'react'

const ArrowRightToLine = ({
  strokeClass = 'stroke-icon-neutral-default',
}: {
  strokeClass?: string
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M14.1667 9.99984H2.5M14.1667 9.99984L9.16667 14.9998M14.1667 9.99984L9.16667 4.99984M17.5 4.1665V15.8332"
      className={`${strokeClass}`}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default ArrowRightToLine
