const BreadrcrumbsSkeleton = () => {
  return (
    <nav className="flex flex-row items-center space-x-2 p-4 animate-pulse">
      <div className="h-4 w-32 rounded-md bg-gray-300" />
      <span className="h-5 text-gray-300">/</span>
      <div className="h-4 w-20 rounded-md bg-gray-300" />
    </nav>
  )
}

export default BreadrcrumbsSkeleton
