import axios from 'axios'
import CryptoJS from 'crypto-js'
import { makeAutoObservable, runInAction } from 'mobx'

import UploadBatch from './UploadBatch'
import { getPresignedUrlAndMetadata, completeUpload } from '../../apis/filesApi'
import { rollbarConfig } from '../../helpers/rollbarConfig'
import Store from '../../helpers/Store'

type MaxchatFile = File & { relativePath: string }

class UploadRequest {
  requestStatus:
    | 'initialized'
    | 'uploading'
    | 'processing'
    | 'completed'
    | 'failed' = 'initialized'
  file: MaxchatFile
  uploadBatch: UploadBatch
  store: Store
  enterpriseAttachmentId?: string

  constructor(_file: MaxchatFile, _uploadBatch: UploadBatch) {
    this.file = _file
    this.uploadBatch = _uploadBatch
    this.store = _uploadBatch.store

    makeAutoObservable(this)
  }

  async upload() {
    try {
      // TODO: preflight duplicate file check goes here
      this.requestStatus = 'uploading'
      const reader = new FileReader()
      reader.onload = this.withFileContent
      reader.readAsArrayBuffer(this.file)
    } catch (error) {
      this.handleUploadError(error as Error)
    }
  }

  // NB: bound function for use with FileReader.onload
  public withFileContent = async (event: Event) => {
    try {
      if (!event.target) {
        throw new Error('Unable to read file for upload')
      }

      // Make EnterpriseAttachment, retrieve presigned URL
      const target = event.target as EventTarget & { result: ArrayBuffer }
      const wordArray = CryptoJS.lib.WordArray.create(target.result)
      // S3 requires the checksum base64 encoded for some reason
      const checksum = CryptoJS.MD5(wordArray).toString(CryptoJS.enc.Base64)

      const args = {
        filename: this.file.name,
        byte_size: this.file.size,
        relative_path: this.file.relativePath,
        checksum,
        content_type: this.file.type,
        store: this.store,
        matter_id: this.uploadBatch.matterId,
      }

      const { direct_upload_url, id } = await getPresignedUrlAndMetadata(args)
      this.enterpriseAttachmentId = id

      // Upload to S3
      await axios.put(direct_upload_url, this.file, {
        headers: {
          'Content-MD5': checksum,
          'Content-Type': this.file.type,
        },
        // TODO: update an attribute on the request object to drive an animation here?
        // onUploadProgress: (progressEvent) => {
        //   const progress = Math.round(
        //     (progressEvent.loaded * 100) / progressEvent.total
        //   )
        // },
      })

      // Connect file with its matter, and notify platform
      completeUpload(this.store, id, this.uploadBatch.matterId)
      runInAction(() => {
        this.requestStatus = 'processing'
      })
      this.uploadBatch.uploadRequestUploaded()
    } catch (error) {
      this.handleUploadError(error as Error)
    }
  }

  processingFinished() {
    this.requestStatus = 'completed'
    this.uploadBatch.uploadRequestProcessed()
  }

  processingFailed() {
    this.requestStatus = 'failed'
    this.uploadBatch.uploadRequestProcessed()
  }

  handleUploadError(error: Error) {
    console.error('Upload error:', error)
    this.requestStatus = 'failed'
    rollbarConfig(this.store)?.error(error)
    this.uploadBatch.uploadRequestUploaded()
  }
}

export type { MaxchatFile }
export default UploadRequest
