import React from 'react'

const ExternalLinkIcon = ({ className }: { className: string }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g id="lucide:external-link">
      <path
        id="Vector"
        d="M9.2 2H14M14 2V6.8M14 2L7.6 8.4M14 10.8V12.4C14 12.8243 13.8314 13.2313 13.5314 13.5314C13.2313 13.8314 12.8243 14 12.4 14H3.6C3.17565 14 2.76869 13.8314 2.46863 13.5314C2.16857 13.2313 2 12.8243 2 12.4V3.6C2 3.17565 2.16857 2.76869 2.46863 2.46863C2.76869 2.16857 3.17565 2 3.6 2H4.8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={className}
      />
    </g>
  </svg>
)

export default ExternalLinkIcon
