import React from 'react'

import Tooltip from './Tooltip'
import ArrowDownIcon from '../assets/ArrowDownIcon'
import ArrowUpIcon from '../assets/ArrowUpIcon'

interface TableSortButtonProps {
  columnName: string
  columnKey: string
  sortBy: string
  sortOrder: string
  handleSortButtonClick: (sortKey: string) => void
}
const TableSortableHeader = ({
  columnName,
  columnKey,
  sortBy,
  sortOrder,
  handleSortButtonClick,
}: TableSortButtonProps) => {
  return (
    <button
      className="p-0"
      onClick={() => {
        handleSortButtonClick(columnKey)
      }}
    >
      <div className="flex items-center space-x-0.5">
        {' '}
        {/* Updated to flex for horizontal alignment */}
        <span>{columnName}</span>
        {sortBy === columnKey && (
          <div className="flex items-center">
            {' '}
            {/* Add spacing between the text and icon */}
            {sortOrder === 'asc' ? (
              <Tooltip tooltipContents="Sort ascending">
                <ArrowUpIcon />
              </Tooltip>
            ) : (
              <Tooltip tooltipContents="Sort descending">
                <ArrowDownIcon />
              </Tooltip>
            )}
          </div>
        )}
      </div>
    </button>
  )
}

export default TableSortableHeader
