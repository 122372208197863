import React, { useRef, useState } from 'react'

import WhiteUploadIcon from '../assets/WhiteUploadIcon'

interface UploadButtonProps {
  onFilesSelected: (files: File[]) => void
  className?: string
}

const UploadButton: React.FC<UploadButtonProps> = ({
  onFilesSelected,
  className,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [isDropdownOpen, setDropdownOpen] = useState(false)

  const allowedFileTypes = [
    '.csv',
    '.doc',
    '.docx',
    '.jpeg',
    '.jpg',
    '.pdf',
    '.png',
    '.ppt',
    '.pptx',
    '.xls',
    '.xlsx',
  ]

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen)
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files || [])
    onFilesSelected(files)
    if (fileInputRef.current) {
      fileInputRef.current.value = '' // Reset input value
    }
    setDropdownOpen(false) // Close dropdown
  }

  const handleOptionClick = (type: 'Documents' | 'Folders') => {
    if (fileInputRef.current) {
      if (type === 'Documents') {
        fileInputRef.current.removeAttribute('webkitdirectory')
      } else if (type === 'Folders') {
        fileInputRef.current.setAttribute('webkitdirectory', 'true')
      }
      fileInputRef.current.click()
    }
  }

  return (
    <div className={`relative inline-block ${className || ''}`}>
      {/* Upload Button */}
      <button
        onClick={toggleDropdown}
        className="px-6 py-2 flex items-center space-x-2 bg-bgCol-brand-default text-white rounded-md focus:ring-2"
      >
        <WhiteUploadIcon />
        <span className="text-sm">Upload</span>
      </button>

      {/* Dropdown Menu */}
      {isDropdownOpen && (
        <div className="absolute top-full mt-2 right-0 bg-white border border-gray-300 rounded-md shadow-lg w-35">
          <button
            onClick={() => handleOptionClick('Documents')}
            className="w-full px-4 py-2 text-left text-txt-default-default hover:bg-bgCol-default-secondary"
          >
            Documents
          </button>
          <button
            onClick={() => handleOptionClick('Folders')}
            className="w-full px-4 py-2 text-left text-txt-default-default hover:bg-bgCol-default-secondary"
          >
            Folders
          </button>
        </div>
      )}

      {/* Hidden File Input */}
      <input
        ref={fileInputRef}
        type="file"
        multiple={true}
        accept={allowedFileTypes.join(',')}
        onChange={handleFileChange}
        className="hidden"
      />
    </div>
  )
}

export default UploadButton
