import React from 'react'

const UndoIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 4V8.66667H5.66667"
      className="stroke-icon-brand-default"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 11.7778C15 9.92126 14.2625 8.14078 12.9497 6.82802C11.637 5.51527 9.85652 4.77777 8 4.77777C6.27732 4.77953 4.61577 5.41645 3.33333 6.56666L1 8.66666"
      className="stroke-icon-brand-default"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default UndoIcon
