const DocumentDefaultOutline = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="81"
      height="80"
      viewBox="0 0 81 80"
      fill="none"
    >
      <path
        d="M61.8331 75.3333H21.8335C19.7118 75.3333 16.7735 75.3939 15.2732 73.8936C13.773 72.3934 13.8336 69.4551 13.8336 67.3334V11.3339C13.8336 9.21218 13.773 6.2739 15.2732 4.77362C16.7735 3.27335 19.7118 3.33397 21.8335 3.33397H49.8332L69.833 23.3338V67.3334C69.833 69.4551 69.8937 72.3934 68.3934 73.8936C66.8931 75.3939 63.9548 75.3333 61.8331 75.3333Z"
        className="stroke-icon-brand-default"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.8384 3.33363V19.3335C49.8384 20.3943 50.2598 21.4117 51.0099 22.1619C51.7601 22.912 52.7775 23.3334 53.8383 23.3334H69.8382"
        className="stroke-icon-brand-default"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default DocumentDefaultOutline
