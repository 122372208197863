import React from 'react'

const PencilLineIcon: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="lucide:pencil-line">
      <path
        id="Vector"
        d="M12 20.0002H21M15 5.00022L18 8.00022M16.376 3.62222C16.7741 3.22413 17.314 3.00049 17.877 3.00049C18.44 3.00049 18.9799 3.22413 19.378 3.62222C19.7761 4.02031 19.9997 4.56024 19.9997 5.12322C19.9997 5.68621 19.7761 6.22613 19.378 6.62422L7.36798 18.6352C7.13007 18.8731 6.836 19.0472 6.51298 19.1412L3.64098 19.9792C3.55493 20.0043 3.46372 20.0058 3.37689 19.9836C3.29006 19.9613 3.2108 19.9162 3.14742 19.8528C3.08404 19.7894 3.03887 19.7101 3.01662 19.6233C2.99437 19.5365 2.99588 19.4453 3.02098 19.3592L3.85898 16.4872C3.9532 16.1646 4.12722 15.8708 4.36498 15.6332L16.376 3.62222Z"
        stroke="#28BF73"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
)

export default PencilLineIcon
