import React from 'react'

interface CircularLoadingIconProps {
  size?: number
}

const CircularLoadingIcon = ({ size = 24 }: CircularLoadingIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      style={{
        animation: 'spin 1s linear infinite',
      }}
    >
      <g clipPath="url(#clip0_7999_7927)">
        <path
          opacity="0.3"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4ZM2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12Z"
          className="fill-icon-brand-default"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.0005 4.00001C9.93791 3.99586 7.95427 4.79248 6.46746 6.22201C6.37265 6.31289 6.26087 6.3842 6.13849 6.43187C6.01612 6.47954 5.88555 6.50265 5.75425 6.49986C5.62295 6.49708 5.49348 6.46846 5.37324 6.41564C5.253 6.36281 5.14434 6.28683 5.05346 6.19201C4.96259 6.0972 4.89128 5.98541 4.84361 5.86304C4.79593 5.74067 4.77283 5.6101 4.77562 5.4788C4.7784 5.3475 4.80702 5.21803 4.85984 5.09779C4.91266 4.97755 4.98865 4.86889 5.08347 4.77801C6.94265 2.99162 9.42214 1.99581 12.0005 2.00001C12.2657 2.00001 12.52 2.10537 12.7076 2.29291C12.8951 2.48044 13.0005 2.7348 13.0005 3.00001C13.0005 3.26523 12.8951 3.51958 12.7076 3.70712C12.52 3.89466 12.2657 4.00001 12.0005 4.00001Z"
          className="fill-icon-brand-default"
        />
      </g>
      <defs>
        <clipPath id="clip0_7999_7927">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CircularLoadingIcon
