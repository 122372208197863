import React from 'react'

const MagicIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M13.5043 17.8636C13.4474 17.6434 13.3327 17.4424 13.1718 17.2816C13.011 17.1207 12.8101 17.006 12.5898 16.9491L8.68584 15.9424C8.61924 15.9235 8.56061 15.8834 8.51887 15.8282C8.47713 15.7729 8.45455 15.7056 8.45455 15.6364C8.45455 15.5671 8.47713 15.4998 8.51887 15.4445C8.56061 15.3893 8.61924 15.3492 8.68584 15.3303L12.5898 14.3229C12.81 14.2662 13.0109 14.1515 13.1717 13.9908C13.3325 13.8301 13.4473 13.6293 13.5043 13.4091L14.511 9.50515C14.5297 9.43828 14.5697 9.37937 14.6251 9.33741C14.6804 9.29544 14.7479 9.27273 14.8174 9.27273C14.8868 9.27273 14.9543 9.29544 15.0097 9.33741C15.065 9.37937 15.1051 9.43828 15.1238 9.50515L16.1298 13.4091C16.1866 13.6294 16.3014 13.8303 16.4622 13.9912C16.6231 14.152 16.824 14.2668 17.0443 14.3236L20.9482 15.3296C21.0154 15.3482 21.0746 15.3882 21.1168 15.4436C21.159 15.499 21.1818 15.5667 21.1818 15.6364C21.1818 15.706 21.159 15.7737 21.1168 15.8291C21.0746 15.8845 21.0154 15.9246 20.9482 15.9431L17.0443 16.9491C16.824 17.006 16.6231 17.1207 16.4622 17.2816C16.3014 17.4424 16.1866 17.6434 16.1298 17.8636L15.1231 21.7676C15.1044 21.8344 15.0643 21.8934 15.009 21.9353C14.9537 21.9773 14.8862 22 14.8167 22C14.7473 22 14.6798 21.9773 14.6244 21.9353C14.5691 21.8934 14.529 21.8344 14.5103 21.7676L13.5043 17.8636Z"
      className="stroke-icon-brand-default"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.88555 6.90906C5.85309 6.78322 5.7875 6.66838 5.6956 6.57648C5.6037 6.48458 5.48886 6.41899 5.36302 6.38653L3.13217 5.81127C3.09411 5.80047 3.06061 5.77754 3.03676 5.74598C3.01291 5.71441 3 5.67593 3 5.63636C3 5.5968 3.01291 5.55831 3.03676 5.52675C3.06061 5.49518 3.09411 5.47226 3.13217 5.46146L5.36302 4.88584C5.48882 4.8534 5.60363 4.78787 5.69552 4.69604C5.78741 4.60421 5.85303 4.48944 5.88555 4.36367L6.46081 2.13281C6.4715 2.0946 6.4944 2.06094 6.52601 2.03696C6.55763 2.01298 6.59622 2 6.6359 2C6.67557 2 6.71416 2.01298 6.74578 2.03696C6.77739 2.06094 6.80029 2.0946 6.81098 2.13281L7.38588 4.36367C7.41834 4.48951 7.48393 4.60435 7.57583 4.69625C7.66772 4.78814 7.78257 4.85374 7.90841 4.8862L10.1393 5.46109C10.1776 5.47168 10.2115 5.49455 10.2356 5.52621C10.2597 5.55787 10.2727 5.59657 10.2727 5.63636C10.2727 5.67616 10.2597 5.71485 10.2356 5.74651C10.2115 5.77817 10.1776 5.80105 10.1393 5.81163L7.90841 6.38653C7.78257 6.41899 7.66772 6.48458 7.57583 6.57648C7.48393 6.66838 7.41834 6.78322 7.38588 6.90906L6.81062 9.13991C6.79992 9.17812 6.77703 9.21179 6.74541 9.23577C6.7138 9.25975 6.67521 9.27273 6.63553 9.27273C6.59585 9.27273 6.55726 9.25975 6.52565 9.23577C6.49404 9.21179 6.47114 9.17812 6.46045 9.13991L5.88555 6.90906Z"
      className="stroke-icon-brand-default"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default MagicIcon
