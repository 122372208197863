import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import FilesAndFoldersSkeleton from './FilesAndFoldersSkeleton'
import ReadOnlyDocumentHeader from './ReadOnlyDocumentHeader'
import UploadButton from './UploadButton'
import UploadSection from './UploadSection'
import { setFetchedFilesAndFolders } from '../../actions/fileActions'
import { handleFileUpload } from '../../actions/fileUploadActions'
import { useStore } from '../../helpers/useStore'
import useFilesAndFolders from '../../hooks/useFilesAndFolders'
import TableSortableHeader from '../shared/TableSortableHeader'
import EnterpriseDocument from '../workflow/EnterpriseDocument'
import FolderTableRow from '../workflow/FolderTableRow'

const FilesAndFolders = observer(() => {
  const store = useStore()
  const navigate = useNavigate()
  const { id, folderId } = useParams()

  const { data, isLoading } = useFilesAndFolders(store)

  // TODO: PROBABLY NEED TO INVALIDATE ALL CACHES NOT KEEP REFETCHING IDK RENEW
  // TODO: REFETCH ISNT GOOD ENOUGH! RENEW

  setFetchedFilesAndFolders(store, data)

  const handleSort = (newSortKey) => {
    const newSortParams = new Map([['sortBy', newSortKey]])

    const isAscending = store.documentSortParams.get('sortOrder') === 'asc'
    if (newSortKey === store.documentSortParams.get('sortBy')) {
      newSortParams.set('sortOrder', isAscending ? 'desc' : 'asc')
    } else {
      newSortParams.set('sortOrder', 'asc')
    }
    runInAction(() => {
      store.documentSortParams = newSortParams
    })
  }

  const handleFolderClick = (subFolder) => {
    navigate(`/chat/${id}/folder/${subFolder.id}`)
  }

  // Navigate to the clicked folder
  const handleFileClick = (file) => {
    navigate(`/chat/${id}/folder/${folderId}/document/${file.id}`)
  }

  const hasDocumentsOrFolders =
    data?.documents?.length > 0 || data?.sub_folders?.length > 0

  const shouldRenderUploadSection =
    !hasDocumentsOrFolders && data?.folder_type !== 'alexi_generated'

  if (isLoading) {
    return <FilesAndFoldersSkeleton />
  }

  return (
    <div className="p-4 h-full w-full">
      {hasDocumentsOrFolders ? (
        <>
          {/* Header Section */}

          <div className="pb-4">
            {store.selectedMatter.is_alr ? (
              <div className="justify-self-end">
                <UploadButton
                  onFilesSelected={(e) => handleFileUpload(e, store, navigate)}
                />
              </div>
            ) : (
              <ReadOnlyDocumentHeader />
            )}
          </div>

          {/* Table Section */}
          <div className="w-full">
            <table className="w-full table-auto border-collapse divide-y divide-gray-200">
              <thead>
                <tr>
                  <th className="px-4 py-2 text-left text-sm font-normal text-gray-700">
                    <div className="flex items-center space-x-1">
                      <TableSortableHeader
                        columnName="Name"
                        columnKey="name"
                        sortBy={store.documentSortParams.get('sortBy')}
                        sortOrder={store.documentSortParams.get('sortOrder')}
                        handleSortButtonClick={handleSort}
                      />
                    </div>
                  </th>
                  <th className="px-4 py-2 text-left text-sm font-normal text-gray-700">
                    <div className="flex items-center space-x-1">
                      <TableSortableHeader
                        columnName="Type"
                        columnKey="type"
                        sortBy={store.documentSortParams.get('sortBy')}
                        sortOrder={store.documentSortParams.get('sortOrder')}
                        handleSortButtonClick={handleSort}
                      />
                    </div>
                  </th>
                  <th className="px-4 py-2 w-12"></th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {/* Subfolders */}
                {data?.sub_folders?.map((subFolder) => (
                  <FolderTableRow
                    key={`table-folder-row-${subFolder.id}`}
                    folder={subFolder}
                    handleRowClick={() => handleFolderClick(subFolder)}
                  />
                ))}

                {/* Documents */}
                {data?.documents?.map((doc) => (
                  <EnterpriseDocument
                    key={doc.id}
                    doc={doc}
                    handleRowClick={() => handleFileClick(doc)}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </>
      ) : shouldRenderUploadSection ? (
        <UploadSection />
      ) : null}
    </div>
  )
})

export default FilesAndFolders
