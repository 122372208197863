import React from 'react'

import { Breadcrumb } from '../../hooks/useBreadcrumbs'
import FolderIcon from '../assets/FolderIcon'

interface ModalStyle {
  top: string | number
  left: string | number
  position: string
}

interface BreadcrumbsListModalProps {
  closeModal: () => void
  handleBreadcrumbsClick: (folderId: string) => void
  modalStyle: ModalStyle
  breadcrumbs: Breadcrumb[]
}

export const BreadcrumbsListModal: React.FC<BreadcrumbsListModalProps> = ({
  closeModal,
  modalStyle,
  breadcrumbs,
  handleBreadcrumbsClick,
}) => {
  return (
    <div
      className="fixed inset-0 z-50 bg-bgCol-default-default bg-opacity-10"
      onClick={closeModal}
    >
      <div
        className="absolute bg-white rounded-md shadow-lg p-4 w-[368px] max-h-[70vh] overflow-auto"
        style={{
          top: modalStyle.top,
          left: modalStyle.left,
        }}
        onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside modal
      >
        <button
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-800"
          onClick={closeModal}
        >
          &times;
        </button>
        <ul className="space-y-4 p-2">
          {breadcrumbs.map((folder) => (
            <li
              key={folder.id}
              className="flex items-center truncate"
              title={folder.title}
            >
              <span className="text-txt-brand-default font-bold mr-2">
                <FolderIcon
                  className="stroke-icon-neutral-default flex-shrink-0"
                  stroke=""
                  backgroundColor=""
                  width={18}
                  height={18}
                />
              </span>
              <button
                onClick={() => handleBreadcrumbsClick(folder.id)}
                className="text-txt-default-secondary hover:underline flex items-center"
              >
                {folder.title.length > 25
                  ? `${folder.title.slice(0, 22)}...`
                  : folder.title}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
