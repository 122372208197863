export enum ActivityItemStatus {
  STARTED = 'STARTED',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  INVALID = 'INVALID',
  REJECTED = 'REJECTED',
}

export enum MessageType {
  USER_MESSAGE = 'userMessage',
  SYSTEM_MESSAGE = 'systemMessage',
  MEMO_FORM_RENDERED = 'memoFormRendered',
  MEMO_FORM_SUBMITTED = 'memoFormSubmitted',
  INSTANT_MEMO_RETRY_FORBIDDEN = 'instantMemoRetryForbidden',
  INSTANT_MEMO_RETRY_SUGGESTED = 'instantMemoRetrySuggested',
  INSTANT_MEMO_REJECTED = 'instantMemoRejected',
  MEMO_RESULT = 'memoResult',
  DOC_SUMMARY_RESULT = 'docSummaryResult',
  EMPTY_MEMO_FORM = 'emptyMemoForm',
  MEMO_FORM_CANCELLED = 'memoFormCancelled',
}

export enum FileUploadStatus {
  Uploading = 'uploading',
  Uploaded = 'uploaded',
  Validated = 'validated',
  Error = 'error',
}

export const DEFAULT_UPLOAD_PATH = 'Home/My Documents'

export const LEGACY_INITIAL_CHAT_SUGGESTIONS = [
  {
    label: 'Get an answer to a legal question',
    input: 'Can you help me answer a legal question?',
  },
  {
    label: 'Get a research memo',
    input: 'Can you prepare a research memo for me?',
  },
  {
    label: 'Ask a question about a document',
    input: 'Can you help me answer a question about a document?',
  },
  {
    label: 'Summarize a document',
    input: 'Can you help me summarize a document?',
  },
  {
    label: 'Draft a document',
    input: 'Can you help me draft a document?',
  },
]

// TODO: waiting product team to finalize the chat messages
export const INITIAL_CHAT_SUGGESTIONS = [
  {
    label: 'Analyze uploaded documents',
    input: 'Analyze uploaded documents. ',
  },
  {
    label: 'Build persuasive arguments',
    input: 'User example. ',
  },
  {
    label: 'Refine legal strategies',
    input: 'Refine legal strategies. ',
  },
  {
    label: 'Ask about the law',
    input: 'Ask about the law.',
  },
  {
    label: 'Find a case',
    input: 'Find a case.',
  },
  {
    label: 'Get a research memo',
    input: 'Get a research memo.',
  },
  {
    label: 'Automate a first draft',
    input: 'Automate a first draft.',
  },
]

export const FILE_TYPE_MAP: { [key: string]: string } = {
  'application/msword': 'Word',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    'Word',
  'application/vnd.ms-powerpoint': 'PowerPoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    'PowerPoint',
  'application/pdf': 'PDF',
  'image/jpeg': 'JPEG',
  'image/png': 'PNG',
  'image/gif': 'GIF',
  'image/webp': 'WebP',
}
