const UploadIcon = ({
  strokeClass = 'stroke-icon-neutral-default',
}: {
  strokeClass?: string
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M1 11.7059V13.3529C1 13.7898 1.17353 14.2087 1.48241 14.5176C1.7913 14.8265 2.21023 15 2.64706 15H12.5294C12.9662 15 13.3852 14.8265 13.6941 14.5176C14.0029 14.2087 14.1765 13.7898 14.1765 13.3529V11.7059M3.47059 5.11765L7.58823 1M7.58823 1L11.7059 5.11765M7.58823 1V10.8824"
      className={`${strokeClass}`}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default UploadIcon
