import { makeAutoObservable, computed, toJS, reaction } from 'mobx'
import React from 'react'

import { DEFAULT_UPLOAD_PATH, MessageType } from './constants'
import UploadBatch from '../actions/upload_batch/UploadBatch'
import { groupByDateAndThread } from '../actions/workflowActivityActions'
import { UserInfo } from '../apis/userApi'
import {
  IInstantMemo,
  IEnterpriseAttachment,
  ISummaryDocument,
} from '../components/types/interfaces'

class Store {
  // User-related Data
  user: UserInfo | null = null
  credentials: Credentials = {
    customerAppUrl: '',
  }

  // Input and Interaction
  inputValue = ''
  inputRef: any = null
  isInputFocused = true
  pendingUrlReplacements = new Map()

  uploadedFiles: File[] = []
  chunkBuffer = ''
  fetchedUrls = new Set()

  // Matter-related Data
  matters: Matter[] = []
  selectedMatter: Matter | null = null
  activities: Activity[] = []
  activitiesLoading: ActivitiesLoading = {}
  documents = []
  folders: Partial<Folder> = {}
  currentFolder: Partial<Folder> | null = null
  currentDocument:
    | IInstantMemo
    | IEnterpriseAttachment
    | ISummaryDocument
    | null = null
  matterUploadsInProgress = {}

  // Messaging and Notifications
  chatResponseLoading = {}
  flashes = []
  lastProcessedIds = {}
  messageQueues = {}
  messages: Record<string, Message[]> = {}
  isStreaming: Record<string, boolean> = {}

  // Channels
  matterChannels = {}
  activityChannels = {}
  documentChannels = {}

  userChannel = null // User channel message related

  showLatestMessagesButton = false
  highlightedMessageRef: null | React.RefObject<HTMLElement> = null
  highlightedActivityRef: null | React.RefObject<HTMLElement> = null

  // Workflow and Steps
  currentStep = 0
  workflowTab = 'activities' // 'activities' or 'documents'

  // Data Management
  sortParams: Map<string, string> | undefined = undefined
  filterParams: Map<string, string> | undefined = undefined
  manifest = null

  documentSortParams: Map<string, string> = new Map([
    ['sortBy', 'name'],
    ['sortOrder', 'asc'],
  ])

  documentPagination: DocumentPagination = {
    page: 1,
    perPage: 25,
    totalPages: 1,
    totalCount: 0,
  }

  modal: ModalStore = {
    isOpen: false,
    isLoading: false,
    contentType: null,
    contentId: null,
    errorMessage: null,
    data: {},
  }

  // Onboarding step
  current_onboarding_step = null
  onboarding_completed = false

  // layout store
  showLayouts: ShowLayout = {
    showSidebar: true,
    showDocumentManager: false,
  }

  // Batched file uploads
  uploadBatches: Record<string, UploadBatch[] | undefined> = {}

  constructor() {
    makeAutoObservable(this, {
      groupedActivities: computed,
    })

    const storedActivitiesLoading = localStorage.getItem('activitiesLoading')

    if (storedActivitiesLoading !== null) {
      this.activitiesLoading = JSON.parse(storedActivitiesLoading)
    }

    // Automatically reset currentDocument when currentFolder changes
    reaction(
      () => this.currentFolder,
      () => {
        this.currentDocument = null
      }
    )
  }

  // Computed property for grouping activities by date and thread ID
  get groupedActivities(): {
    [date: string]: { [threadId: string]: Activity[] }
  } {
    return groupByDateAndThread(this)
  }

  get uploadDefaultDocumentUploadPath(): Partial<Folder> | null {
    if (this.selectedMatter?.id !== this.folders.matter_id) {
      return null
    }
    return (
      this.folders.sub_folders?.find(
        (folder) => folder.title === DEFAULT_UPLOAD_PATH
      ) || this.folders
    )
  }

  get mostRecentMessage(): Message | null {
    if (!this.selectedMatter) {
      return null
    }
    const length = this.messages[this.selectedMatter.id].length

    if (length === 0) {
      return null
    }

    return this.messages[this.selectedMatter.id][length - 1]
  }
}

export function printStoreAttributes(store: { [x: string]: unknown }) {
  Object.keys(store).forEach((key) => {
    /* eslint-disable no-console */
    console.log(`${key}:`, toJS(store[key]))
  })
}

export default Store

export type { Store }

interface Activity {
  id: string
  created_at: string
  chat_thread_message_id: string
  [key: string]: any
}

interface ActivitiesLoading {
  [id: string]: ActivityLoadingItem
}

interface ActivityLoadingItem {
  durationElapsed: number
  durationEstimate: number
  durationStartTime: number
  intervalId: ReturnType<typeof setInterval> | null
}

interface ModalStore {
  isOpen: boolean
  isLoading: boolean
  contentType: null | 'InstantMemo' | 'SummaryDocument' | 'EnterpriseAttachment'
  contentId: null | string
  errorMessage: null | string
  data: InstantMemoModalData | object
}

interface InstantMemoModalData {
  instant_memo: IInstantMemo
  instant_memo_html: string
}

export interface Credentials {
  launchDarklyClientId?: string
  customerAppUrl: string
  rollbarAccessToken?: string
  rollbarEnvironment?: string
  hotjarId?: number
  hotjarVersion?: number
  googleAnalyticsId?: string
}

interface ChatThread {
  id: string
  name: string | null
  created_at: string
}

export interface Matter {
  id: string
  assigned: boolean
  title: string
  gen_name: string | null
  user_id: number
  thread_id: string
  updated_at: string
  file_number: string | null
  created_at: string
  is_scheduled_for_deletion: boolean
  chat_threads: ChatThread[]
  jusrisdiction: string | null
  state_id: string
  display_formatted_jurisdiction: string | null
  is_alr: boolean
}

interface ShowLayout {
  showSidebar: boolean
  showDocumentManager: boolean
}

export interface File {
  content_type: string
  e_attachment_id: string
  file: {
    attachable_sgid: string
  }
  filename: string
  progress: string
  status: string
  icon_for_content_type: string
  matterId: string
  error_msg: string | null
}

interface ReplacementUrlMetadata {
  replacement_url: string
  doc_id: number
}

export interface Message {
  id: string
  content: string
  role: 'user' | 'system'
  created_at: string
  message_type: MessageType
  response_additional_context?: ResponseAdditionalContext
  current_type: 'text' | 'file' | 'markdown'
  replacement_urls_metadata?: Record<string, ReplacementUrlMetadata>
  async_estimate_seconds?: number
  async_estimate_at?: Date
  async_wip_message?: string
  async_progress: undefined | number // in [0,1], to animate progression of the progress bar

  thread_id: string
  matter_id: string
  instant_memo_id?: string
  instant_memo_title?: string
  instant_memo_retries?: number
  enterprise_attachments?: IEnterpriseAttachment[]
  summary_document?: ISummaryDocument
}

export interface ResponseAdditionalContext {
  research_facts?: string
  research_question?: string
  jurisdiction?: string
  state?: string
}

export interface Folder {
  id: string
  title: string
  parent_id?: string
  matter_id: string
  full_path: string
  folder_type: string
  documents: (IEnterpriseAttachment | ISummaryDocument)[]
  sub_folders: Folder[]
}

export interface DocumentPagination {
  page: number
  perPage: number
  totalCount: number
  totalPages: number
}
