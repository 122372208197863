import React from 'react'

export const ShieldCheckmarkIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    {...props}
  >
    <path
      d="M10.5984 7.59916C10.5984 10.5987 8.49874 12.0984 6.00316 12.9683C5.87248 13.0125 5.73053 13.0104 5.60123 12.9623C3.09965 12.0984 1 10.5987 1 7.59916V3.39987C1 3.24076 1.0632 3.08818 1.17571 2.97567C1.28821 2.86317 1.4408 2.79997 1.5999 2.79997C2.7997 2.79997 4.29945 2.08009 5.34327 1.16824C5.47036 1.05966 5.63204 1 5.7992 1C5.96636 1 6.12803 1.05966 6.25512 1.16824C7.30494 2.08609 8.79869 2.79997 9.99849 2.79997C10.1576 2.79997 10.3102 2.86317 10.4227 2.97567C10.5352 3.08818 10.5984 3.24076 10.5984 3.39987V7.59916Z"
      stroke="#1DA46D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.99902 6.9996L5.19882 8.1994L7.59842 5.7998"
      stroke="#1DA46D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default ShieldCheckmarkIcon
