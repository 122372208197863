import React from 'react'

const ChevronDownIcon = ({
  stroke = 'stroke-icon-brand-default',
  fill = 'fill-icon-brand-default',
}: {
  fill?: string
  stroke?: string
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 20 21"
  >
    <path
      d="M14 8.5L10 12.5L6 8.5H14Z"
      className={`${stroke} ${fill}`}
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
  </svg>
)

export default ChevronDownIcon
