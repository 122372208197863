import axios, { AxiosError } from 'axios'
import Cookies from 'js-cookie'

import Store from '../helpers/Store'

// Whenever we take an action that communicates only with the maxchat server, but which should reset the session timeout timer, we should call this function. It will either obtain a fresh session cookie, or redirect teh user if the session has expired. Awaiting its response should be unnecessary.
async function extendSessionTimeout(store: Store) {
  logoutIfSessionExpired(store)

  await makeRefreshRequest(store)
}

const makeRefreshRequest = async (store: Store): Promise<null> => {
  try {
    await axios.get(
      `${store.credentials.customerAppUrl}/max_chat_api/v1/session`
    )
  } catch (error: unknown) {
    const err = error as AxiosError

    if (err.response?.status === 401) {
      performLogout(store)
    }
  }

  return null
}

function setupPeriodicSessionCookieCheck(store: Store) {
  // Check whether the session is still valid every minute
  setInterval(() => {
    logoutIfSessionExpired(store)
  }, 60_000)
}

function logoutIfSessionExpired(store: Store) {
  // We rely on this returning 'undefined' after the cookie expires
  const expiryCookie = Cookies.get('max_chat_expiry')
  if (expiryCookie === undefined) {
    performLogout(store)
    return
  }

  const expiryDate = new Date(expiryCookie)
  if (new Date() > expiryDate) {
    ;``
    performLogout(store)
    return
  }
}

function performLogout(store: Store) {
  window.location.href = `${store.credentials.customerAppUrl}/users/sign_in`
}

export { setupPeriodicSessionCookieCheck, extendSessionTimeout }
