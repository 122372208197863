// ChipSelectGroup.tsx
import React from 'react'

interface Option {
  value: string
  label: string
}

interface ChipSelectGroupProps {
  options: Option[]
  value: string[]
  onChange: (val: string[]) => void
}

const ChipSelectGroup: React.FC<ChipSelectGroupProps> = ({
  options = [],
  value = [],
  onChange,
}) => {
  const handleChipClick = (optionValue: string) => {
    // Check if value is selected
    const selected = value.includes(optionValue)
    let newValue
    if (selected) {
      newValue = value.filter((val) => val !== optionValue)
    } else {
      newValue = [...value, optionValue]
    }
    onChange(newValue)
  }

  return (
    <div className="flex flex-wrap gap-2">
      {options.map((opt) => {
        const isSelected = value.includes(opt.value)
        // Apply different styles if selected
        const chipStyles = isSelected
          ? 'border border-brd-brand-default text-txt-brand-default bg-bgCol-brand-secondary'
          : 'border border-brd-neutral-default text-txt-neutral-default'

        return (
          <button
            key={opt.value}
            type="button"
            className={`px-6 py-3 h-11 text-sm rounded-full focus:outline-none ${chipStyles} text-center flex items-center justify-center`}
            onClick={() => handleChipClick(opt.value)}
          >
            {opt.label}
          </button>
        )
      })}
    </div>
  )
}

export default ChipSelectGroup
