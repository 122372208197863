import { action, runInAction } from 'mobx'
import React from 'react'

import { fetchFile, fetchFiles } from '../apis/filesApi'
import { rollbarConfig } from '../helpers/rollbarConfig'

const removeFile = action((store, blobId) => {
  store.uploadedFiles = store.uploadedFiles.filter(
    (attachment) => attachment.file.id !== blobId
  )
})

const setFetchedDocuments = action(async (store) => {
  try {
    const documents = await fetchFiles(store)

    if (documents.length === 0) {
      runInAction(() => {
        store.documents = [] // reset document store
      })
      return
    }

    runInAction(() => {
      store.documents = documents.map((document) => ({
        ...document,
        ref: React.createRef(),
      }))
    })
  } catch (error) {
    rollbarConfig(store)?.error(error)
  }
})

const setFetchedDocument = action(async (store, docId) => {
  try {
    const document = await fetchFile(store, docId)
    if (!document) {
      runInAction(() => {
        store.currentDocument = null
      })
      return
    }

    runInAction(() => {
      store.currentDocument = document
    })
    return document
  } catch (error) {
    rollbarConfig(store)?.error(error)
  }
})

const setFetchedFilesAndFolders = action(async (store, folders) => {
  try {
    if (folders === undefined) {
      runInAction(() => {
        store.folders = {} // reset document store
        store.currentFolder = null
      })
      return
    }

    runInAction(() => {
      store.folders = folders
      store.currentFolder = folders

      // CONSISTENCY SETTING
      store.documentPagination.totalCount = folders.meta?.total_count
      store.documentPagination.totalPages = folders.meta?.total_pages
      store.documentPagination.page = folders.meta?.current_page
      store.documentPagination.perPage = folders.meta?.per_page
    })
  } catch (error) {
    console.error(error)
    rollbarConfig(store)?.error(error)
  }
})

const setCurrentFolder = action((store, value) => {
  store.currentFolder = value
})

export {
  removeFile,
  setFetchedDocument,
  setFetchedDocuments,
  setFetchedFilesAndFolders,
  setCurrentFolder,
}
